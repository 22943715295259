<div class="trinka_cloud_wrap">
  <div id="particles-js"></div>
  <div class="cloud_top_banners1">
    <div class="container">
      <div class="row">

        <div class="col-lg-4">
          <div class="trinka_cloud_banner_text">
            <!-- <button type="button" class="navbar-toggler" id="mobile_pharsebank_toggle" data-toggle="collapse" data-target="#navbarCollapse1"> -->
            <button type="button" class="navbar-toggler" id="mobile_pharsebank_toggle" (click)="toggleNavbar()">
              <svg viewBox="0 0 150 80" width="60" height="25">
                <rect width="100" height="15"></rect>
                <rect y="29" width="150" height="16"></rect>
                <rect y="60" width="100" height="15"></rect>
              </svg>
            </button>
            <div id="body_overlay_section1 body_overlay" [ngClass]="{'body_overlay': isNavbarVisible}"></div>

            <div id="navbarCollapse1" class="collapse navbar-collapse" [ngClass]="{'show': isNavbarVisible}">
              <div class="my-auto">
                <h2 class="mb-4">Find Phrases by Topic</h2>

                <div id="accordion" role="tablist" aria-multiselectable="true">

                  <!-- Accordion Items -->
                  <div class="card" *ngFor="let topic of objectKeys(topics); let i = index;">
                    <div class="card-header mobile-accordionHeadings" role="tab" id="mobile-accordionHeading-{{i}}" [ngClass]="{'active': currentSelection.topic === topic.toLocaleLowerCase().split(' ').join('-')}">
                      <div class="mb-0 row">
                        <div class="col-12 no-padding accordion-head">
                          <a href="javascript:void(0)" class="collapsed" (click)="toggleAccordion('mobile', topic, i)">
                            <h3 class="phasebank_cat1">{{topic}}</h3>
                          </a>
                        </div>
                      </div>

                    </div>

                    <div id="mobile-accordionBody-{{i}}" class="mobile-accordionBodies collapse" [ngClass]="{'show': currentSelection.topic === topic.toLocaleLowerCase().split(' ').join('-')}">
                      <div class="card-block col-12">
                        <ul class="nav nav-tabs phasebank_subclass" id="myTab" role="tablist">
                          <li class="nav-item1" *ngFor="let subtopic of topics[topic]; let j = index;">
                            <a class="nav-link" [ngClass]="{'active': currentSelection.topic === topic.toLocaleLowerCase().split(' ').join('-') && currentSelection.subtopic === subtopic.toLocaleLowerCase().split(' ').join('-')}" href="javascript:void(0)"
                              (click)="getPhraseBankPhrases(topic, subtopic)">{{subtopic}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h1>Academic Phrasebank</h1>
          </div>
        </div>

        <div class="col-md-8">
          <div class="contact" id="phasebankEmailBox">
            <form class="form-inline" [formGroup]="phraseBankSearchForm" (ngSubmit)="phraseBankSearch()">
              <div class="form-group ">
                <input matInput placeholder="&#xF002; Paste/type phrase to find similar ones" formControlName="sentence" class="form-control"
                  name="email">
              </div>
              <button type="submit" id="phasebank" class="btn btn-primary mb-2">Find Similar</button>
            </form>
            <p class="phasebanknote">E.g. "This represents an important topic to study because" </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="academic_phasebank pb-5">
  <div class="container">
    <div class="row">

      <!-- col-md-4 start -->
      <div class="col-md-4">
        <div class="my-auto">
          <h2 class="mb-4">Find Phrases by Topic</h2>

          <div id="accordion" role="tablist" aria-multiselectable="true">

            <!-- Accordion Items -->
            <div class="card" *ngFor="let topic of objectKeys(topics); let i = index;">
              <div class="card-header accordionHeadings" role="tab" id="desktop-accordionHeading-{{i}}" [ngClass]="{'active': currentSelection.topic === topic.toLocaleLowerCase().split(' ').join('-')}">
                <div class="mb-0 row">
                  <div class="col-12 no-padding accordion-head">
                    <!-- <a data-toggle="collapse" data-parent="#accordion" href="#accordionBody-{{i}}" aria-expanded="false" class="collapsed"> -->
                    <a id="topic__{{topic.toLocaleLowerCase().split(' ').join('-')}}" href="javascript:void(0)" class="collapsed" (click)="toggleAccordion('desktop', topic, i)">
                      <h3 class="phasebank_cat1">{{topic}}</h3>
                    </a>
                  </div>
                </div>

              </div>

              <!-- <div id="accordionBody-{{i}}" class="accordionBodies collapse" role="tabpanel" aria-labelledby="accordionHeading-{{i}}" aria-expanded="false" data-parent="accordion"> -->
              <div id="desktop-accordionBody-{{i}}" class="accordionBodies collapse" [ngClass]="{'show': currentSelection.topic === topic.toLocaleLowerCase().split(' ').join('-')}">
                <div class="card-block col-12">
                  <ul class="nav nav-tabs phasebank_subclass" id="myTab" role="tablist">
                    <li class="nav-item1" *ngFor="let subtopic of topics[topic]; let j = index;">
                      <a id="topic__{{topic.toLocaleLowerCase().split(' ').join('-')}}__subtopic__{{subtopic.toLocaleLowerCase().split(' ').join('-')}}" class="nav-link" [ngClass]="{'active': currentSelection.topic === topic.toLocaleLowerCase().split(' ').join('-') && currentSelection.subtopic === subtopic.toLocaleLowerCase().split(' ').join('-')}" href="javascript:void(0)"
                        (click)="getPhraseBankPhrases(topic, subtopic)">{{subtopic}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- col-md-4 end -->

      <!-- col-md-8 start -->
      <div class="col-md-8" *ngIf="!inProgress">

        <div class="phaseIntro" *ngIf="!phrases">
          <div class="phaseSearch">
            <div class="phaseSearchwrap">
              <h4>Search</h4>
              <p>Type/paste phrases to find the right academic phrase.
              </p>
            </div>
          </div>
          <div class="phaseor">
            or
          </div>
          <div class="phaseNav">
            <div class="phaseSearchwrap">
              <h4>Navigate</h4>
              <p class="navigate_desktop">Click on the category that matches your intent and find your right phrase</p>
              <p class="navigate_mobile">Click on
                <img src="../../../assets/images/navigate_icon.png" alt="icon" /> category menu and select category that matches your intent and find your right phrase</p>
            </div>
          </div>
        </div>

        <div class="phaseList" *ngIf="phrases" style="height: 100%;">
          <div class="row">
            <div class="col-md-12">
              <div class="phaseNote"> “____” this indicates the space where you can write your text
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h4>Relevant Phrases
                <!-- <span *ngIf="(phraseBankSearchForm.get('sentence').value || '').length">"{{ phraseBankSearchForm.get('sentence').value }}"</span> -->
              </h4>
            </div>
          </div>

          <div class="tab-content" style="height: 100%;">

            <!-- empty response from server message -->
            <div *ngIf="phrases.meta && !phrases.meta.length">
              <p class="no-result">No results found</p>
            </div>

            <div *ngIf="phrases.meta && phrases.meta.length">
              <div class="tab-pane active" id="phase-{{i}}" role="tabpanel" *ngFor="let phrase of pageOfItems['searched']; let i = index;">
                <p>
                  <span appHighlightKeywords [phrase]="phrase.meta.clean_phrase" [keywords]="phrase.meta.matched_keywords"></span>
                  <button class="copyPhase" (click)="copyToClipboard(phrase.meta.clean_phrase)">Copy</button>
                  <span class="breadcrumb">{{phrase.meta.class}} &raquo; {{phrase.meta.new_subclass}}</span>
                </p>
              </div>
              <app-pagination [items]="phrases.meta" [settings]="pager" (changePage)="onChangePage($event, 'searched')"></app-pagination>
            </div>

            <div *ngIf="!phrases.meta">
              <div class="tab-pane active" id="phase-{{i}}" role="tabpanel" *ngFor="let phrase of pageOfItems['static']; let i = index;">
                <p>
                  <span>{{phrase}}</span>
                  <button class="copyPhase" (click)="copyToClipboard(phrase)">Copy</button>
                  <span class="breadcrumb">{{currentSelection.topic}} &raquo; {{currentSelection.subtopic}}</span>
                </p>
              </div>

              <app-pagination [items]="phrases" [settings]="pager" (changePage)="onChangePage($event, 'static')"></app-pagination>
            </div>


          </div>
        </div>
      </div>
      <!-- col-md-8 end -->

    </div>
  </div>
</div>
<div class="most_suited_for_section pricing_works">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="brandlogo_heading">
          <h2>Trusted by Researchers Worldwide</h2>
        </div>
        <p>Trinka AI is preferred by researchers worldwide for their writing and publication needs. In addition to Trinka's
          academic phrasebank, technical writers and academics around the globe rely heavily on its <a href="/grammar-checker" class="text-link">grammar corrections</a> and
          language enhancements. Unlike most other tools, Trinka goes beyond grammar to ensure holistic language improvements
          for increased publication success.</p>
      </div>

      <div class="col-lg-12">
        <div class="try_trinka_Buttons">

          <a href="https://cloud.trinka.ai/signup?_rg=GB" target="_blank" rel="noopener noreferrer">Try it now</a>
        </div>
      </div>
    </div>
  </div>
</div>
