import { Component, OnInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../../../_services/langhfre.service';
// declare function ire(string,{}): any;

@Component({
  selector: 'app-aiapps-library',
  templateUrl: './aiapps-library.component.html',
  styleUrls: ['./aiapps-library.component.scss']
})
export class AIAppsLibraryComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private _document: Document,
    private LinkService: LinkService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Best Free AI Writing Tools – Generate content in seconds');
    this.metaTagService.updateTag({ name: 'description', content: "Discover the top free AI writing tools that help you generate high-quality content. AI Studio - Your perfect AI writing assistant!" });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Best Free AI Writing Tools – Generate content in seconds' });
    this.metaTagService.updateTag({ property: 'og:description', content: "A Suite of Specialized AI Apps for Content Creation" });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/ai-studio/ai-writing-tools' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Best Free AI Writing Tools – Generate content in seconds' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "A Suite of Specialized AI Apps for Content Creation" });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'keywords', content: '' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features/ai-studio/ai-writing-tools' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/ai-studio/ai-writing-tools' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features/ai-studio/ai-writing-tools' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/features/ai-studio/ai-writing-tools' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/features/ai-studio/ai-writing-tools' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/features/ai-studio/ai-writing-tools' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/features/ai-studio/ai-writing-tools' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/features/ai-studio/ai-writing-tools' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/features/ai-studio/ai-writing-tools' });
    

    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  stopAllVideos() {
    const iframes = Array.from(document.querySelectorAll('iframe.youtubeVideoForTryDemo'));
    iframes.forEach((iframe) => {
        const htmlIframe = iframe as HTMLIFrameElement;
        htmlIframe.src = htmlIframe.src;
    });
  }

}
