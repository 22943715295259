import { Component, OnInit, ElementRef, ViewChild, NgZone, AfterViewChecked, PLATFORM_ID, Inject, AfterViewInit, Renderer2 } from '@angular/core';
// import { NetworkServicesService } from 'src/app/networkCalls/network-services.service';
import { Title, Meta } from '@angular/platform-browser';
// import { katanaInput } from 'src/app/models/katana-input.service';
// import { katanaResult } from 'src/app/models/katana-result.service';
// import { CKEditorComponent } from 'ngx-ckeditor';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
// import { Observable, from } from 'rxjs';
// import { MatDialog } from '@angular/material';
// import { ErrorModalComponent } from '../error-modal/error-modal.component';
// import { ProgressStatusService } from '../../services/progress-status-service'
// import { rejectError } from 'src/app/models/reject-suggetions.service';
// import { NGXLogger } from 'ngx-logger';
// import { acceptError } from 'src/app/models/accept-suggetions.service';
// import { trigger, transition, style } from '@angular/animations';
// import { $ } from 'protractor';
import * as $ from 'jquery';
import { LinkService } from '../../_services/langhfre.service';
// import { MatRadioChange } from '@angular/material/radio';
// import { start } from 'repl';
//declare function ire(string,{}): any;
@Component({
  selector: 'app-punctuation-checker',
  templateUrl: './punctuation-checker.component.html',
  styleUrls: ['./punctuation-checker.component.scss']
})
export class PunctuationCheckerComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private LinkService: LinkService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    // public dialog: MatDialog,
    // public progressStatusService: ProgressStatusService,
    // private errorModalComponent: ErrorModalComponent,
    public lc: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object
    // private logger: NGXLogger,
  ) {
    //window.addEventListener("message", (d)=>{this.listenWindowMessage(d)});
  }

  ngOnInit() {
    // ele.classList.remove("loading")
    this.titleService.setTitle("Free Punctuation Checker Online – Trinka AI ");
    this.metaTagService.updateTag({ name: 'description', content: "Trinka's free online punctuation checker helps you instantly correct punctuation errors and also checks grammar." });
    this.metaTagService.updateTag({ property: 'og:title', content: "Free Punctuation Checker Online – Trinka AI" });
    this.metaTagService.updateTag({ property: 'og:description', content: "Trinka's free online punctuation checker helps you instantly correct punctuation errors and also checks grammar." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/punctuation-checker' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/WritingMoreEffective.png' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Free Punctuation Checker Online – Trinka AI' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Trinka's free online punctuation checker helps you instantly correct punctuation errors and also checks grammar." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/WritingMoreEffective.png' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/punctuation-checker' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/punctuation-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/punctuation-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/punctuation-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/punctuation-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/punctuation-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/punctuation-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/punctuation-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/punctuation-checker' });
    if (isPlatformBrowser(this.platformId)) {
      if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
        $("#GCChromeButton").css("display", "none");
        $("#GCFirFoxButton").css("display", "none");
        $("#GCEdgeButton").css("display", "none");
        return 'Opera';

      } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        $("#GCChromeButton").css("display", "block");
        $("#GCFirFoxButton").css("display", "none");
        $("#GCEdgeButton").css("display", "none");
        return 'Chrome';

      } else if (navigator.userAgent.indexOf("Safari") != -1) {
        $("#GCChromeButton").css("display", "none");
        $("#GCFirFoxButton").css("display", "none");
        $("#GCEdgeButton").css("display", "none");

        return 'Safari';

      } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        $("#GCChromeButton").css("display", "none");
        $("#GCFirFoxButton").css("display", "block");
        $("#GCEdgeButton").css("display", "none");
        return 'Firefox';

      }
      else if ((navigator.userAgent.indexOf("MSIE") != -1)) {
        $("#GCChromeButton").css("display", "none");
        $("#GCFirFoxButton").css("display", "none");
        $("#GCEdgeButton").css("display", "block");
        return 'IE';
      }
      else {
        return 'unknown';
      }
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka AI","item":"https://www.trinka.ai"},{"@type":"ListItem","position":2,"name":"Punctuation Checker","item":"https://www.trinka.ai/punctuation-checker"}]}
    `;
    this._renderer2.appendChild(this._document.head, script);
    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  

}
