import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { environment } from 'src/environments/environment';
//declare function ire(string,{}): any;
@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit, AfterViewInit {
  referral_code = null;
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private activateRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.activateRoute.paramMap.subscribe(params => {
      this.referral_code = params.get("referral_code");
    })
  }


  ngOnInit(): void {
    this.titleService.setTitle('Trinka - Advanced Grammar Checker for Academic and Technical Writing | Referral Program');
    this.metaTagService.updateTag({ name: 'description', content: "Trinka finds and corrects errors unique to academic writing that other tools don't." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Trinka - Advanced Grammar Checker for Academic and Technical Writing | Referral Program' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Trinka finds and corrects errors unique to academic writing that other tools don't." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/referral' });
    this.metaTagService.updateTag({ property: 'og:imgage', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });

    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.location.search.split("=")[1]) {
        window.location.href = environment.application_server_address + "/" + "signup" + "/" + window.location.search.split("=")[1];
      } else {
        alert("Invalid URL.");
      }
    }
  }

  

}
