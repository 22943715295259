import { Component, OnInit} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../../../_services/langhfre.service';
//declare function ire(string,{}): any;

@Component({
  selector: 'app-process-model',
  templateUrl: './process-model.component.html',
  styleUrls: ['./process-model.component.scss']
})
export class ProcessModelComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private LinkService: LinkService,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('Building Your Custom AI Model: Step-by-Step Process | Trinka AI');
    this.metaTagService.updateTag({ name: 'description', content: "Learn how Trinka AI builds custom AI models tailored to your needs. From data collection to secure deployment, we ensure precision, scalability, and full ownership." });
    this.metaTagService.updateTag({ property: 'og:title', content: "Building Your Custom AI Model: Step-by-Step Process | Trinka AI" });
    this.metaTagService.updateTag({ property: 'og:description', content: "Learn how Trinka AI builds custom AI models tailored to your needs. From data collection to secure deployment, we ensure precision, scalability, and full ownership." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/enterprise/custom-ai-models/process' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/enterprise/custom-ai-models/process' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Building Your Custom AI Model: Step-by-Step Process | Trinka AI' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Learn how Trinka AI builds custom AI models tailored to your needs. From data collection to secure deployment, we ensure precision, scalability, and full ownership." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/enterprise/custom-ai-models/process' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/enterprise/custom-ai-models/process' });

    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
    // console.log("Country is", this.country);
  }

}
