import { Component, OnInit} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../../../_services/langhfre.service';
//declare function ire(string,{}): any;

@Component({
  selector: 'app-why-choose-custom-ai',
  templateUrl: './why-choose-custom-ai.component.html',
  styleUrls: ['./why-choose-custom-ai.component.scss']
})
export class WhyChooseCustomAIComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private LinkService: LinkService,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('Why Choose Trinka AI to Build Custom AI Models You Own?');
    this.metaTagService.updateTag({ name: 'description', content: "Discover why Trinka AI is your partner for custom AI models. Gain full ownership, control, and scalable solutions tailored to your business needs. Make AI your asset today." });
    this.metaTagService.updateTag({ property: 'og:title', content: "Why Choose Trinka AI to Build Custom AI Models You Own?" });
    this.metaTagService.updateTag({ property: 'og:description', content: "Discover why Trinka AI is your partner for custom AI models. Gain full ownership, control, and scalable solutions tailored to your business needs. Make AI your asset today." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/enterprise/custom-ai-models/why-choose-us' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/enterprise/custom-ai-models/why-choose-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Why Choose Trinka AI to Build Custom AI Models You Own?' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Discover why Trinka AI is your partner for custom AI models. Gain full ownership, control, and scalable solutions tailored to your business needs. Make AI your asset today." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/enterprise/custom-ai-models/why-choose-us' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/enterprise/custom-ai-models/why-choose-us' });

    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
    // console.log("Country is", this.country);
  }

}
