import { Component, OnInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../../../_services/langhfre.service';
//declare function ire(string,{}): any;

@Component({
  selector: 'app-ask-trinka',
  templateUrl: './ask-trinka.component.html',
  styleUrls: ['./ask-trinka.component.scss']
})
export class AskTrinkaComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private _document: Document,
    private LinkService: LinkService
  ) { }

  ngOnInit() {
    this.titleService.setTitle("The Power of GenAI Within the Writing Process | Trinka's AI-assisted Writing");
    this.metaTagService.updateTag({ name: 'description', content: "Ask Trinka to start writing from scratch, improve an idea, or let AI continue where you left off." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Generate High Quality Standardized Content At Scale | Trinka AI Studio' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Ask Trinka AI to help you write better." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/ai-studio/ai-assisted-writing' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Generate High Quality Standardized Content At Scale | Trinka AI Studio' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Ask Trinka AI to help you write better." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'keywords', content: '' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features/ai-studio/ai-assisted-writing' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/ai-studio/ai-assisted-writing' });
    

    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  stopAllVideos() {
    const iframes = Array.from(document.querySelectorAll('iframe.youtubeVideoForTryDemo'));
    iframes.forEach((iframe) => {
        const htmlIframe = iframe as HTMLIFrameElement;
        htmlIframe.src = htmlIframe.src;
    });
  }

  freeshowTab = 1;
  freeScore(index) {
    this.freeshowTab = index;
  }

}
