import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  testimonials = [
    {
      "author": "Hugo Alberto Rivera Rodriguez",
      "designation": "Assistant Professor, Universidad del Rosario, Colombia",
      "message": "Trinka has allowed me to improve the articles I have published. In addition to giving me suggestions for journals according to my research.",
      "photo": "clientPhotos/hugo.jpg"
    },
    {
      "author": "Michal Plaček",
      "designation": "Professor of Public and Social Policy at Charles University,<br/> Prague, Czech Republic",
      "message": "Trinka has greatly streamlined the work on my research. As a non-native English speaker, I used the services of a language editor before finalizing the article, which took weeks and cost a large amount of money. With Trinka, I can spend much more time on the research itself.",
      "photo": "clientPhotos/michal.jpg"
    },
    {
      "author": "Dmitry Belousov",
      "designation": "Managing Editor, Publishing House OKI",
      "message": "I have been using Trinka for several years and have had a very positive impression. This tool really helps improve the quality of writing. I especially liked the grammar and style checker, which not only found errors but also provided recommendations on how to correct them. The program interface is intuitive, which is an advantage for new users. Trinka is a versatile tool for publishing professionals. I recommend it to anyone seeking to improve their writing skills.",
      "photo": "clientPhotos/dmitry-belousov.png"
    },
    {
      "author": "Angela Reddemann",
      "designation": "Professor of Theological Anthropology at Theological Institute VERBUM DEI - San Pablo<br/>Loeches - Madrid, Spain",
      "message": "I met Trinka while searching for a reliable companion to assure the correct publication of my university classes and books abput the spirituality of contemplation. With a Premium account I can revise the grammar and text flow of my manuscripts in Spanish, English and German Language. The customer service is brilliant and even available as Zoom Assistance if I need help or things need to change. Trinka is quick, still developing though and full of surprises with new services. Great stuff!",
      "photo": "clientPhotos/angela-reddemann.png"
    },
    // {
    //   "author": "James Smith",
    //   "designation": "Academic Editor, USA",
    //   "message": "First, I love Trinka. Congratulations on developing something that extensively aids editing, especially critical grammar conventions and word count reductions.",
    //   "photo": "clientPhotos/James-Smith.jpg"
    // },
    // {
    //   "author": "Edith Davies",
    //   "designation": "PhD Scholar, UK",
    //   "message": "I have tested Trinka and am impressed with its fine capabilities. It did better than the grammar checkers I use. Well done! I have told my colleagues in my research lab to use Trinka. Hope it is widely used in academia.",
    //   "photo": "clientPhotos/Edith-Davies.jpg"
    // },
    {
      "author": "Raquel Rodrigues",
      "designation": "PhD student, Universidade Federal de Minas Gerais, Brazil",
      "message": "The platform has helped me a lot not only with grammar, but also with the cohesion and coherence of my texts. The plagiarism checking and reference checking features are really good.",
      "photo": "clientPhotos/raquel_rodrigues.jpg"
    },
    {
      "author": "Dyke Gareth",
      "designation": "Researcher, University of Debrecen",
      "message": "I think Trinka.ai is a very interesting and potentially useful idea, especially for editors/publishers/journals. I found it very useful when I checked my editing work on the platform.",
      "photo": "clientPhotos/Dyke-Gareth.jpg"
    },
        // {
    //   "author": "Dmitry Belousov",
    //   "designation": "OKI Publishing House, Russian Federation",
    //   "message": "We have been using the Trinka AI online service for more than a year to check the abstracts of medical articles and to check translations of articles from Russian to English. During this period, the Trinka AI service was excellent. We recommend it to our colleagues.",
    //   "photo": "clientPhotos/belousov.jpg"
    // },
    {
      "author": "Theo van Leeuwen",
      "designation": "Manager Rio Tinto Exploration SE Asia, Indonesia",
      "message": "I have been using Trinka for several years to check my scientific writings (geology). Compared to a few other programs I tried Trinka  offered the best results. Furthermore, their service in terms of answering questions has been excellent.",
      "photo": "clientPhotos/theo-van-leeuwen.jpg"
    },
        // {
    //   "author": "Abba Kasim",
    //   "designation": "Researcher, FUDMA, Nigeria",
    //   "message": "The Trinka platform is intuitive and easy to navigate. Most importantly, the plagiarism checker is a game-changer! It gives me confidence that my work is original and free from unintentional copying, which is crucial for academic integrity.",
    //   "photo": "clientPhotos/abba-kasim.jpg"
    // },
        {
      "author": "Abdi Yusuf",
      "designation": "Jijiga University - Senior Lecturer and HRM Director",
      "message": "I have been using Trinka for the past year, and it has significantly improved my writing process. The advanced grammar and style suggestions have helped me refine my work, making it more polished and professional. Trinka is not just a tool; it’s an essential companion for anyone looking to enhance their writing quality. I highly recommend it to anyone who values clarity and precision in their writing.",
      "photo": "clientPhotos/abdi-yusuf.jpg"
    },
    {
      "author": "Rosa Diaz Sandoval",
      "designation": "Physics and Mathematics PhD, Mexico",
      "message": "Writing texts with correct English grammar and style is not an easy task for people who speak other languages. Sometimes you cannot find little mistakes in your document without help and moreover for academic writing. I tried Trinka AI as a tool of automatic correction and was quite glad with the suggested corrections and the feedback. With the feedback you understand why the suggested change must be taken into account.",
      "photo": "clientPhotos/Rosa-Diaz-Sandoval.jpg"
    },
    {
      "author": "Jesse Rumball-Smith",
      "designation": "Student, Wellington College, New Zealand",
      "message": "I just wanted to say thank you for such a great and free service, all the other grammar checkers and writing assistants I have tried don’t have nearly the depth of features let alone all available on a free subscription. As a student with little income available I am extremely grateful that unlike all others companies you don’t limit features on the free plan. This is a fantastic product and given the income I would definitely upgrade to the premium. I've let all my fellow students know about Trinka as it is seriously a life saver.",
      "photo": "clientPhotos/Noor-Al-rishi.png"
    },
    {
      "author": "Nwaji Jude",
      "designation": "University of Chester, UK",
      "message": "It's been a wonderful experience using Trinka and I almost can't submit any manuscript without re-evaluating with it. In summary, Trinka has been a life-saver.",
      "photo": "clientPhotos/Nwaji-Jude.jpg"
    },
    {
      "author": "Dr. Abdalla Machano",
      "designation": "General Practioner at Jitimai Referral Hospital, Tanzania<br/>Founder & Director, Kadi Hai",
      "message": "Trinka AI has significantly improved my academic writing. It has helped me become more confident in my writing by quickly and accurately identifying grammatical and spelling errors.",
      "photo": "clientPhotos/abdalla-machano.jpg"
    },
    {
      "author": "Dauda Ayuba",
      "designation": "Research Assistant, Ahmadu Bello University, Zaria, Nigeria",
      "message": "I have been using the Trinka service, particularly to proofread manuscripts since 2023. This will service check and correct grammar, spelling writing advice, and enhancements of your manuscript. The platform’s intuitive design and comprehensive feedback have greatly enhanced my writing skills. The ability to provide context-specific suggestions makes it a standout tool to ensure clarity and precision in my work. I highly recommend Trinka to anyone looking to improve their writing quality.",
      "photo": "clientPhotos/d-ayuba.jpg"
    },
    {
      "author": "Dr. Farooq Rathore",
      "designation": "Assistant Professor and Head of Department, Pakistan",
      "message": "I have mainly used it to improve the language, grammar, and syntax of the scientific manuscripts that I write for national and international biomedical journals. I have found the interface to be user friendly and the procedure itself is efficient and hassle-free. The auto-edit is a great help and the supplementary page with the revision summary is an excellent idea.",
      "photo": "clientPhotos/Dr_Farooq_Rathore.jpg"
    },
    {
      "author": "Dr. Maryam Razmgir",
      "designation": "Associate Professor - Iran University of Medical Sciences Tehran, Iran",
      "message": "I used Trinka for writing and checking grammar in Iran. Trinka helped me improve the writing. It also helped me to find and correct grammatical errors faster. The use of Trinka was a good experience. ",
      "photo": "clientPhotos/maryam.png"
    },
    {
      "author": "Dr. Nitin Wahi",
      "designation": "Project Scientist - Pathfinder Research and Training Foundation",
      "message": "Trinka AI is a great tool to help beginners in research publication. Its grammar editing, online proofreading with file download, citation checker, plagiarism checker, paraphraser, and journal finder provide amazing features at your fingertips. Even the free Trinka Basic plan can be invaluable for researchers in their early stages or at PG levels, helping them improve project reports and papers to meet publication standards. Overall, I'm extremely impressed with Trinka AI, as it stands out among research writing tools. Give it a try and you will love it! Thanks Trinka AI team for this valuable tool.",
      "photo": "clientPhotos/client-photo.jpg"
    },
    // {
    //   "author": "Dinesh Gupta",
    //   "designation": "Radiologist, India",
    //   "message": "The grammar checking tool exceeds Grammarly! I re-checked some documents that I had checked earlier with Grammarly. I was pleasantly surprised to see such a product from India!",
    //   "photo": "clientPhotos/Dinesh-Gupta.jpg"
    // },
    
    // {
    //   "author": "Ralph Bailey",
    //   "designation": "Neurologist, USA",
    //   "message": "Trinka has become my go-to tool for grammar corrections. The AI-powered corrections and language enhancements made writing a lot more easier. Now I am able to focus more on research than on writing an error-free document.",
    //   "photo": "clientPhotos/Ralph-Bailey.jpg"
    // },
    // {
    //   "author": "Thomas Andres",
    //   "designation": "Cardiologist, Brazil",
    //   "message": "The grammar correction and suggestion based on the AMA style guide is really impressive! Trinka is indeed the best grammar correction tool for medical writing.",
    //   "photo": "clientPhotos/Thomas-Andres.jpg"
    // }
  ]  

}
