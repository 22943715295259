import { Component, OnInit, HostListener, PLATFORM_ID, Inject } from '@angular/core';
import { WebsiteService } from 'src/app/_services/website.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import * as $ from 'jquery';
import { LinkService } from '../../_services/langhfre.service';
// import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { Router } from '@angular/router';
import { LoadScriptService } from 'src/app/_services/load-script.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

//declare function ire(string,{}): any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  disabledSubmitButton: boolean = true;
  billingInvoiceText: boolean = false;
  country: string;
  isSubmitting: boolean = false;
  @HostListener('input') oninput() {

    if (this.contactForm.valid) {
      this.disabledSubmitButton = false;
    }
  }

  constructor(
    private fb: FormBuilder,
    private networkCall: WebsiteService,
    private toastr: ToastrService,
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService,
    private router: Router,
    public loadScript: LoadScriptService,
     private http: HttpClient
    // public amplitudeService: AmplitudeService

  ) {
    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])],
      'contactFormContactNumber': [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.pattern(/^\d{10,}$/)
        ])
      ],
      'contactFormOrgTitle': ['', Validators.required],
      'contactFormInquiryType': ['', Validators.required],
      'contactFormMessage': ['', Validators.required]
    });
  }

  ngOnInit() {
    // this.amplitudeService.logAmplitude('home_page_contact_view', {});
    this.titleService.setTitle('Contact Us | Trinka');
    this.metaTagService.updateTag({ name: 'description', content: 'Contact our team for any help, request additional information, or business and sales opportunities. Fill in this form or write to us at support&#64;trinka&#46;ai' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Contact Us | Trinka' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Contact our team for any help, request additional information, or business and sales opportunities. Fill in this form or write to us at support&#64;trinka&#46;ai' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/contactus' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/contactus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/contactus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/contactus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/contactus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/contactus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/contactus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/contactus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/contactus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/contactus' });
    if (isPlatformBrowser(this.platformId)) {
      //$.getScript('../../../assets/js/wisepop.js', function () { });
      $.getScript('../../../assets/js/about_us_testimonial_silders.js', function () { });
    }
    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  processForm() {

    if (this.contactForm.valid) {
      this.isSubmitting = true;

      if (!localStorage.getItem("country-trinka")) {
          this.loadGeoAPIScript();
      }

      this.country = localStorage.getItem("country-trinka") || "IN";

      const organizationName = this.contactForm.value.contactFormOrgTitle || "Unknown Organization";
      const personName = this.contactForm.value.contactFormName;
      const email = this.contactForm.value.contactFormEmail;
      const phone = this.contactForm.value.contactFormContactNumber;
      const comments = `Requirement - ${this.contactForm.value.contactFormMessage || ""}
        Inquiry for - ${this.contactForm.value.contactFormInquiryType || ""}
        URL - ${this.router.url || "N/A"}`;

      console.log("comments are:", comments);

      const selectedInquiryType = this.contactForm.value.contactFormInquiryType;
      // Create the subject with the selected inquiry type
      const subject = `Inquiry Type: ${selectedInquiryType}`;
      var query = this.contactForm.value.contactFormName + "||" + this.contactForm.value.contactFormMessage + "||" + this.contactForm.value.contactFormContactNumber + "||" + this.contactForm.value.contactFormOrgTitle + "||" + this.contactForm.value.contactFormInquiryType
      this.networkCall.postContactUs(this.contactForm.value.contactFormEmail, query, this.router.url).subscribe(result => {
        
        // Step 1: Search for Existing Organization
        this.http.get(`${environment.pipedriveApiUrl}/v1/organizations/search?term=${organizationName}&api_token=${environment.pipedriveApiToken}`)
        .subscribe(searchResponse => {
            const existingOrganization = searchResponse['data']?.items?.find(item => item.item.name === organizationName);
            console.log("inside pipedrive api");
            if (existingOrganization) {
                console.log("Existing Organization Found:", existingOrganization);
                const organizationId = existingOrganization.item.id;
                this.createPersonAndLead(personName, email, phone, organizationId, comments);
            } else {
                // Organization does not exist, create a new one
                this.createOrganization(organizationName, personName, email, phone, comments);
            }
        }, searchError => {
            console.error("Pipedrive Organization Search Error:", searchError);
        });

        this.toastr.success("Thanks! We will contact you shortly.")
        this.contactForm.reset();
        this.billingInvoiceText = false;
      }, error => {
        this.toastr.warning("Something went wrong!")
      }).add(() => {
        this.isSubmitting = false;
      });
    } else {
        this.validateAllFormFields(this.contactForm);
    }
    // const allInfo = `My name is ${this.name}. My email is ${this.email}. My message is ${this.message}`;
    // alert(allInfo);
  }

  createOrganization(organizationName: string, personName: string, email: string, phone: string, comments: string) {
    const organizationData = { name: organizationName };

    this.http.post(`${environment.pipedriveApiUrl}/v1/organizations?api_token=${environment.pipedriveApiToken}`, organizationData)
        .subscribe(orgResponse => {
            console.log("Organization Created:", orgResponse);
            const organizationId = orgResponse['data']?.id;

            if (!organizationId) {
                console.error("Error: No organization ID returned from Pipedrive.");
                return;
            }

            this.createPersonAndLead(personName, email, phone, organizationId, comments);
        }, orgError => {
            console.error("Pipedrive Organization Error:", orgError);
        });
  }

  createPersonAndLead(personName: string, email: string, phone: string, organizationId: number, comments: string) {
    const personData = {
        name: personName,
        email: email,
        phone: phone,
        visible_to: 3,
        org_id: organizationId // Associate person with the organization
    };

    this.http.post(`${environment.pipedriveApiUrl}/v1/persons?api_token=${environment.pipedriveApiToken}`, personData)
        .subscribe(personResponse => {
            console.log("Person Created:", personResponse);
            const personId = personResponse['data']?.id;

            if (!personId) {
                console.error("Error: No person ID returned from Pipedrive.");
                return;
            }

            this.createLead(personName, personId, organizationId, comments);
        }, personError => {
            console.error("Pipedrive Person Error:", personError);
        });
  }

  createLead(personName: string, personId: number, organizationId: number, comments: string) {
    const pipedriveData = {
        title: personName,
        owner_id: 21257804,
        label_ids: [],
        person_id: personId,
        organization_id: organizationId,
        value: { amount: 0, currency: "INR" },
        expected_close_date: null,
        visible_to: "3",
        was_seen: 1,
        origin_id: null,
        channel: null,
        channel_id: null,
        "ab9df2f471b661d064548dc401e65436abe0638b": comments, //comments
        "fd8732da92bc0b0ff2858e31a9c7fa17c8606b9d": this.country   ///Capture country
    };

    this.http.post(`${environment.pipedriveApiUrl}/v1/leads?api_token=${environment.pipedriveApiToken}`, pipedriveData)
        .subscribe(leadResponse => {
            console.log("Lead Created:", leadResponse);
        }, leadError => {
            console.error("Pipedrive Lead Error:", leadError);
        });
  }

  loadGeoAPIScript() {
    if (isPlatformBrowser(this.platformId)) {
      var scriptName = "geoIP"
      this.loadScript.loadScript(scriptName, '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js')
        .then(() => {
          console.log(`${scriptName} loaded successfully`);
          var onSuccess = function (location) {
            localStorage.setItem("country-trinka", location['country']['iso_code'])
          };

          var onError = function (error) {
            localStorage.setItem("country-trinka", "IN")
          };
          try {
            //@ts-ignore
            geoip2.city(onSuccess, onError);
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => {
          console.error(`Failed to load ${scriptName}`);
        });
    }
  }


  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }
  displayInvoiceEmail(event){
    if(event.target.value == "Billing/Invoice Support"){
      this.billingInvoiceText = true;
    }else{
      this.billingInvoiceText = false;
    }
    
  }

  
}
