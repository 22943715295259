<div class="top-tools-tab-mobile" id="tabs-tools" role="tablist">
    <a [routerLink]="['/grammar-checker']" role="tab" aria-selected="false" tabindex="-1" id="tab-grammar">
        <img src="../../../../assets/images/menu-icons/grammar-checker.svg" alt="grammar-checker">
        <p>Grammar Checker</p>
    </a>
    <a [routerLink]="['/paraphrasing-tool']" role="tab" aria-selected="false" tabindex="-1" id="tab-paraphraser">
        <img src="../../../../assets/images/menu-icons/pharaphraser.svg" alt="Paraphraser">
        <p>Paraphraser</p>
    </a>
    <a [routerLink]="['/journal-finder']" role="tab" aria-selected="true" tabindex="0" id="tab-jorunal-finder">
        <img src="../../../../assets/images/menu-icons/journal-finder.svg" alt="Journal Finder">
        <p>Journal Finder</p>
    </a>
    <a [routerLink]="['/ai-content-detector']" role="tab" aria-selected="false" tabindex="-1" id="tab-ai-detector">
        <img src="../../../../assets/images/menu-icons/ai-detector.svg" alt="AI Detector">
        <p>AI Detector</p>
    </a>
    <a [routerLink]="['/academic-phrasebank']" role="tab" aria-selected="true" class="active" tabindex="0" id="tab-phrasebank">
        <img src="../../../../assets/images/menu-icons/phrasebank.svg" alt="Academic Phrasebank">
        <p>Academic Phrasebank</p>
    </a>
</div>


<div class="trinka_cloud_wrap" #target>
    <div id="particles-js"></div>
    <!-- <div class="container">
        <div class="col-md-12">
            <div class="toolLinkingTabs">
                <ul>
                    <li>
                        <a [routerLink]="['/grammar-checker']" routerLinkActive="active">
                            <img class="grammarCheckerIcons" src="../../../assets/icons/GrammarChecker.svg" alt="">
                            <img class="grammarCheckerActiveIcons" src="../../../assets/icons/GrammarCheckerActive.svg" alt="">
                            <span>Grammar checker</span>
                        </a>
                    </li>
                    <li>
                        <a  [routerLink]="['/paraphrasing-tool']" routerLinkActive="active">
                            <img class="grammarCheckerIcons" src="../../../assets/icons/ParaphrasingTool.svg" alt="">
                            <img class="grammarCheckerActiveIcons" src="../../../assets/icons/ParaphrasingToolActive.svg" alt="">
                            <span>Paraphrasing tool</span>
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/ai-content-detector']" routerLinkActive="active">
                            <img class="grammarCheckerIcons" src="../../../assets/icons/AIContentDetector.svg" alt="">
                            <img class="grammarCheckerActiveIcons" src="../../../assets/icons/AIContentDetectorActive.svg" alt="">
                            <span>AI Content Detector</span>
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/academic-phrasebank']" routerLinkActive="active">
                            <img class="grammarCheckerIcons" src="../../../assets/icons/AcademicPhraseBank.svg" alt="">
                            <img class="grammarCheckerActiveIcons" src="../../../assets/icons/AcademicPhraseBankActive.svg" alt="">
                            <span>Academic PhraseBank</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div> -->
    <div class="cloud_top_banners">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="trinka_cloud_banner_text">
                        <h1>Trinka’s Academic Phrasebank</h1>
                        <p>Find phrases or sentences to best express your intent or avoid repetition
                        </p>

                        <div class="contact" id="phasebankEmailBox">
                            <form class="form-inline" [formGroup]="phaseBankSearchForm" (ngSubmit)="phaseBankSearch()">
                                <div class="form-group ">

                                    <input placeholder="&#xF002; Paste/type phrase to find similar ones" formControlName="sentence" class="form-control" name="email">
                                </div>
                                <button type="submit" id="phasebank" class="btn btn-primary mb-2">Find Phrases</button>
                            </form>
                        </div>
                        <p class="phasebanknote">E.g. "This represents an important topic to study because"</p>
                        <div class="purplephasebank">
                            <a [routerLink]="['/academic-phrasebank-browse', 'topic', 'subtopic']">or browse our section-wise collection</a>
                        </div>
                        <div class="disclaimercontents">
                            <p>Information available here is free to use for academic purposes. For commercial use, please
                                <a href="mailto:sales&#64;trinka&#46;ai">contact us</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="SectionHowItWrok">
        <div class="container">
            <div class="row">
                <div class="col-xl-6">
                    <div class="ImgSectionHowItWrok">
                        <a href="" data-toggle="modal" data-target="#wordPluginPopup" id="VewiVideoSection">
                            <img src=".././../../assets/images/acadmicPhraseBank.png" alt="" class="img-fluid eposter-img">
                        </a>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="contentSectionHowItWrok">
                        <h2>See How It Works</h2>
                        <div class="HeadingSubLine"></div>
                        <p style="margin-bottom: 10px;">Check out the easy walk-through video to start building your Personal Dictionary in no time.</p>
                        <p style="margin-bottom: 10px;">It is a detailed step-by-step tutorial to help you use Trinka to its fullest.</p>
                        <p>Have more questions? Browse through our FAQs <a style="color: #0000ff;" (click)="scroll(target)" class="link">here</a>.</p>
                    </div>
                </div>

            </div>
        </div>
    </div> -->

    <div class="section-pd-sm section-purple list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <img src="../../../assets/images/brain.png" alt="brain" srcset="">
                        <h3>AI to Find the Right Phrase</h3>
                        <p>
                            Our specially trained AI model saves you time finding the right phrase to write confidently from our large
                            <b>academic phrasebank</b>
                            in just seconds.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <img src="../../../assets/images/network.png" alt="brain" srcset="">
                        <h3>Large Academic Database</h3>
                            <p>
                                Our
                                <b>academic phrasebank</b> curates phrases from millions of academic publications to help you
                                easily express your intent while meeting academic conventions. </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <img src="../../../assets/images/snap.png" alt="brain" srcset="">
                        <h3>Easy to Search and Use</h3>
                            <p>Save time and write confidently using our
                                <b>academic phrasebank</b> powered by AI technology that makes finding the right phrases quick
                                and easy.
                            </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="most_suited_for_section">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="features_cards">
                        <img src="../../../assets/images/brain.png" alt="brain" srcset="">
                        <div class="features_cards_inner">
                            <h3>AI to Find the Right Phrase</h3>
                            <p>
                                Our specially trained AI model saves you time finding the right phrase to write confidently from our large
                                <b>academic phrasebank</b>
                                in just seconds.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="features_cards">
                        <img src="../../../assets/images/network.png" alt="brain" srcset="">
                        <div class="features_cards_inner">
                            <h3>Large Academic Database</h3>
                            <p>
                                Our
                                <b>academic phrasebank</b> curates phrases from millions of academic publications to help you
                                easily express your intent while meeting academic conventions. </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="features_cards">
                        <img src="../../../assets/images/snap.png" alt="brain" srcset="">
                        <div class="features_cards_inner">
                            <h3>Easy to Search and Use</h3>
                            <p>Save time and write confidently using our
                                <b>academic phrasebank</b> powered by AI technology that makes finding the right phrases quick
                                and easy.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->


</div>

<!--

<div class="writeToolboxs_section writeToolboxs_section_phrasebank">
    <div class="writeToolbox_inner_sections">
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <div class="toolbox_text_content">
                        <h3>About Trinka’s Academic Phrasebank</h3>

                        <p>Trinka's academic phrasebank is a large database of phrases or sentences in English that lets you
                            find the right phrase or sentence to use to express a specific intent while meeting academic
                            writing conventions and style. </p>
                        <p> Trinka's
                            <b>academic phrasebank</b> includes phrases/sentences that have been used in scientific journal
                            papers and other academic publications. Our database is specially curated by a team of expert
                            academics and editors. </p>
                        <p>Our unique AI technology delivers highly relevant results and saves your time in drafting your academic
                            content.
                        </p>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="toolbox_img_content">
                        <img src="../../../assets/images/safe-secure.png" alt="safe" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="writeToolbox_inner_sections sections_height_set publicationCheckSections" style="background-image: url('../../../assets/images/toolbox_backgrond_browser_plugin_r.png');">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="toolbox_img_content">
                        <div class="ImgSectionHowItWrok">
                            <a href="" data-toggle="modal" data-target="#wordPluginPopup" id="VewiVideoSection">
                                <img src=".././../../assets/images/acadmicPhraseBank.png" alt="" class="img-fluid eposter-img">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="toolbox_text_content">
                        <h3>How Does Trinka’s Academic Phrasebank Work?</h3>

                        <p> Using Trinka’s
                            <b>academic phrasebank</b>, you can find the right phrases or sentences to communicate your message
                            effectively in two easy ways.</p>
                        <p class="purple_bullet">
                            <b>Searching for phrases similar to ones you have.</b> Type or paste the phrase to see all similar
                            phrases. Trinka's AI understands the meaning of the phrase and shows you all the relevant phrases.
                            Copy the suggestion of your choice with a click to use it. </p>
                        <p class="purple_bullet">
                            <b>Navigating the academic phrasebank using the sections.</b> Say you need phrases to introduce
                            previous work to establish the importance of your topic in the introduction section. Just click
                            on the section “Introducing your work” and then the subsection “Establishing the importance of
                            the topic” in the categorized academic phrasebank navigation. You get all the relevant suggestions
                            that you can copy with a click!
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<section class="section-pd list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row align-items-center flex-row-reverse">
            <div class="col-md-6">
                <div class="list-wrapper-content-2">
                    <h3>About Trinka’s Academic Phrasebank</h3>
                    <p>Trinka's academic phrasebank is a large database of phrases or sentences in English that lets you
                        find the right phrase or sentence to use to express a specific intent while meeting academic
                        writing conventions and style. </p>
                    <p> Trinka's
                        <b>academic phrasebank</b> includes phrases/sentences that have been used in scientific journal
                        papers and other academic publications. Our database is specially curated by a team of expert
                        academics and editors. </p>
                    <p>Our unique AI technology delivers highly relevant results and saves your time in drafting your academic
                        content.
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image">
                    <img src="../../../assets/images/safe-secure.png" alt="safe" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd list-wrapper-style-2 pt-0">
    <div class="trinka_container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="list-wrapper-content-2 pr-80">
                    <h3>How Does Trinka’s Academic Phrasebank Work?</h3>

                        <p> Using Trinka’s
                            <b>academic phrasebank</b>, you can find the right phrases or sentences to communicate your message
                            effectively in two easy ways.</p>
                        <p class="purple_bullet">
                            <b>Searching for phrases similar to ones you have.</b> Type or paste the phrase to see all similar
                            phrases. Trinka's AI understands the meaning of the phrase and shows you all the relevant phrases.
                            Copy the suggestion of your choice with a click to use it. </p>
                        <p class="purple_bullet">
                            <b>Navigating the academic phrasebank using the sections.</b> Say you need phrases to introduce
                            previous work to establish the importance of your topic in the introduction section. Just click
                            on the section “Introducing your work” and then the subsection “Establishing the importance of
                            the topic” in the categorized academic phrasebank navigation. You get all the relevant suggestions
                            that you can copy with a click!
                        </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image">
                    <a href="" data-toggle="modal" data-target="#wordPluginPopup" id="VewiVideoSection">
                        <img src=".././../../assets/images/acadmicPhraseBank.png" alt="academic phrase bank" class="img-fluid eposter-img">
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="softWare_workFlow cloud_key_features">
    <div class="trinka_container">
        <div class="row">
            <!-- <div class="col-md-12">

            </div> -->
            <div class="col-md-6">
                <div class="trinka-title">
                    <h2>Trinka’s Phrasebank vs. Manchester Phrasebank</h2>
                    <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                    <p> The Manchester academic phrasebank was compiled by Dr. John Morley at the University of Manchester. The Manchester
                        academic phrasebank can be downloaded as a pdf file or accessed on their website. </p>
                    <p>Trinka's academic phrasebank is a fully searchable and navigable academic phrasebank for authors. It provides
                        a significantly larger database than the Manchester academic phrasebank and includes phrases from millions
                        of published articles, making it a highly comprehensive academic phrasebank. </p>
                    <p>Furthermore, Trinka’s
                        <b>academic phrasebank</b> uses AI technology to help you easily find relevant phrases with a simple search.
                        Trinka’s categorized academic phrasebank lets you browse through the database and find the right phrase
                        easily.
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="phasebankFeatures">
                    <ul>
                        <li>
                            <div class="features_cards">
                                <div class="features_cards_icon">
                                    <img src="../../../assets/images/curated-researcher.png" alt="curated-researcher" srcset="">
                                </div>
                                <div class="features_cards_desc">
                                    <h3>Large Curated Academic Database</h3>
                                    <p>Trinka’s academic phrasebank uses a large academic database containing content from millions
                                        of published papers from reputed journals across disciplines.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <!-- <li>
                            <div class="features_cards">
                                <div class="features_cards_icon">
                                    <img src="../../../assets/images/Highly-secured.png" alt="" srcset="">
                                </div>
                                <div class="features_cards_desc">
                                    <h3>Highly Secure</h3>
                                    <p>We adheres to the strictest global standards to ensure maximum security for our clients.
                                        AI does not learn from your anonymized data, your data is yours alone.</p>
                                </div>
                            </div>
                        </li> -->
                        <li>
                            <div class="features_cards">
                                <div class="features_cards_icon">
                                    <img src="../../../assets/images/ai.png" alt="ai powered" srcset="">
                                </div>
                                <div class="features_cards_desc">
                                    <h3>AI Powered Search</h3>
                                    <p>Trinka’s academic phrasebank uses AI to help you find the right phrase with a simple
                                        search so you can save time while drafting your manuscript.

                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="features_cards">
                                <div class="features_cards_icon">
                                    <img src="../../../assets/images/Ease-to-use.png" alt="easy to navigate" srcset="">
                                </div>
                                <div class="features_cards_desc">
                                    <h3>Easy to Navigate </h3>
                                    <p>Trinka's
                                        <b>academic phrasebank</b> is categorized in logical sections so you can easily find
                                        the right phrase to communicate intended message for every section of your paper.
                                    </p>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="section-pd section-purple">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title text-center">
                    <h2>Trusted by Researchers Worldwide</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>Trinka AI is preferred by researchers worldwide for their writing and publication needs. In addition to Trinka's
                        academic phrasebank, technical writers and academics around the globe rely heavily on its grammar corrections
                        and language enhancements. Unlike most other tools, Trinka goes beyond grammar to ensure holistic language
                        improvements for increased publication success.</p>
                    <div class="btn-group justify-content-center mt-4">
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" target="_blank" rel="noopener noreferrer" class="filled-btn">Try it now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="faqQuestionAnwser section-pd " #target>
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="trinka-title text-center">
                    <h2>Academic Phrasebank FAQ’s</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="faqQuestionsList">
            <div class="accordion" id="faq">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionOne">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                        Why use an academic phrasebank?</a>
                                </div>

                                <div id="QuestionOneContent" class="collapse" aria-labelledby="QuestionOne" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Writing an academic manuscript is challenging, as it not only requires grammatical accuracy
                                            but also requires adhering to several writing conventions while ensuring impactful
                                            communication. Trinka’s
                                            <b>academic phrasebank</b> helps you prepare your manuscript faster by allowing you
                                            to use phrases from standard academic publications without the need for searching
                                            and reading several papers to identify the right phrases. Using Trinka’s academic
                                            phrasebank hence helps you save time and prepare your manuscript more confidently.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="QuestionTwo">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                        aria-controls="QuestionTwoContent">Do I have to pay to use Trinka’s academic phrasebank?</a>
                                </div>

                                <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka’s
                                            <b>academic phrasebank</b> aims to help academic authors and students write effectively.
                                            It is completely free to use, without limitations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionThree">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionThreeContent" aria-expanded="true"
                                        aria-controls="QuestionThreeContent">
                                        Will the use of a phrase from an academic phrasebank be considered as plagiarism?
                                    </a>
                                </div>

                                <div id="QuestionThreeContent" class="collapse" aria-labelledby="QuestionThree" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka’s
                                            <b>academic phrasebank</b> provides you with phrases aimed to achieve a specific communication
                                            objective. Once you use a phrase from Trinka’s academic phrasebank, you will need
                                            to expand on it to complete communicating your message, making the final content
                                            your own unique version. Therefore, using Trinka’s academic phrasebank will not constitute
                                            to plagiarizing.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionFour">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFourContent" aria-expanded="true"
                                        aria-controls="QuestionFourContent">
                                        Which subjects can Trinka’s academic phrasebank be used for?
                                    </a>
                                </div>

                                <div id="QuestionFourContent" class="collapse" aria-labelledby="QuestionFour" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka’s
                                            <b>academic phrasebank</b> includes standard phrases from millions of publications across
                                            all scientific disciplines and hence can be used for any subject.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="wordPluginPopup" tabindex="-1" role="dialog" aria-labelledby="wordPluginLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 0;">
                <button type="button" id="closedPopup" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div id="iframeHolder"></div>

                <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/ingGxMsQgdI"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                <!-- <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/z-TVcRwVp3A"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
            </div>
        </div>
    </div>
</div>
