import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-grammar-without-internet',
  templateUrl: './grammar-without-internet.component.html',
  styleUrls: ['./grammar-without-internet.component.scss']
})
export class GrammarWithoutInternetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Output() scrollToTarget = new EventEmitter<void>();

  triggerScroll() {
    this.scrollToTarget.emit();
  }

}
