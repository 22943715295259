<div class="modal custom_modal_popup enterprisePopup" id="EnterPriseContactPopup">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="closed_icons">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="contactPopupHeading">
                            <h2>Enterprise Inquiry</h2>
                        </div>
                    </div>
                    <div class="inquiryTypeSection">
                        <ul class="nav nav-tabs" role="tablist" *ngIf="formSection">
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#IndividualType" role="tab">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                        <defs>
                                            <style>
                                                .cls-1 {
                                                    fill: none;
                                                    stroke: #783694;
                                                    stroke-linecap: round;
                                                    stroke-linejoin: round;
                                                    stroke-width: 2px;
                                                }
                                            </style>
                                        </defs>
                                        <title>TrinkaIcons-new</title>
                                        <g id="Individual">
                                            <path class="cls-1" d="M44.83,45.13V39.82a4.65,4.65,0,0,0-1.7-3.6,62.85,62.85,0,0,0-10.89-7V22.5a4.4,4.4,0,0,0,1.56-3.36V11.39A7.9,7.9,0,0,0,25.91,3.5H24.24a7.9,7.9,0,0,0-7.89,7.89v7.75a4.32,4.32,0,0,0,1.54,3.36h0v6.68A62.22,62.22,0,0,0,7,36.22a4.62,4.62,0,0,0-1.7,3.6v5.31"
                                            />
                                        </g>
                                    </svg>
                                    <div class="text-left">
                                        <h3>Individual</h3>
                                        <span>(Single user)</span>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#OrganizationType" role="tab">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                        <defs>
                                            <style>
                                                .cls-1 {
                                                    fill: none;
                                                    stroke: #783694;
                                                    stroke-linecap: round;
                                                    stroke-linejoin: round;
                                                    stroke-width: 2px;
                                                }
                                            </style>
                                        </defs>
                                        <title>TrinkaIcons-new</title>
                                        <g id="Organization">
                                            <path class="cls-1" d="M42.87,45.13V40.06a4.45,4.45,0,0,0-1.62-3.44,60,60,0,0,0-10.41-6.72V23.51a4.2,4.2,0,0,0,1.49-3.21V12.89a7.55,7.55,0,0,0-7.54-7.54H23.19a7.55,7.55,0,0,0-7.54,7.54V20.3a4.13,4.13,0,0,0,1.47,3.21h0v6.38A59.44,59.44,0,0,0,6.71,36.62a4.42,4.42,0,0,0-1.62,3.44v5.07"
                                            />
                                            <path class="cls-1" d="M14.34,30.75a24.56,24.56,0,0,0-3.32-2V26.18a1.66,1.66,0,0,0,.59-1.27V22a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v2.93a1.63,1.63,0,0,0,.58,1.27h0v2.53a23.54,23.54,0,0,0-4.12,2.66,1.75,1.75,0,0,0-.64,1.36v2"
                                            />
                                            <path class="cls-1" d="M34.29,30.75a24.56,24.56,0,0,1,3.32-2V26.18A1.66,1.66,0,0,1,37,24.91V22a3,3,0,0,1,3-3h.63a3,3,0,0,1,3,3v2.93A1.63,1.63,0,0,1,43,26.18h0v2.53a23.54,23.54,0,0,1,4.12,2.66,1.75,1.75,0,0,1,.64,1.36v2"
                                            />
                                        </g>
                                    </svg>
                                    <div class="text-left">
                                        <h3>Organization</h3>
                                        <span>(Multiple users)</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane" id="IndividualType" role="tabpanel" *ngIf="formSection">
                                <div class="IndividualTypeSection">
                                    <p>Trinka is free for individual users.
                                        <br /> You can simply register with your email address.</p>
                                    <a href="https://cloud.trinka.ai/signup" target="_blank" rel="noopener noreferrer">Register for Free</a>
                                </div>
                            </div>
                            <div class="tab-pane" id="OrganizationType" role="tabpanel" *ngIf="formSection">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="contact_us_form">
                                            <form [formGroup]="contactForm" (ngSubmit)="sendContactUsForm()">
                                                <h2>Share your requirements with us and our team will respond to you promptly.</h2>
                                                <div class="row">
                                                    <div class="col-md-6 rightPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('NatureBusinessType').valid && contactForm.get('NatureBusinessType').dirty) || (contactForm.get('NatureBusinessType').touched && contactForm.get('NatureBusinessType').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Nature of business*</mat-label>
                                                                <input matInput placeholder="" formControlName="NatureBusinessType" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 leftPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormSubjects').valid && contactForm.get('contactFormSubjects').dirty) || (contactForm.get('contactFormSubjects').touched && contactForm.get('contactFormSubjects').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Organization name*</mat-label>
                                                                <input matInput placeholder="" formControlName="contactFormSubjects" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="textbox_content custom-mat-select form-group position-relative">
                                                            <mat-form-field appearance="outline" class="w-100">
                                                                <mat-label>Inquiry For</mat-label>
                                                                <mat-select formControlName="tsFeatures" name="tsFeatures" multiple>
                                                                <mat-option *ngFor="let feature of trinkaFeatures" [value]="feature">{{ feature }}</mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="textbox_content form-group textareaBox" [ngClass]="(!contactForm.get('contactFormMessage').valid && contactForm.get('contactFormMessage').dirty) || (contactForm.get('contactFormMessage').touched && contactForm.get('contactFormMessage').pristine) ? 'TextFiledError' : '' ">
                                                            <label for="">Tell us a bit about your requirement</label>
                                                            <textarea formControlName="contactFormMessage" class="form-control" placeholder="E.g. approximare number of users, monthly word count volume per user, customizations needed, etc."></textarea>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-md-6 rightPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormName').valid && contactForm.get('contactFormName').dirty) || (contactForm.get('contactFormName').touched && contactForm.get('contactFormName').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Name*</mat-label>
                                                                <input matInput placeholder="" formControlName="contactFormName" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 leftPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('DesignationTypes').valid && contactForm.get('DesignationTypes').dirty) || (contactForm.get('DesignationTypes').touched && contactForm.get('DesignationTypes').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Designation*</mat-label>
                                                                <input matInput placeholder="" formControlName="DesignationTypes" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 rightPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormEmail').valid && contactForm.get('contactFormEmail').dirty) || (contactForm.get('contactFormEmail').touched && contactForm.get('contactFormEmail').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Email*</mat-label>
                                                                <input matInput placeholder="" formControlName="contactFormEmail" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 leftPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactphoneNo').valid && contactForm.get('contactphoneNo').dirty) || (contactForm.get('contactphoneNo').touched && contactForm.get('contactphoneNo').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Contact Number* (incl. ISD code)</mat-label>
                                                                <input type="number" matInput placeholder="" formControlName="contactphoneNo" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group text-center">
                                                    <button type="submit" [disabled]="isSubmitting">Submit</button>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="thank_section contact_us_form" *ngIf="thankSection">
                                <div class="form-group">
                                    <h2>Thank You!</h2>
                                    <p>We will contact you soon.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>