
<div class="trinka_cloud_wrap">
    <div class="cloud_top_banners">
        <section class="mainTopBanner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="topBannerWrapper">
                            <div class="top-banner-left">
                                <h1>Grammar Checker API</h1>
                                <h2>Simple, Fast AI Grammar Check API</h2>
                                    <p>Trinka's AI powered grammar check delivered via a simple RESTful API to enable powerful real-time or passive grammar checks right within your application.</p>
                                    <div class="btn-group">
                                        <button data-toggle="modal"
                                        data-target="#EnterPriseContactPopup" class="filled-btn" type="submit">Contact Us</button>
                                        <a href="https://developer.trinka.ai/docs/getting-started" target="_blank" rel="noopener noreferrer"  id="autoEditGetStart" class="outline-btn">Developer Docs</a>

                                    </div>
                            </div>
                            <div class="top-banner-right">
                                <img src="../../../assets/images/trinka-api.svg" class="img-fluid" alt="Grammar Check API">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


<section class="section-pd trinka-key-features">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
                <div class="trinka-title mb-0 text-center">
                    <h2>Unique Integrations to Power Bespoke Applications</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="gt-features three-coloum">
                    <span class="hr-line"></span>
                    <span class="vr-line l25"></span>
                    <span class="vr-line l50"></span>
                    <span class="vr-line l75"></span>
                    <div class="gt-wrapper c-card">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <circle cx="10" cy="10" r="10" fill="#763393"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                        </svg>
                        <h6>Content Management Systems
                        </h6>
                    </div>
                    <div class="gt-wrapper c-card">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <circle cx="10" cy="10" r="10" fill="#763393"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                        </svg>
                        <h6>Online Writing Platforms</h6>
                    </div>
                    <div class="gt-wrapper c-card">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <circle cx="10" cy="10" r="10" fill="#763393"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                        </svg>
                        <h6>Messaging Apps/Social Platforms</h6>
                    </div>
                    <div class="gt-wrapper c-card">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <circle cx="10" cy="10" r="10" fill="#763393"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                          </svg>
                        <h6>CRM + Helpdesk Software</h6>
                    </div>
                    <div class="gt-wrapper c-card">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <circle cx="10" cy="10" r="10" fill="#763393"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                          </svg>
                        <h6>Email Clients</h6>
                    </div>
                    <div class="gt-wrapper c-card">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <circle cx="10" cy="10" r="10" fill="#763393"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                          </svg>
                        <h6>Project Management Software</h6>
                    </div>
                    <div class="gt-wrapper c-card">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <circle cx="10" cy="10" r="10" fill="#763393"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                          </svg>
                        <h6>E-learning Platforms</h6>
                    </div>
                    <div class="gt-wrapper c-card">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <circle cx="10" cy="10" r="10" fill="#763393"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                          </svg>
                        <h6>Learning Management Systems</h6>
                    </div>
                </div>            
            </div>
        </div>
    </div>
</section>

<div class="writeToolboxs_section section-pd pb-0">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-112">
                <div class="trinka-title mb-0 text-center">
                    <h2>Text or Files - Trinka APIs Let You Choose</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>Build seamless integrations with Trinka grammar check APIs that meet your workflow and technical requirements.</p>
                </div>
            </div>
        </div>
    </div>

    <section class="section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row align-items-center flex-row-reverse">
                <div class="col-md-6">
                    <div class="list-wrapper-content-2 wrapper_left">
                        <h3>Real-time Check API</h3>
                        <p>Trinka's real-time checks power applications that need instant writing corrections on learning platforms, editors, chat applications, and the like.</p>
                        <ul>
                            <li>Flexibility to pass on lines, paragraphs, or larger text collections</li>
                            <li>Simple response payload for all input formats</li>
                            <li>Flexibility to pay by text volume or per API call </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="list-wrapper-image">
                        <img src="../../../assets/images/Real-time Check API.svg" alt="Real-time Check API" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-pd list-wrapper-style-2 pt-0">
        <div class="trinka_container">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="list-wrapper-content-2 pr-80 wrapper_left">
                        <h3>File-edit API</h3>
                        <p>Trinka's file edit API lets you support unique workflow needs while avoiding complex integrations and a high volume of API calls.</p>
                        <ul>
                            <li>Get edited files with a single API call</li>
                            <li>Tracked changes for versioning and user control</li>
                            <li>Writing quality scores and revision summaries for assessments</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="list-wrapper-image">
                        <img src="../../../assets/images/file-edit-api.png" alt="File-edit API" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<div class="cloud_key_features cloudKeyBenefitSection cs-section section-pd">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="trinka-title mb-0 text-center">
                    <h2>Access All of Trinka's Features with a Simple API</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-xl-5">
                <div class="cs-left-section">
                    <p>Trinka grammar check APIs offer comprehensive writing assistance ranging from advanced English grammar corrections to tone and style enhancements. Pick and choose the features that suit your needs!</p>
                    <p>Trinka's grammar and spelling check APIs refine the way you write to match academic writing conventions and best practices while letting you focus more on your ideas.</p>
                    <!-- <p>Trinka stands out by working with the author and going beyond grammar and spelling checks to ensure holistic language enhancement.</p> -->
                    <p>Trinka grammar checker APIs also provide additional features like Technical Phrasing, Academic Tone, Unbiased language, Word Choice, Usage and Style, and more.  </p>
                </div>
            </div>
            <div class="col-lg-7 col-xl-7">
                <div class="xs-features-api">
                    <span class="hline"></span>
                    <span class="vline"></span>
                    <span class="vline vline2"></span>
                    <div class="row match-box">
                        <div class="col-md-6 col-sm-6 col-12 dflex">
                            <div class="features_cards media">
                                <div class="icon media-left">
                                    <img src="../../../../assets/images/icons/Advanced-Grammar.png" alt="Advanced Grammar" />
                                </div>
                                <div class="media-body">
                                    <div class="features_cardsHeading ">
                                        <h3>Advanced Grammar</h3>
                                    </div>
                                    <p>Best for academic & formal communication
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-12 dflex">
                            <div class="features_cards media no-border-before">
                                <div class="icon media-left">
                                    <img src="../../../../assets/images/icons/Icon_Dictionary.png" alt="Custom Dictionaries" />
                                </div>
                                <div class="media-body">
                                    <div class="features_cardsHeading">
                                        <h3>Custom Dictionaries</h3>
                                    </div>
                                    <p>Improve suggestions and stay on brand
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-12 dflex">
                            <div class="features_cards media">
                                <div class="icon media-left">
                                    <img src="../../../../assets/images/icons/Icon_title.png" alt="Contextual Spelling" />
                                </div>
                                <div class="media-body">
                                    <div class="features_cardsHeading">
                                        <h3>Contextual Spelling </h3>
                                    </div>
                                    <p>Picks correct terms based on your subject
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-12 dflex">
                            <div class="features_cards media">
                                <div class="icon media-left">
                                    <img src="../../../../assets/images/icons/Icon_Pass_02.png" alt="Tips & Explanations" />
                                </div>
                                <div class="media-body">
                                    <div class="features_cardsHeading">
                                        <h3>Tips & Explanations</h3>
                                    </div>
                                    <p>Explainable AI suggestions for users
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-12 dflex">
                            <div class="features_cards media">
                                <div class="icon media-left">
                                    <img src="../../../../assets/images/icons/Non-standard-2.png" alt="Style Guide Preference" />
                                </div>
                                <div class="media-body">
                                    <div class="features_cardsHeading">
                                        <h3>Style Guide Preference </h3>
                                    </div>
                                    <p>Suggests corrections based on style guides (AMA, APA, etc.)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-12 dflex">
                            <div class="features_cards media">
                                <div class="icon media-left">
                                    <img src="../../../../assets/images/icons/Icon_ExpertProofreading.png" alt="Subject-specific Corrections" />
                                </div>
                                <div class="media-body">
                                    <div class="features_cardsHeading">
                                        <h3>Subject-specific Corrections</h3>
                                    </div>
                                    <p>Automatic subject detection & precise suggestions
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="trinka-banner section-pd-sm">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
                <div class="trinka-title text-center">
                    <h3 class="text-white">See What Trinka Grammar Checker API Can Do for You</h3>
                </div>
                <div class="btn-group justify-content-center">
                    <button data-toggle="modal" data-target="#EnterPriseContactPopup"
                    class="white-btn" type="submit">Contact Us</button>
                </div>
            </div>
        </div>  
    </div>
</div>


<div class="cloud_key_features section-pd list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
                <div class="trinka-title mb-0 text-center">
                    <h2>Unique Use Cases Powered by Trinka Grammar Check API</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-none">
                    <h3>E-Learning Applications</h3>
                    <p>The Trinka grammar check API has helped application developers polish their learners' writing to meet academic standards.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <h3>Publishing & Editorial</h3>
                    <p>Trinka's grammar checking API helps editorial staff with assistive text correction, saving them time and cost to quality.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <h3>Student<br class="d-lg-block d-none"/>Forums</h3>
                    <p>The robust checks by Trinka's grammar check API have students communicate effectively in forums & groups, improving student engagement.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1">
                    <h3>Businesses<br class="d-lg-block d-none"/>&nbsp;</h3>
                    <p>Trinka's grammar check API has empowered several teams to write better without sacrificing on clarity, consistency or brand perception.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1">
                    <h3>Productivity Platforms</h3>
                    <p>Trinka's real-time API let productivity platforms offer unique writing assistance to help their users be more productive.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="section-pd section-purple">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-10">
                <div class="trinka-title text-center">
                    <h2>Flexible Pricing that Works Best for You</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>We work with you to identify the best pricing model that gives you the best value. We support text volume based, file volume based, or API call volume based pricing options to match your exact need.</p>
                    <div class="btn-group justify-content-center mt-4">
                        <button data-toggle="modal"
                        data-target="#EnterPriseContactPopup" class="filled-btn" type="submit">Get a Quote</button>
                        <a href="https://calendly.com/trinkaai/meet" target="_blank" rel="noopener noreferrer"  id="autoEditGetStart" class="outline-btn">Book a Demo</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="trinka-banner section-pd-sm">
    <div class="trinka_container">
        <div class="row align-items-center justify-content-center">
            <div class="col-md-6 col-lg-6">
                <div class="trinka-title">
                    <h3 class="text-white mb-4">Trinka Simplifies Invoices with Support for Local Currency.</h3>
                    <p class="text-white">Trinka offers seamless invoices  (Seikyu-sho, Fapiao, Yeongeumjeung, Fatura) in multiple local currencies of JPY, CNY, BRL, TRY, KRW, INR, USD, EUR, TWD. Organizations can also ease the invoicing process  by  clubbing multiple accounts into a single invoice in their own local currency.  </p>
                </div>
                <div class="btn-group">
                    <a [routerLink]="['/local-invoice']" class="white-btn">Check Invoice Options</a>
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div class="text-center">
                    <img src="../../../assets/images/local-inovice-element.png" alt="local invoice" class="img-fluid" />
                </div>
            </div>
        </div>  
    </div>
</div>

<section class="section-pd list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row align-items-center flex-row-reverse">
            <div class="col-md-6">
                <div class="list-wrapper-content-2">
                    <h3>Safe, Secure, and Trusted</h3>
                        <p>Trinka puts data safety and privacy at the forefront. All your data is strongly encrypted and securely
                            stored - no one else has access to your data. We offer unique plans that completely eliminate
                            saving any data once you receive writing suggestions.
                        </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image">
                    <img src="../../../assets/images/safe-secure.png" alt="safe" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd list-wrapper-style-2 pt-0">
    <div class="trinka_container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="list-wrapper-content-2 pr-80">
                    <h3>About Trinka</h3>
                    <p>Trinka uses the latest Machine Learning (ML) and Natural Language Processing (NLP) technologies to
                        correct grammar errors and suggest language enhancements. Our AI is trained on millions of well-written papers and articles across a wide range of subject areas, including medical, life sciences, physical sciences, engineering, humanities, business, and arts, to provide you with the most relevant suggestions.</p>
                    <p>Trinka is developed by a team of linguists, editors, data scientists, and engineers with a deep desire
                        to create a future with barrier-free communication.
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image">
                    <img src="../../../assets/images/about-ai.png" alt="safe" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>

<div class="faqQuestionAnwser section-pd pt-0" #target>
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="trinka-title text-center">
                    <h2>Frequently Asked Questions</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="faqQuestionsList">
            <div class="accordion" id="faq">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionOne">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                        Who is a grammar or spell check API for?</a>
                                </div>
    
                                <div id="QuestionOneContent" class="collapse" aria-labelledby="QuestionOne" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Our grammar checker APIs can be used for many different uses. Here are some of the things that our current clients use our API for:</p>
                                        <ol>
                                            <li>Automated marking of essays.</li>
                                            <li>Adding <a href="/grammar-checker" class="text-link">grammar checking</a> to text editors in applications.</li>
                                            <li>Improving the quality of copy editing services.</li>
                                            <li>Checking websites for grammar issues.</li>
                                            <li>Providing feedback for learners of English.</li>
                                            <li>Checking translated text for grammar mistakes.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="QuestionIcons">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionIconsContent" aria-expanded="true"
                                        aria-controls="QuestionIconsContent">
                                        Why Trinka Grammar Checker API?</a>
                                </div>
    
                                <div id="QuestionIconsContent" class="collapse" aria-labelledby="QuestionIcons" data-parent="#faq">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="" cellpadding="5">
                                                        <tr>
                                                            <td>✅ Checks</td>
                                                            <td>Advanced Grammar & Spelling</td>
                                                        </tr>
                                                        <tr>
                                                            <td>⌛ AI-powered</td>
                                                            <td>Restful API</td>
                                                        </tr>
                                                        <tr>
                                                            <td>✍ Data Privacy</td>
                                                            <td>Safe, Secure, Trusted</td>
                                                        </tr>
                                                        <tr>
                                                            <td>🔠 Features</td>
                                                            <td>Style Guide Preference, Subject-specific Corrections, Contextual Spelling</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="QuestionTwo">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                        aria-controls="QuestionTwoContent">
                                        Can I integrate real-time checking into my text editor?</a>
                                </div>
    
                                <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Integrating real-time checking into a text editor is a common use case. Grammar checking has become an expected feature anytime somebody writes. We've made it easy to integrate grammar checking into any online editor. We've build a grammar checking component that makes this easy. You should be able to integrate grammar and spellchecking into your editor in a few minutes. It's also easy to customize the look of the component so it matches your company styles. You can even add keyboard shortcuts and other customizations.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionFour">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFourContent" aria-expanded="true"
                                        aria-controls="QuestionFourContent">
                                        Is there a Free trial?
                                    </a>
                                </div>
    
                                <div id="QuestionFourContent" class="collapse" aria-labelledby="QuestionFour" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Yes, you can try our API for free. Just <a data-toggle="modal" data-target="#EnterPriseContactPopup">reach out to us!</a></p>
                                    </div>
                                </div>
                            </div>
    
                            <div class="card">
                                <div class="card-header" id="QuestionFive">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFiveContent" aria-expanded="true"
                                        aria-controls="QuestionFourContent">
                                        Is the API documentation available?
                                    </a>
                                </div>
    
                                <div id="QuestionFiveContent" class="collapse" aria-labelledby="QuestionFive" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Yes! We provide detailed documentation and integration support to help you integrate the Trinka API. Just <a data-toggle="modal" data-target="#EnterPriseContactPopup">reach out to us!</a></p>
                                    </div>
                                </div>
                            </div>
    
                            <div class="card">
                                <div class="card-header" id="QuestionSix">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionSixContent" aria-expanded="true"
                                        aria-controls="QuestionFourContent">
                                        Are there any additional costs included to start working with the Grammar Checker API?
                                    </a>
                                </div>
    
                                <div id="QuestionSixContent" class="collapse" aria-labelledby="QuestionSix" data-parent="#faq">
                                    <div class="card-body">
                                        <p>No, there are no setup fees of any kind. <a data-toggle="modal" data-target="#EnterPriseContactPopup">Get Started</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-enterprise-modal></app-enterprise-modal>