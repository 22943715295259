<div class="top-tools-tab-mobile" id="tabs-tools" role="tablist">
    <a [routerLink]="['/grammar-checker']" role="tab" aria-selected="false" tabindex="-1" id="tab-grammar">
        <img src="../../../../assets/images/menu-icons/grammar-checker.svg" alt="grammar-checker">
        <p>Grammar Checker</p>
    </a>
    <a [routerLink]="['/paraphrasing-tool']" role="tab" aria-selected="false" tabindex="-1" id="tab-paraphraser">
        <img src="../../../../assets/images/menu-icons/pharaphraser.svg" alt="Paraphraser">
        <p>Paraphraser</p>
    </a>
    <a [routerLink]="['/journal-finder']" role="tab" aria-selected="true" class="active"  tabindex="0" id="tab-jorunal-finder">
      <img src="../../../../assets/images/menu-icons/journal-finder.svg" alt="Journal Finder">
      <p>Journal Finder</p>
    </a>
    <a [routerLink]="['/ai-content-detector']" role="tab" aria-selected="false" tabindex="-1" id="tab-ai-detector">
        <img src="../../../../assets/images/menu-icons/ai-detector.svg" alt="AI Detector">
        <p>AI Detector</p>
    </a>
    <a [routerLink]="['/academic-phrasebank']" role="tab" aria-selected="true"  tabindex="0" id="tab-phrasebank">
        <img src="../../../../assets/images/menu-icons/phrasebank.svg" alt="Academic Phrasebank">
        <p>Academic Phrasebank</p>
    </a>
</div>
  
<app-sage-journal-tool></app-sage-journal-tool>

<section class="section-how-it-works section-pd-sm bg-white">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12">
                <div class="trinka-title text-center">
                    <h2>Try our Sage journal finder for publication success</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p class="w-100">Boost your chances of publication success with our Sage Journal Finder. Choosing the right journal enhances your research's impact. Discover Sage journals that align with your topic, scope, and audience. Take into account factors such as indexing, open access, and publication speed. </p>
                    <p class="w-100">Simplify your search using tools like Trinka’s Sage Journal Finder, tailored to match your manuscript and improve acceptance rates.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <ul>
                    <li>
                        <div class="login_circle_section bg-light-purple">
                            <svg width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20.9983 10C20.9862 7.82497 20.8897 6.64706 20.1213 5.87868C19.2426 5 17.8284 5 15 5H12C9.17157 5 7.75736 5 6.87868 5.87868C6 6.75736 6 8.17157 6 11V16C6 18.8284 6 20.2426 6.87868 21.1213C7.75736 22 9.17157 22 12 22H15C17.8284 22 19.2426 22 20.1213 21.1213C21 20.2426 21 18.8284 21 16V15" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"></path> <path d="M3 10V16C3 17.6569 4.34315 19 6 19M18 5C18 3.34315 16.6569 2 15 2H11C7.22876 2 5.34315 2 4.17157 3.17157C3.51839 3.82475 3.22937 4.69989 3.10149 6" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
                        </div>
                        <h3>Enter <br class="d-lg-block d-none"/>Abstract</h3>
                        <p class="mt-2">Enter your manuscript's abstract, and Trinka will analyze it to pinpoint key concepts and focus areas.</p>
                    </li>
                    <li>
                        <span class="arrow">
                            <svg fill="#a8a8a8" width="64px" height="64px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#a8a8a8"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8.489 31.975c-0.271 0-0.549-0.107-0.757-0.316-0.417-0.417-0.417-1.098 0-1.515l14.258-14.264-14.050-14.050c-0.417-0.417-0.417-1.098 0-1.515s1.098-0.417 1.515 0l14.807 14.807c0.417 0.417 0.417 1.098 0 1.515l-15.015 15.022c-0.208 0.208-0.486 0.316-0.757 0.316z"></path> </g></svg>
                        </span>
                        <div class="login_circle_section">
                            <svg width="48px" height="48px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" stroke-width="3" stroke="#7A28A0" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><polyline points="33.85 55.28 12.44 55.28 12.44 19.61 24.39 6.75 50.05 6.75 50.05 38.42"></polyline><polyline points="24.41 6.75 24.39 19.61 12.44 19.61"></polyline><line x1="17.59" y1="46.68" x2="29.6" y2="46.68"></line><line x1="17.59" y1="40.15" x2="31.6" y2="40.15"></line><line x1="17.59" y1="34.05" x2="39.6" y2="34.05"></line><line x1="17.59" y1="27.95" x2="44.6" y2="27.95"></line><circle cx="41.76" cy="47.06" r="7.66"></circle><line x1="46.96" y1="52.69" x2="52.73" y2="58.95"></line></g></svg>
                        </div>
                        <h3>Find Related <br class="d-lg-block d-none"/>Journals</h3>
                        <p class="mt-2">Based on your abstract, Trinka will recommend a curated list of Sage journals that match your research topic and scope.</p>
                        <span class="arrow arrow1">
                            <svg fill="#a8a8a8" width="64px" height="64px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#a8a8a8"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8.489 31.975c-0.271 0-0.549-0.107-0.757-0.316-0.417-0.417-0.417-1.098 0-1.515l14.258-14.264-14.050-14.050c-0.417-0.417-0.417-1.098 0-1.515s1.098-0.417 1.515 0l14.807 14.807c0.417 0.417 0.417 1.098 0 1.515l-15.015 15.022c-0.208 0.208-0.486 0.316-0.757 0.316z"></path> </g></svg>
                        </span>
                    </li>
                    <li>
                        <div class="login_circle_section">
                            <svg width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0607 3H5.25V21H12V19.5H6.75V4.5H15.4393L17.25 6.31066V13.5H18.75V5.68934L16.0607 3ZM15.75 9.75H8.25V8.25H15.75V9.75ZM15.75 12.75H8.25V11.25H15.75V12.75ZM12 15.75H8.25V14.25H12V15.75ZM15.0578 20.569L19.2845 16.2762L18.2157 15.2238L15.0578 18.431L13.2845 16.6301L12.2157 17.6825L15.0578 20.569Z" fill="#7A28A0"></path> </g></svg>
                        </div>
                        <h3>Select/Analyze the<br class="d-lg-block d-none"/> Best Result</h3>
                        <p class="mt-2">Review the suggested journals, compare their articles, and filter by region, impact factor, or personal preferences to find the correct match for your research.</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<div class="howItWorks section-pd list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="list-wrapper-image">
                    <a href="" data-toggle="modal" data-target="#wordPluginPopup" id="VewiVideoSection">
                        <img src="/assets/images/JournalFinderThumbnail.jpg" loading="lazy" decoding="async" alt="video thumbnail" class="img-fluid eposter-img">
                    </a>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="list-wrapper-content-2">
                    <div class="trinka-title mb-0">
                        <h2>See How It Works</h2>
                        <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                        <p>Using the Trinka Sage Journal Finder is simple and convenient. Just enter your research article abstract, and the tool will analyze it against a comprehensive database of Sage journals to identify the most relevant options for your research.</p>
                        <p class="mb-0">Still have questions? Browse through our FAQs <a style="color: #0000ff;" (click)="scroll(target)" class="link text-link">here</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="section-pd bg-white">
    <div class="trinka_container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="trinka-title">
                    <h2>How Trinka’s Sage Journal Finder Can Help</h2>
                    <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                    <p>Trinka’s Sage Journal Finder streamlines the journal selection process by analyzing your manuscript and offering tailored recommendations. It factors in impact scores, geography, and H-index to quickly match your abstract with the ideal journal. </p>
                    <p>With its intuitive interface, the tool saves time and simplifies navigation, making it an invaluable resource for researchers seeking publication in Sage journals.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image features-img">
                    <img src="/assets/images/features/s1.svg" loading="lazy" decoding="async" class="img-fluid" alt="journal-finder">
                </div>
            </div>
        </div>
    </div>
</section>

<div class="cloud_key_features section-pd pt-0 list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-10">
                <div class="trinka-title mb-0 text-center">
                    <h2>Key Features of Trinka’s Journal Finder for Sage</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p class="w-70">Trinka’s Journal Finder for Sage publication is designed to simplify your publishing journey. It offers an intuitive interface that allows researchers to input their article details easily, resulting in tailored journal suggestions.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-none pr-0">
                    <img src="../../../assets/images/icons/AI-power.svg" loading="lazy" decoding="async" alt="AI-Powered Journal Recommendations">
                    <h3>AI-Powered<br class="d-md-block d-none"/> Suggestions</h3>
                    <p>Matches your manuscript abstract with relevant Sage journals using proprietary AI algorithms.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards pr-0">
                    <img src="../../../assets/images/icons/all-subjects.svg" height="40" loading="lazy" decoding="async" alt="Works for all Subjects Areas">
                    <h3>Works Across All Disciplines</h3>
                    <p>Supports a broad range of subjects through a vast database of published papers.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards pr-0">
                    <img src="../../../assets/images/icons/large-database.svg" height="40" loading="lazy" decoding="async" alt="Large Database of Scientific Publications">
                    <h3>Comprehensive Journal Database</h3>
                    <p>Leverages data from reliable sources like OpenAlex, Crossref, and PubMed.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1 pr-0">
                    <img src="../../../assets/images/icons/publication-trend.svg" height="40" loading="lazy" decoding="async" alt="Publication Trends to Understand Citability">
                    <h3>Understand Publication Trends</h3>
                    <p>Displays trends in your research field to help gauge your work's visibility.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1 pr-0">
                    <img src="../../../assets/images/icons/similar.svg" height="40" loading="lazy" decoding="async" alt="Similar Articles for Perfect Scope Match">
                    <h3>Find Similar Articles</h3>
                    <p>Provides related papers to ensure a perfect scope match and minimize desk rejections.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1 pr-0">
                    <img src="../../../assets/images/icons/search-intutive.svg" height="40" loading="lazy" decoding="async" alt="Intuitive Filters for Refined Search">
                    <h3>Smart<br class="d-lg-block d-none"/> Filters</h3>
                    <p>Refine results by subject, open access, impact factor, and more for accurate recommendations.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-global-leaders></app-global-leaders>

<div class="section-pd">
    <div class="trinka_container">
        <div class="row">
            <div class="col-sm-12">
                <div class="trinka-title mb-0 text-center">
                    <h2>Find the Best Sage Journal for Your Research</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p class="w-100">The Trinka Sage Journal Finder is an invaluable resource for researchers looking to publish their work in the right Sage journal. By analyzing your manuscript’s abstract, it compares your content against Sage’s extensive journal database, offering personalized recommendations. This smart matching process helps you discover journals that align perfectly with your research, boosting your chances of acceptance and successful publication.
                    </p>
                    <div class="btn-group justify-content-center mt-4">
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" target="_blank" rel="noopener noreferrer" class="filled-btn" id="grammerSignUpButton">
                            <!-- <i class="fa fa-sign-in"></i> -->
                            <span>Sign Up for Free</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="cloud_key_features section-pd list-wrapper-style-2 section-purple">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title mb-0 text-center">
                    <h2>Who can use the Sage journal finder?</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>Whether you’re a seasoned academic or just starting your research journey, the Sage Journal Finder is designed for scholars across all disciplines. It’s the perfect tool for anyone striving to publish in reputable journals and make their research stand out.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-none">
                    <h3>Researchers</h3>
                    <p>
                        Designed for scholars in every field, this tool provides customized Sage journal suggestions tailored to your area of study. It helps connect your research with the right journals effortlessly.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <h3>Publisher</h3>
                    <p>
                        Publishers can leverage the Sage Journal Finder to align their content with suitable journals, enhancing visibility and streamlining research paper submissions to strengthen their research portfolios.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1">
                    <h3>Graduate Student</h3>
                    <p>
                        For those new to academic publishing, the Sage Journal Finder offers invaluable support, guiding students to journals that match their research focus and simplifying the often daunting publication process.                        
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="faqQuestionAnwser section-pd" #target>
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="trinka-title text-center">
                    <h2>FAQs on Sage Journal Finder</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="faqQuestionsList">
            <div class="accordion" id="faq">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionOne">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                        How to Get access to Sage Journals?
                                    </a>
                                </div>

                                <div id="QuestionOneContent" class="collapse" aria-labelledby="QuestionOne" data-parent="#faq">
                                    <div class="card-body">
                                        <p>You can access the Sage Journal List by visiting the Sage website or using our Trinka Sage Journal Finder tool. The list includes all available journals across various disciplines.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionTwo">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                        aria-controls="QuestionTwoContent">
                                        How to find a suitable journal in Sage Publication?
                                    </a>
                                </div>

                                <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                    <div class="card-body">
                                        <p>If you're looking for the right journal to publish your article in Sage, try our Trinka Sage Journal Finder. Simply input your article’s abstract or description, and our tool will provide a list of relevant Sage journals that match your research.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionThree">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionThreeContent" aria-expanded="true"
                                        aria-controls="QuestionThreeContent">
                                        What is the Sage Journal Finder?
                                    </a>
                                </div>

                                <div id="QuestionThreeContent" class="collapse" aria-labelledby="QuestionThree" data-parent="#faq">
                                    <div class="card-body">
                                        <p>The Sage Journal Suggester is a specialized research tool designed to help users identify the most suitable Sage journal for publishing their research.</p>
                                        <!-- <p>No, accessing the Trinka Journal Finder is free. Users who have created their free account, can access the full features for free. So <a href="https://cloud.trinka.ai/signup" target="_blank" rel="noopener noreferrer" class="text-link">create your free account</a> or <a href="https://cloud.trinka.ai/signin" target="_blank" rel="noopener noreferrer" class="text-link">sign-in</a> now.</p> -->
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionFour">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFourContent" aria-expanded="true"
                                        aria-controls="QuestionFourContent">
                                        What factors should I consider when selecting a journal?
                                    </a>
                                </div>

                                <div id="QuestionFourContent" class="collapse" aria-labelledby="QuestionFour" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Once you receive the Sage journal recommendations, consider the following factors for each journal:</p>
                                        <ul>
                                            <li>Scope and Relevance</li>
                                            <li>Impact factor and Cite Score</li>
                                            <li>Audience</li>
                                            <li>Open Access</li>
                                            <li>Indexing and Database Coverage</li>
                                            <li>Publication speed and indexing databases.</li>
                                            <li>Peer Review Process</li>
                                            <li>Reputation and Acceptance Rate</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="faqContainer">

                            <div class="card">
                                <div class="card-header" id="QuestionFive">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFiveContent" aria-expanded="true"
                                        aria-controls="QuestionFiveContent">
                                        Is the Sage Journal Finder free to use?
                                    </a>
                                </div>

                                <div id="QuestionFiveContent" class="collapse" aria-labelledby="QuestionFive" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Yes, Trinka Sage Journal Suggester is free and available online for all researchers and students. Users who have created their free account, can access the full features for free. So <a href="https://cloud.trinka.ai/signup" target="_blank" rel="noopener noreferrer" class="text-link">create your free account</a> or <a href="https://cloud.trinka.ai/signin" target="_blank" rel="noopener noreferrer" class="text-link">sign-in</a> now.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionSix">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionSixContent" aria-expanded="true"
                                        aria-controls="QuestionSixContent">
                                        How do I find a journal with a high impact factor using Trinka Sage Journal Selector?
                                    </a>
                                </div>

                                <div id="QuestionSixContent" class="collapse" aria-labelledby="QuestionSix" data-parent="#faq">
                                    <div class="card-body">
                                        <p>The Sage Journal Finder will suggest journals based on your article's content, and you can review each journal's impact factor on their individual pages to select the one with the most relevance and prestige.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionSeven">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionSevenContent" aria-expanded="true"
                                        aria-controls="QuestionSevenContent">
                                        How frequently is the Sage Journal Finder updated?
                                    </a>
                                </div>

                                <div id="QuestionSevenContent" class="collapse" aria-labelledby="QuestionSeven" data-parent="#faq">
                                    <div class="card-body">
                                        <p>The tool is regularly updated to reflect changes in journal content, scope, and other key factors to provide the most accurate recommendations.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="wordPluginPopup" tabindex="-1" role="dialog" aria-labelledby="wordPluginLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 0;">
                <button type="button" id="closedPopup" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div id="iframeHolder"></div>


                    <iframe  class="youtubeVideoForTryDemo" id="iframe"  width="100%" height="450" src="https://www.youtube.com/embed/fuWNwHYFPFM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <!-- <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/z-TVcRwVp3A"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
            </div>
        </div>
    </div>
</div>