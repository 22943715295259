<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>

    <section class="mainTopBanner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="topBannerWrapper">
                        <div class="top-banner-left">
                            <h1>Legal Writing</h1>
                            <h2>Legal Writing Made Easy</h2>
                            <p>Apply industry-accepted style guides to write consistently and save time.</p>
                            <div class="btn-group">
                                <a href="https://cloud.trinka.ai/signup?_rg=GB" class="filled-btn" target="_blank" rel="noopener noreferrer">Get Started</a>
                            </div>
                        </div>
                        <div class="top-banner-right">
                            <img src="../../../../assets/images/legal-writing/legal-writing-banner.png" loading="lazy" decoding="async" alt="grammar checker for legal writing" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="legal-style-guide section-pd">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Legal Style Guides</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="legal-card">
                <div class="media">
                    <div class="media-left">
                        <img src="../../../../assets/images/legal-writing/redbook.png" loading="lazy" class="img-fluid" alt="The Redbook A Manual On Legal Style" />
                    </div>
                    <div class="media-body">
                        <h3>The Redbook:<br/> A Manual On Legal Style </h3>
                        <p>Trinka incorporates the legal style guide, The Redbook 3<sup>rd</sup> edition. This allows you to integrate the Redbook's comprehensive style rules directly into your writing process. You can upload your document, and Trinka will analyze and suggest replacements to align with the Redbook style guide, enhancing the clarity of your legal documents.</p>
                    </div>
                </div>
                <div class="row match-box">
                    <div class="col-md-4 dflex">
                        <div class="c-card">
                            <p>The changes were incorporated in the  <span class="strike">25th Amendment.</span><span class="success d-inline">Twenty-fifth Amendment.</span></p>
                        </div>
                    </div>
                    <div class="col-md-4 dflex">
                        <div class="c-card">
                            <p>The plaintiff alleged that the defendant's actions during <span class="strike d-inline">the July 14,</span> <span class="strike">2004 campaign</span><span class="success d-inline">the 2004 campaign</span> constituted a breach of electoral laws.</p>
                        </div>
                    </div>
                    <div class="col-md-4 dflex">
                        <div class="c-card">
                            <p>In <span class="strike">Bausch’s and Lomb’s</span><span class="success">Bausch and Lomb’s</span> lawsuit, the court's focus was primarily on the defendants' assets. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="legal-card">
                <div class="media">
                    <div class="media-body">
                        <h3 style="color: #485DAA;">
                            Handbook on Combating Gender Stereotypes
                            by Supreme Court of India 
                        </h3>
                        <p>Trinka integrates the 2023 Handbook on Combating Gender Stereotypes by Supreme Court of India, streamlining the application of its style rules into your legal documents. Upload your document, and Trinka will provide alerts for non-inclusive content, aligning your writing with the handbook's standards for promoting stereotype-free legal communication.</p>
                        <div class="btn-group">
                            <a href="../../../../assets/images/legal-writing/SupremeCourtIndia-GenderStereotypesHandbook.pdf" target="_blank" rel="noopener noreferrer" class="filled-btn btn-sm">View PDF</a>
                            <a href="../../../../assets/images/legal-writing/Handbook_on_Combating_Gender_Stereotypes_by_Supreme_Court_of_India_Sample.docx" target="_blank" rel="noopener noreferrer" class="outline-btn btn-sm">View Sample</a>
                        </div>
                    </div>
                    <div class="media-left">
                        <img src="../../../../assets/images/legal-writing/handbook.png" width="224" loading="lazy" class="img-fluid" alt="Handbook on Combating Gender Stereotypes by Supreme Court of India" />
                    </div>
                </div>
                <div class="row match-box">
                    <div class="col-md-4 dflex">
                        <div class="c-card">
                            <p>He <span class="strike">further</span> <span class="success d-inline">also</span> highlights the unexplained <span class="strike">inordinate</span> <span class="success">excessive</span> delay of over two years in lodging the FIR.</p>
                        </div>
                    </div>
                    <div class="col-md-4 dflex">
                        <div class="c-card">
                            <p>The Defendant was a giddy boy and seduced to gratify the lewdness of a <span class="strike">wanton</span> woman.</p>
                        </div>
                    </div>
                    <div class="col-md-4 dflex">
                        <div class="c-card">
                            <p>The victim is a transvestite, <span class="strike d-inline">born a girl</span> <span class="success d-inline">assigned female at birth</span>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-pd list-wrapper-style-2 legal-features">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-50 text-center">
                        <h2>Key Features of Trinka</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none modern-legal">
                        <h3>Modern Legal Writing</h3>
                        <p>Trinka draws upon the guidance by authoritative legal writing guides to make your legal drafts persuasive, direct, and concise by using modern phrasing, without changing terms of art.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="correct-statement">
                        <span class="shape" style="background-color: #FFE5C8;"></span>
                        <p><span class="strike">There was a search warrant signed</span><span class="success"> A search warrant was signed</span> by the aforementioned magistrate.</p>
                    </div>
                </div>
            </div>
            <div class="section-pd pb-0">
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2 list-style-cards bt-none consistency">
                            <h3>Consistency</h3>
                            <p>Trinka helps you keep specific phrases and entities consistent in terms of spelling, hyphenation, and number style.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="correct-statement">
                            <span class="shape" style="background-color: #EBFFDE;"></span>
                            <span class="features-title" style="color: #0E7490; background:  #A5F3FC;">hyphen/en dash inconsistency</span>
                            <p>In vertical agreements, the <span class="underlined">buyer-seller</span> relationship differs from horizontal agreements, posing less risk of competition elimination than <span class="underlined">buyer–seller</span> interactions among competitors.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-pd pb-0">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2 list-style-cards bt-none inclusive-writing">
                            <h3>Inclusive Legal Writing</h3>
                            <p>Trinka ensures your writing is respectful, and free from bias or offensive language, helping you communicate more effectively and professionally.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="correct-statement">
                            <span class="shape" style="background-color: #EAD5FF;"></span>
                            <p>The Employer recruited underpaid <span class="strike">illegal immigrants</span><span class="success d-inline">undocumented immigrants</span> between 1975 and 1987.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-pd pb-0">
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2 list-style-cards bt-none legal-terms">
                            <h3>Legal Terms of Art</h3>
                            <p>Trinka provides contextual corrections and avoids flagging valid legal terms. This database is routinely updated. </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="correct-statement">
                            <span class="shape" style="background-color: #DFFFF8;"></span>
                            <p>Bank A announced the <span class="strike">abolishment</span> <span class="success d-inline">abolition</span> of a series of documents issued in 1991. [Garner, Bryan A., et al. “§ 12 Troublesome Words.” The Redbook: A Manual on Legal Style, West Academic Publishing, St. Paul, MN, 2018.]</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="trinka-banner section-pd-sm">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-12 col-lg-12">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Get Customized Style Guide for Your Law Firm</h3>
                    </div>
                    <div class="btn-group justify-content-center">
                        <a [routerLink]="['/contactus/']" routerLinkActive="active" class="white-btn">Contact Us</a>
                    </div>
                </div>
            </div>  
        </div>
    </div>


    <div class="allAboutTrinkaCredit list-wrapper-style-2 section-pd" style="background-color: #F6FAFF;">
        <div class="trinka_container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="aboutTrinkaCreditHeading list-wrapper-content-2 p-0">
                        <div class="trinka-title mb-0">
                            <span class="trinka-sub-title">For Enterprise</span>
                            <h2>Your Data is Secure</h2>
                            <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                            <ul class="bullets-box">
                                <li>Trinka incorporates real-time deletion, ensuring that no user content is persistently stored.</li>
                                <li>Your data is not stored in our modules, ensuring its safety.</li>
                                <li>We don't use your data for our model training.</li>
                            </ul>
                            <div class="btn-group">
                                <button type="button" class="filled-btn" data-toggle="modal"
                                data-target="#EnterPriseContactPopup">Contact Us</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="api_img text-center">
                        <img src="../../../../assets/images/legal-writing/data-secure.svg" class="img-fluid" alt="data security with trinka" />
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="cloud_key_features section-pd list-wrapper-style-2 custom-styles">
        <span class="shape"></span>
        <span class="shape shape2"></span>
        <span class="shape shape3"></span>
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Customize Trinka to Align With Your House Style</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="row match-box">
                <div class="col-lg-6 col-md-6 dflex">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
                            <path d="M13.4999 36.6426H8.13328C5.08328 36.6426 2.58325 34.1593 2.58325 31.0927V21.426C2.58325 20.7427 3.14992 20.176 3.83325 20.176H13.4999C16.6666 20.176 19.0499 22.5593 19.0499 25.726V31.0927C19.0333 34.2593 16.6499 36.6426 13.4999 36.6426ZM5.08325 22.6926V31.1094C5.08325 32.7927 6.44995 34.1593 8.13328 34.1593H13.4999C15.2666 34.1593 16.5499 32.876 16.5499 31.1094V25.7426C16.5499 23.976 15.2666 22.6926 13.4999 22.6926H5.08325Z" fill="#763393"/>
                            <path d="M3.83325 22.6925C3.14992 22.6925 2.58325 22.1259 2.58325 21.4425C2.58325 11.0259 4.69996 8.8426 10.75 5.25927C11.35 4.90927 12.1166 5.10928 12.4666 5.69262C12.8166 6.29262 12.6166 7.05923 12.0332 7.40923C6.63322 10.6092 5.08325 11.9425 5.08325 21.4425C5.08325 22.1259 4.51659 22.6925 3.83325 22.6925Z" fill="#763393"/>
                            <path d="M32.8668 36.6426H27.5002C24.4502 36.6426 21.9502 34.1593 21.9502 31.0927V21.426C21.9502 20.7427 22.5169 20.176 23.2002 20.176H32.8668C36.0335 20.176 38.4169 22.5593 38.4169 25.726V31.0927C38.4169 34.2593 36.0335 36.6426 32.8668 36.6426ZM24.4669 22.6926V31.1094C24.4669 32.7927 25.8336 34.1593 27.5169 34.1593H32.8835C34.6502 34.1593 35.9336 32.876 35.9336 31.1094V25.7426C35.9336 23.976 34.6502 22.6926 32.8835 22.6926H24.4669Z" fill="#763393"/>
                            <path d="M23.2166 22.6925C22.5332 22.6925 21.9666 22.1259 21.9666 21.4425C21.9666 11.0259 24.0833 8.8426 30.1333 5.25927C30.7333 4.90927 31.4999 5.10928 31.8499 5.69262C32.1999 6.29262 31.9999 7.05923 31.4165 7.40923C26.0165 10.6092 24.4666 11.9425 24.4666 21.4425C24.4666 22.1259 23.8999 22.6925 23.2166 22.6925Z" fill="#763393"/>
                        </svg>
                        <h3>Adaptability and Customization</h3>
                        <p>If your law firm uses a unique writing style and preferences, Trinka can customize an automated style guide for your specific needs, ensuring that all documents reflect your firm’s unique identity and approach to legal writing.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 dflex">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
                            <path d="M20.4999 38.7757C10.6166 38.7757 2.58325 30.7424 2.58325 20.859C2.58325 10.9757 10.6166 2.94238 20.4999 2.94238C30.3833 2.94238 38.4166 10.9757 38.4166 20.859C38.4166 30.7424 30.3833 38.7757 20.4999 38.7757ZM20.4999 5.44238C11.9999 5.44238 5.08325 12.359 5.08325 20.859C5.08325 29.359 11.9999 36.2757 20.4999 36.2757C28.9999 36.2757 35.9166 29.359 35.9166 20.859C35.9166 12.359 28.9999 5.44238 20.4999 5.44238Z" fill="#763393"/>
                            <path d="M26.6832 27.4091C26.4666 27.4091 26.2499 27.3591 26.0499 27.2257L20.8832 24.1424C19.5999 23.3757 18.6499 21.6924 18.6499 20.2091V13.3757C18.6499 12.6924 19.2166 12.1257 19.8999 12.1257C20.5832 12.1257 21.1499 12.6924 21.1499 13.3757V20.2091C21.1499 20.8091 21.6499 21.6924 22.1666 21.9924L27.3332 25.0757C27.9332 25.4257 28.1166 26.1924 27.7666 26.7924C27.5166 27.1924 27.0999 27.4091 26.6832 27.4091Z" fill="#763393"/>
                        </svg>
                        <h3>Accuracy and Compliance</h3>
                        <p>Trinka will help you in maintaining accuracy and compliance with legal writing standards, which is critical for the credibility and validity of legal documents.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 dflex">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
                            <path d="M20.1501 20.6592C19.2501 20.6592 18.3334 20.4925 17.6167 20.1759L7.78341 15.8092C5.28341 14.6925 4.91675 13.1925 4.91675 12.3759C4.91675 11.5592 5.28341 10.0592 7.78341 8.94255L17.6167 4.57588C19.0667 3.92588 21.2501 3.92588 22.7001 4.57588L32.5501 8.94255C35.0334 10.0425 35.4167 11.5592 35.4167 12.3759C35.4167 13.1925 35.0501 14.6925 32.5501 15.8092L22.7001 20.1759C21.9667 20.5092 21.0667 20.6592 20.1501 20.6592ZM20.1501 6.59255C19.5834 6.59255 19.0334 6.67588 18.6334 6.85921L8.80008 11.2259C7.78341 11.6925 7.41675 12.1592 7.41675 12.3759C7.41675 12.5925 7.78341 13.0759 8.78341 13.5259L18.6167 17.8925C19.4167 18.2425 20.8667 18.2425 21.6667 17.8925L31.5167 13.5259C32.5334 13.0759 32.9001 12.5925 32.9001 12.3759C32.9001 12.1592 32.5334 11.6759 31.5167 11.2259L21.6834 6.85921C21.2834 6.69255 20.7167 6.59255 20.1501 6.59255Z" fill="#763393"/>
                            <path d="M20.5 29.3424C19.8667 29.3424 19.2333 29.2091 18.6333 28.9424L7.31667 23.909C5.6 23.159 4.25 21.0757 4.25 19.1924C4.25 18.509 4.81667 17.9424 5.5 17.9424C6.18333 17.9424 6.75 18.509 6.75 19.1924C6.75 20.109 7.5 21.2591 8.33333 21.6424L19.65 26.6757C20.1833 26.909 20.8 26.909 21.35 26.6757L32.6667 21.6424C33.5 21.2757 34.25 20.109 34.25 19.1924C34.25 18.509 34.8167 17.9424 35.5 17.9424C36.1833 17.9424 36.75 18.509 36.75 19.1924C36.75 21.0757 35.4 23.159 33.6833 23.9257L22.3667 28.9591C21.7667 29.2091 21.1333 29.3424 20.5 29.3424Z" fill="#763393"/>
                            <path d="M20.5 37.6757C19.8667 37.6757 19.2333 37.5424 18.6333 37.2757L7.31667 32.2424C5.45 31.409 4.25 29.559 4.25 27.509C4.25 26.8257 4.81667 26.259 5.5 26.259C6.18333 26.259 6.75 26.8424 6.75 27.5257C6.75 28.5757 7.36667 29.5424 8.33333 29.9757L19.65 35.009C20.1833 35.2424 20.8 35.2424 21.35 35.009L32.6667 29.9757C33.6333 29.5424 34.25 28.5924 34.25 27.5257C34.25 26.8424 34.8167 26.2757 35.5 26.2757C36.1833 26.2757 36.75 26.8424 36.75 27.5257C36.75 29.5757 35.55 31.4257 33.6833 32.259L22.3667 37.2924C21.7667 37.5424 21.1333 37.6757 20.5 37.6757Z" fill="#763393"/>
                        </svg>
                        <h3>Efficiency and<br/> Time-Saving</h3>
                        <p>Trinka helps you in reducing the need for manual checking and editing of each document for style compliance, allowing teams to focus more on substantive legal work.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 dflex">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
                            <path d="M37.1667 11.6924H27.1667" stroke="#763393" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.4999 11.6924H3.83325" stroke="#763393" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.1666 17.5258C20.3882 17.5258 22.9999 14.9141 22.9999 11.6925C22.9999 8.4708 20.3882 5.85913 17.1666 5.85913C13.9449 5.85913 11.3333 8.4708 11.3333 11.6925C11.3333 14.9141 13.9449 17.5258 17.1666 17.5258Z" stroke="#763393" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M37.1667 30.0259H30.5" stroke="#763393" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.8333 30.0259H3.83325" stroke="#763393" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M23.8333 35.859C27.055 35.859 29.6667 33.2474 29.6667 30.0257C29.6667 26.8041 27.055 24.1924 23.8333 24.1924C20.6117 24.1924 18 26.8041 18 30.0257C18 33.2474 20.6117 35.859 23.8333 35.859Z" stroke="#763393" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <h3>Brand<br class="d-md-block d-none"/>Consistency</h3>
                        <p>With Trinka you can ensure that all documents produced by your law firm maintain a consistent style, tone, and format.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sampleReport section-pd pt-0">
        <span class="shape"></span>
        <span class="shape shape2"></span>
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title mb-30 text-center">
                        <h2>View Sample</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="viewSampleReportImg">
                        <img src="../../../../assets/images/legal-writing/view-sample.png" loading="lazy" class="img-fluid" alt="grammar checker for legal writing report">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="otherApps section-pd-sm">
        <div class="trinka_container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Use Trinka in Other Apps</h3>
                    </div>
                    <ul>
                        <li>
                            <a [routerLink]="['/trinkacloud']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_apps_TrinkaCloud.svg" alt="trinka cloud">
                                <h3>Cloud</h3>
                            </a>
                        </li>
                        <li>
                            <a  [routerLink]="['/windows-desktop-app']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/native-app/windows-icon.svg" alt="windows">
                                <h3>Windows App</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/wordplugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Others_App_MSWord.svg" alt="ms word">
                                <h3>MS Word Add-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/browser-plugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/other_Apps_browser.svg" alt="browser plug-in">
                                <h3>Browser Plug-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/enterprise']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_Apps_enterprise.svg" alt="enterprise">
                                <h3>Enterprise <small>(API, JS, & On-Premise)</small></h3>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<app-enterprise-modal></app-enterprise-modal>