<section class="mainTopBanner section-pd">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="topBannerWrapper">
                    <div class="top-banner-left">
                        <h1 class="trinka-sub-title">Offline Grammar Checker</h1>
                        <h2>Ensure Full Data Protection With Offline Grammar Checker</h2>
                        <p>Get grammar corrections processed locally on your Laptop/PC without any internet.</p>
                        <div class="btn-group align-items-end">
                            <button data-toggle="modal" data-target="#EnterPriseContactPopup" class="filled-btn nowrap" type="button">Contact Us</button>
                        </div>
                    </div>
                    <div class="top-banner-right">
                        <img src="/assets/images/native-app/windows-native-app.svg" width="489" height="563" alt="native app" priority />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd list-wrapper-style-2 gcWithoutInternet section-purple">
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title mb-0 text-center">
                    <h2>Trinka's Windows Desktop App With <br class="d-lg-block d-none"/>Offline Grammar Correction</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>Trinka’s Windows Desktop App for enterprises provides grammar correction without internet, ensuring absolute data privacy, but with the power of AI.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-none">
                    <div class="features_cards_icon">
                        <svg width="40" height="40" viewBox="0 0 24 24" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#763393"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <style type="text/css"> .st0{display:none;} .st1{display:inline;} .st2{opacity:0.2;fill:none;stroke:#763393;stroke-width:5.000000e-02;stroke-miterlimit:10;} </style> <g class="st0" id="grid_system"></g> <g id="_icons"> <path d="M2,14.5c0,1.6,0.9,3.1,2.2,3.9l-0.9,0.9c-0.4,0.4-0.4,1,0,1.4C3.5,20.9,3.7,21,4,21s0.5-0.1,0.7-0.3l14-14 c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-1.8,1.8C14.9,7,14.3,7,13.8,7c-0.1-0.2-0.3-0.3-0.4-0.4C12.4,5.6,11,5,9.5,5S6.6,5.6,5.6,6.6 C4.6,7.6,4,9,4,10.5c0,0.1,0,0.2,0,0.3c-0.2,0.2-0.5,0.3-0.7,0.6C2.5,12.2,2,13.3,2,14.5z M4.7,12.7c0.2-0.2,0.5-0.4,0.7-0.5 c0.4-0.2,0.7-0.6,0.6-1.1C6,10.9,6,10.7,6,10.5C6,9.6,6.4,8.7,7,8c1.3-1.3,3.6-1.3,4.9,0c0.2,0.2,0.4,0.4,0.5,0.7 c0.2,0.3,0.6,0.5,1,0.4l-7.7,7.7c-1-0.3-1.7-1.3-1.7-2.4C4,13.8,4.3,13.2,4.7,12.7z"></path> <path d="M19.6,10.6c-0.2-0.4-0.4-0.8-0.6-1.2c-0.3-0.5-0.9-0.6-1.4-0.3c-0.5,0.3-0.6,0.9-0.3,1.4c0.2,0.3,0.4,0.7,0.5,1 c0.1,0.3,0.3,0.5,0.6,0.7c0.9,0.4,1.5,1.3,1.5,2.3c0,0.7-0.3,1.3-0.7,1.8c-0.5,0.5-1.1,0.7-1.8,0.7H10c-0.6,0-1,0.4-1,1s0.4,1,1,1 h7.5c1.2,0,2.3-0.5,3.2-1.3c0.9-0.8,1.3-2,1.3-3.2C22,12.8,21.1,11.3,19.6,10.6z"></path> </g> </g></svg>
                    </div>
                    <div class="features_cards_desc">
                        <h3>Offline Processing </h3>
                        <p>Installs and runs locally on your Laptop/PC without requiring any internet connection.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <div class="features_cards_icon">
                        <svg width="40px" height="40px" viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg" fill="#763393"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g fill="none" stroke="#763393" stroke-width="12"> <path stroke-linecap="round" d="M151.8 144.5a74 74 0 0 1-85.59 19.21A74 74 0 0 1 22.42 87.7a74 74 0 0 1 59.55-64.42m28.03.06a74 74 0 0 1 50.06 35.61 74 74 0 0 1 5.915 61.15"></path> <path d="M76 92h40c4.432 0 8 3.568 8 8v22c0 4.432-3.568 8-8 8H76c-4.432 0-8-3.568-8-8v-22c0-4.432 3.568-8 8-8zm4 0V77.7C80 69.029 87.163 62 96 62s16 7.029 16 15.7V92"></path> </g> </g></svg>
                    </div>
                    <div class="features_cards_desc">
                        <h3>Complete Data Privacy</h3>
                        <p>As it works on your Laptop/PC, your data is never shared or uploaded on any server, ensuring full privacy.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <div class="features_cards_icon">
                        <svg width="40" height="40" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.5762 13.3506C19.5762 15.7938 21.5568 17.7745 24 17.7745C26.4432 17.7745 28.4239 15.7938 28.4239 13.3506C28.4239 10.9074 26.4432 8.92676 24 8.92676C21.5568 8.92676 19.5762 10.9074 19.5762 13.3506ZM35.8012 8.92676C33.358 8.92676 31.3773 10.9074 31.3773 13.3506C31.3773 15.7938 33.358 17.7745 35.8012 17.7745C38.2444 17.7745 40.225 15.7938 40.225 13.3506C40.225 10.9074 38.2444 8.92676 35.8012 8.92676ZM7.77502 13.3506C7.77502 15.7938 9.75565 17.7745 12.1989 17.7745C14.6421 17.7745 16.6227 15.7938 16.6227 13.3506C16.6227 10.9074 14.6421 8.92676 12.1989 8.92676C9.75565 8.92676 7.77502 10.9074 7.77502 13.3506ZM19.5762 25.1518C19.5762 27.595 21.5568 29.5756 24 29.5756C26.4432 29.5756 28.4239 27.595 28.4239 25.1518C28.4239 22.7085 26.4432 20.7279 24 20.7279C21.5568 20.7279 19.5762 22.7085 19.5762 25.1518ZM31.3773 25.1518C31.3773 27.595 33.358 29.5756 35.8012 29.5756C38.2444 29.5756 40.225 27.595 40.225 25.1518C40.225 22.7085 38.2444 20.7279 35.8012 20.7279C33.358 20.7279 31.3773 22.7085 31.3773 25.1518ZM7.77502 25.1518C7.77502 27.595 9.75565 29.5756 12.1989 29.5756C14.6421 29.5756 16.6227 27.595 16.6227 25.1518C16.6227 22.7085 14.6421 20.7279 12.1989 20.7279C9.75565 20.7279 7.77502 22.7085 7.77502 25.1518ZM19.5762 36.9529C19.5762 39.3961 21.5568 41.3768 24 41.3768C26.4432 41.3768 28.4239 39.3961 28.4239 36.9529C28.4239 34.5097 26.4432 32.5291 24 32.5291C21.5568 32.5291 19.5762 34.5097 19.5762 36.9529ZM31.3773 36.9529C31.3773 39.3961 33.358 41.3768 35.8012 41.3768C38.2444 41.3768 40.225 39.3961 40.225 36.9529C40.225 34.5097 38.2444 32.5291 35.8012 32.5291C33.358 32.5291 31.3773 34.5097 31.3773 36.9529ZM7.77502 36.9529C7.77502 39.3961 9.75565 41.3768 12.1989 41.3768C14.6421 41.3768 16.6227 39.3961 16.6227 36.9529C16.6227 34.5097 14.6421 32.5291 12.1989 32.5291C9.75565 32.5291 7.77502 34.5097 7.77502 36.9529Z" fill="#763393"/>
                        </svg>
                    </div>
                    <div class="features_cards_desc">
                        <h3>Data Sovereignty</h3>
                        <p>This solution does not transfer data out of your Laptop/PC, thus meeting all data sovereignty requirements with ease.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1">
                    <div class="features_cards_icon">
                        <svg width="40" height="40" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M60 28.8C60 16.8 55.2 12 43.2 12H28.8C16.8 12 12 16.8 12 28.8V43.2C12 55.2 16.8 60 28.8 60" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M49.05 24C47.4 21.9 44.64 21 40.5 21H31.5C24 21 21 24 21 31.5V40.5C21 44.64 21.9 47.4 23.97 49.05" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M24.03 12V6" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M36 12V6" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M48 12V6" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M60 24H66" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M24.03 60V66" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6 24H12" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6 36H12" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6 48H12" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M66 40L40 66" stroke="#7A28A0" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M66 66L40 40" stroke="#7A28A0" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="features_cards_desc">
                        <h3>No AI <br class="d-lg-block d-none"/>Training</h3>
                        <p>As your data is never shared or stored on any server, it cannot be used for training AI models.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1">
                    <div class="features_cards_icon">
                        <svg width="40" height="40" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M31.47 6.68999L16.5 12.33C13.05 13.62 10.23 17.7 10.23 21.36V43.65C10.23 47.19 12.57 51.84 15.42 53.97L28.32 63.6C32.55 66.78 39.51 66.78 43.74 63.6L56.64 53.97C59.49 51.84 61.83 47.19 61.83 43.65V21.36C61.83 17.67 59.01 13.59 55.56 12.3L40.59 6.68999C38.04 5.75999 33.96 5.75999 31.47 6.68999Z" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M23.993 2.67432L9.19709 8.127C5.78721 9.37415 3 13.3186 3 16.8571V38.4068" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M27.15 35.61L31.98 40.44L44.88 27.54" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="features_cards_desc">
                        <h3>Faster & Powerful </h3>
                        <p>It is fast & powerful, while delivering similar level of advanced grammar and technical spelling corrections that Trinka is known for.</p>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1">
                    <div class="features_cards_icon">
                        <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M22 19H14M2 19H10" stroke="#763393" stroke-width="1.5" stroke-linecap="round"></path> <path d="M12 17V14" stroke="#763393" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="12" cy="19" r="2" stroke="#763393" stroke-width="1.5"></circle> <path d="M2 11C2 9.34315 3.34315 8 5 8H19C20.6569 8 22 9.34315 22 11C22 12.6569 20.6569 14 19 14H5C3.34315 14 2 12.6569 2 11Z" stroke="#763393" stroke-width="1.5"></path> <path d="M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5C22 6.65685 20.6569 8 19 8H5C3.34315 8 2 6.65685 2 5Z" stroke="#763393" stroke-width="1.5"></path> <path d="M13 5L19 5" stroke="#763393" stroke-width="1.5" stroke-linecap="round"></path> <path d="M13 11L19 11" stroke="#763393" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="6" cy="5" r="1" fill="#763393"></circle> <circle cx="6" cy="11" r="1" fill="#763393"></circle> </g></svg>
                    </div>
                    <div class="features_cards_desc">
                        <h3>No Servers Needed</h3>
                        <p>This solution does not require any server or IT setup at your end. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd list-wrapper-style-2 pb-0">
    <div class="trinka_container container">
        <div class="row align-items-center flex-row-reverse">
            <div class="col-md-6">
                <div class="list-wrapper-content-2">
                    <div class="trinka-title mb-0 text-left">
                        <h2>Compatible With</h2>
                        <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                    </div>
                    <ul class="steps-pts">
                        <li>Windows 10 and above.</li>
                        <li>MS Word and Outlook 2011 onwards. </li>
                        <li>Support for other Windows Apps is coming soon.</li>
                    </ul>
                    <div class="btn-group align-items-end mt-4">
                        <button data-toggle="modal" data-target="#EnterPriseContactPopup" class="filled-btn nowrap" type="button">Contact Us</button>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image">
                    <img src="/assets/images/native-app/compliance-desktop-app.svg"  width="456" height="424" alt="compare" class="img-fluid" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd list-wrapper-style-2">
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title mb-0 text-center">
                    <h2>Key Features of Trinka Desktop App for Windows</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p class="w-70">Trinka for Windows is available as a desktop application that provides professional grammar corrections and suggestions, as you write, in various applications or programs.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-none">
                    <img src="../../../assets/images/grammar-checker-icon.svg" alt="Grammar Checker" />
                    <h3>English Grammar Checker</h3>
                    <p>Get real-time writing suggestions and advanced grammar checks for your content within Windows applications like MS Word.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <img src="../../../assets/images/PublicationReadiness.svg" alt="Paraphraser">
                    <h3>Technical <br class="d-lg-block d-none"/>Spell <br class="d-lg-block d-none"/>Check</h3>
                    <p>Reduce spelling errors and improve writing with contextual technical spell checks.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 dflex">
                <div class="list-wrapper-content-2 list-style-cards">
                    <img src="../../../assets/images/error-highlight-icon.svg" alt="Interactive Error Highlighting">
                    <h3>Interactive <br class="d-lg-block d-none"/>Error <br class="d-lg-block d-none"/>Highlighting</h3>
                    <p>Errors in your document are highlighted directly on the page, with suggestion cards displayed on the mouse hover and in a dedicated panel.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 dflex">
                <div class="list-wrapper-content-2 list-style-cards bt-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 25" fill="none">
                        <path d="M15 14.25H7C6.59 14.25 6.25 13.91 6.25 13.5C6.25 13.09 6.59 12.75 7 12.75H15C15.41 12.75 15.75 13.09 15.75 13.5C15.75 13.91 15.41 14.25 15 14.25Z" fill="#7A28A0"/>
                        <path d="M16.029 10.5803H7.0882C6.62998 10.5803 6.25 10.2403 6.25 9.83032C6.25 9.42032 6.62998 9.08032 7.0882 9.08032H16.029C16.4872 9.08032 16.8672 9.42032 16.8672 9.83032C16.8672 10.2403 16.4872 10.5803 16.029 10.5803Z" fill="#7A28A0"/>
                        <path d="M12 18.25H7C6.59 18.25 6.25 17.91 6.25 17.5C6.25 17.09 6.59 16.75 7 16.75H12C12.41 16.75 12.75 17.09 12.75 17.5C12.75 17.91 12.41 18.25 12 18.25Z" fill="#7A28A0"/>
                        <path d="M15 23.25H9C3.38 23.25 2.25 20.6 2.25 16.32V10.15C2.25 5.41 3.85 3.48 7.96 3.25H16C16.01 3.25 16.03 3.25 16.04 3.25C20.15 3.48 21.75 5.41 21.75 10.15V16.32C21.75 20.6 20.62 23.25 15 23.25ZM8 4.75C5.2 4.91 3.75 5.79 3.75 10.15V16.32C3.75 20.15 4.48 21.75 9 21.75H15C19.52 21.75 20.25 20.15 20.25 16.32V10.15C20.25 5.8 18.81 4.91 15.98 4.75H8Z" fill="#7A28A0"/>
                    </svg>
                    <h3>Writing <br class="d-lg-block d-none"/>Advisor</h3>
                    <p>Enhance the writing style, coherence, and overall presentation of your writing to convey your intent accurately.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 dflex">
                <div class="list-wrapper-content-2 list-style-cards bt-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 25" fill="none">
                        <path d="M12 5H3" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 10H3" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M21 15H3" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M21 20H3" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <h3>Sentence Structure, Usage & Style</h3>
                    <p>Structure sentences correctly for easy understanding. Improve the impact of writing with better phrasing and formal tone.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 dflex">
                <div class="list-wrapper-content-2 list-style-cards bt-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 25" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.928 1.27032C19.3169 1.47798 17.1419 2.21843 15.0947 2.91565L11.3725 4.18343L7.23548 2.71954L3.09837 1.25554L3.06881 4.09998L3.03915 6.94443L1.76137 7.38887L0.483592 7.83332L0.455147 16.031C0.428592 23.6418 0.499864 24.2778 0.499864 24.2778C0.499864 24.2778 3.11648 23.6045 6.4207 22.5075L12.0391 20.6423L17.3169 22.3242C20.2197 23.2493 22.8071 24.0768 23.0669 24.163L23.5391 24.3199V16.2308V8.14176L22.4836 7.82465L21.428 7.50754V4.33154C21.428 1.28265 21.428 0.722229 21.428 0.722229C21.428 0.722229 20.5391 1.06265 19.928 1.27032ZM20.8888 4.44445C20.8888 6.59167 20.8888 7.33334 20.8888 7.33334C20.8888 7.33334 12.4043 4.65354 12.295 4.56376C12.2106 4.4942 20.8888 1.50001 20.8888 1.50001C20.8888 1.50001 20.8888 1.8889 20.8888 4.44445ZM11.5391 12.4908V20.2036L6.3447 21.9352C3.4877 22.8875 1.05542 23.6667 1.05542 23.6667C1.05542 23.6667 0.983592 20.1958 0.983592 15.9536V8.24076L6.12248 6.51798C8.94893 5.57054 11.3239 4.79132 11.4003 4.78654C11.5101 4.77954 11.5391 6.38976 11.5391 12.4908Z" fill="#7A28A0"/>
                        <path d="M5.60512 11.9638C5.8266 11.9016 6.04846 11.839 6.27068 11.7761C6.28327 12.9168 6.29586 14.0583 6.30846 15.2005C6.3129 15.565 6.24401 15.9072 6.09957 16.2261C5.95623 16.5427 5.7529 16.8161 5.48846 17.045C5.22512 17.2727 4.91401 17.4316 4.55734 17.5238C4.20179 17.615 3.88957 17.615 3.6229 17.525C3.35734 17.4338 3.15068 17.2694 3.00401 17.0316C2.85734 16.7927 2.78401 16.4938 2.7829 16.135C2.78142 15.0098 2.77957 13.885 2.77734 12.7605C2.99883 12.6983 3.22031 12.6361 3.44179 12.5738C3.44623 13.6812 3.44994 14.7879 3.4529 15.8938C3.4529 16.1261 3.49734 16.3227 3.58623 16.4794C3.67734 16.6372 3.80401 16.7461 3.96845 16.8083C4.1329 16.8672 4.32734 16.8694 4.55179 16.8105C4.77846 16.7527 4.9729 16.6494 5.13512 16.505C5.29957 16.3561 5.42401 16.1805 5.50957 15.975C5.59623 15.7705 5.63845 15.5494 5.63623 15.315C5.62586 14.1979 5.61549 13.0809 5.60512 11.9638Z" fill="#7A28A0"/>
                        <path d="M6.87845 16.8416C6.85623 15.1024 6.83401 13.3631 6.81179 11.6238C7.03253 11.5616 7.25327 11.499 7.47401 11.4361C7.48586 12.2694 7.49808 13.1024 7.51068 13.935C7.52846 13.9298 7.5466 13.925 7.56512 13.9205C8.16883 12.9175 8.77105 11.9112 9.37179 10.9016C9.65031 10.8231 9.92882 10.745 10.2073 10.6672C9.63105 11.6242 9.05327 12.5787 8.47401 13.5305C9.09031 14.3364 9.70846 15.145 10.3285 15.9561C10.0588 16.0257 9.78883 16.0953 9.51846 16.165C9.02512 15.5057 8.53253 14.8479 8.04068 14.1916C7.86957 14.4753 7.69845 14.7587 7.52734 15.0416C7.53475 15.5846 7.54253 16.1272 7.55068 16.6694C7.32623 16.7272 7.10216 16.7846 6.87845 16.8416Z" fill="#7A28A0"/>
                        <path d="M16.4898 11.4536C16.7205 11.515 16.9511 11.5768 17.1817 11.639C17.1878 12.7796 17.194 13.9206 17.2001 15.0619C17.2011 15.4253 17.1285 15.729 16.978 15.9708C16.8286 16.2106 16.6168 16.3761 16.3445 16.4651C16.0713 16.5541 15.7509 16.5509 15.3825 16.4556C15.0161 16.3604 14.6948 16.1981 14.4205 15.9666C14.1472 15.7342 13.9344 15.4578 13.7829 15.1373C13.6325 14.8159 13.5567 14.4714 13.5567 14.1059C13.5561 12.9597 13.5554 11.8135 13.5547 10.6672C13.7839 10.7287 14.0135 10.7901 14.2434 10.8515C14.2448 11.9761 14.2462 13.1 14.2475 14.2232C14.2486 14.4599 14.2936 14.6818 14.3847 14.8892C14.4788 15.0965 14.6088 15.2756 14.7787 15.4253C14.9476 15.5729 15.1481 15.6776 15.3805 15.7373C15.6138 15.7981 15.8144 15.7981 15.9822 15.7384C16.1531 15.6776 16.2821 15.5677 16.3711 15.4075C16.4622 15.2494 16.5062 15.0515 16.5052 14.8159C16.5004 13.6955 16.4953 12.5747 16.4898 11.4536Z" fill="white"/>
                        <path d="M20.2468 13.818C20.2212 13.5719 20.1209 13.3614 19.947 13.1834C19.773 13.0033 19.555 12.8798 19.293 12.8096C19.1057 12.7604 18.945 12.7499 18.8079 12.7813C18.6718 12.8107 18.5653 12.8735 18.4906 12.9698C18.4169 13.0651 18.3811 13.1866 18.3821 13.3342C18.3832 13.4567 18.4088 13.5698 18.4599 13.6724C18.5131 13.7761 18.5807 13.8682 18.6646 13.952C18.7506 14.0337 18.8417 14.107 18.9389 14.173C19.0361 14.2379 19.1292 14.2944 19.2183 14.3436C19.3684 14.4267 19.5181 14.5101 19.6676 14.5939C19.8139 14.6724 19.9633 14.7667 20.1179 14.8766C20.2724 14.9866 20.4157 15.1154 20.5477 15.263C20.6807 15.4106 20.7882 15.5782 20.8701 15.7667C20.954 15.9551 20.997 16.1646 20.999 16.3981C21.0021 16.6912 20.9386 16.9342 20.8056 17.1268C20.6746 17.3195 20.4832 17.4483 20.2294 17.5143C19.9787 17.5813 19.6727 17.5677 19.3135 17.4745C18.9686 17.3855 18.6708 17.2462 18.419 17.0567C18.1662 16.8682 17.9687 16.6431 17.8254 16.3782C17.6821 16.1132 17.6013 15.8211 17.5849 15.5049C17.8162 15.5656 18.0478 15.626 18.2798 15.686C18.2952 15.8776 18.3494 16.0483 18.4446 16.197C18.5408 16.3457 18.6636 16.4703 18.812 16.5687C18.9614 16.6672 19.1251 16.7394 19.3042 16.7865C19.4997 16.8368 19.6727 16.8473 19.8252 16.8179C19.9777 16.7865 20.0984 16.7206 20.1854 16.619C20.2724 16.5153 20.3154 16.3803 20.3144 16.2117C20.3123 16.0577 20.2724 15.9216 20.1936 15.8033C20.1168 15.686 20.0125 15.5771 19.8804 15.4787C19.7505 15.3813 19.6021 15.285 19.4383 15.1928C19.2568 15.0895 19.076 14.9866 18.8959 14.8839C18.5275 14.6735 18.2358 14.4295 18.0188 14.1531C17.8039 13.8777 17.6965 13.5573 17.6944 13.197C17.6924 12.8965 17.762 12.6536 17.9042 12.4683C18.0454 12.284 18.2368 12.1615 18.4804 12.1039C18.7229 12.0431 18.9972 12.0547 19.3032 12.1363C19.6133 12.2191 19.8866 12.3541 20.124 12.5416C20.3635 12.7279 20.5518 12.9478 20.69 13.1991C20.8302 13.4494 20.9038 13.7154 20.912 13.9939C20.6903 13.9353 20.4686 13.8766 20.2468 13.818Z" fill="white"/>
                    </svg>
                    <h3>US/UK <br class="d-lg-block d-none"/>Style</h3>
                    <p>Communicate effectively in your audience’s style by choosing US/UK English.
                    </p>
                </div>
            </div>
            
        </div>
    </div>
</section>

<section class="trinka-banner section-pd-sm">
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title text-center mb-40">
                    <h2 class="mb-4 text-white">
                        Your Data Remains on Your Laptop/PC Only. <br class="d-md-block d-none"/>Nobody Can See It or Access It
                    </h2>
                </div>
                <div class="btn-group justify-content-center">
                    <button type="button" data-toggle="modal" data-target="#EnterPriseContactPopup" class="white-btn" target="_blank">Contact Us</button>
                </div>
            </div>
        </div>  
    </div>
</section>

<section class="faqQuestionAnwser section-pd" #target>
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="trinka-title text-center">
                    <h2>Frequently Asked Questions</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="faqQuestionsList">
            <div class="accordion" id="faq">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionOne">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                        Does Trinka's offline grammar checker work the same as the online version?
                                    </a>
                                </div>

                                <div id="QuestionOneContent" class="collapse" aria-labelledby="QuestionOne" data-parent="#faq">
                                    <div class="card-body">
                                        <p>
                                            In most cases, yes. The offline checker provides the same advanced grammar and writing corrections as the online version while ensuring complete data privacy. However, it does not include additional features like paraphrasing or consistency checks and may not always have the latest models or dictionary updates.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="QuestionTwo">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true" aria-controls="QuestionTwoContent">
                                        Can I use Trinka’s offline grammar checker in MS Word and other dapplications?
                                    </a>
                                </div>

                                <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                    <div class="card-body">
                                        <p>
                                            Yes, Trinka’s Windows Desktop App integrates with MS Word as well as many other windows desktop apps to provide real-time grammar corrections within the application.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionThree">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionThreeContent" aria-expanded="true" aria-controls="QuestionThreeContent">
                                        Does Trinka have any offline app for checking grammatical mistakes?
                                    </a>
                                </div>

                                <div id="QuestionThreeContent" class="collapse" aria-labelledby="QuestionThree" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Yes, Trinka offers a Windows Desktop App for enterpriese comes with an offline grammar checker, allowing you to correct grammatical mistakes locally on your laptop/PC without the need of an internet connection.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionFour">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFourContent" aria-expanded="true" aria-controls="QuestionFourContent">
                                        Is my data safe while using Trinka’s offline grammar checker?
                                    </a>
                                </div>

                                <div id="QuestionFourContent" class="collapse" aria-labelledby="QuestionFour" data-parent="#faq">
                                    <div class="card-body">
                                        <p>
                                            Absolutely! Since Trinka’s offline grammar checker works without an internet connection, your data is stored locally, and not shared or accessed by anyone else. This ensures that your data remains completely private and secure.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-enterprise-modal></app-enterprise-modal>