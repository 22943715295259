
<div class="trinka_cloud_wrap" #target>
    <div class="cloud_top_banners">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="trinka_cloud_banner_text">
                        <h1>Grammar Checker API</h1>
                        <h2>Simple, Fast AI Grammar Check API</h2>
                        <p>Trinka's AI powered grammar check delivered via a simple RESTful API to enable powerful real-time or passive grammar checks right within your application</p>
                        
                    </div>
                </div>
                <div class="col-lg-6">
                    <div id="OrganizationType">
                        <div class="contact_us_form">
                            <form [formGroup]="contactForm" (ngSubmit)="sendContactUsForm()" class="DataSensitiveForm">
                                <div class="row">
                                    <div class="col-md-6 rightPaddingRemove">
                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('NatureBusinessType').valid && contactForm.get('NatureBusinessType').dirty) || (contactForm.get('NatureBusinessType').touched && contactForm.get('NatureBusinessType').pristine) ? 'TextFiledError' : '' ">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Nature of business*</mat-label>
                                                <input matInput placeholder="" formControlName="NatureBusinessType" class="form-control">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-6 leftPaddingRemove">
                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormSubjects').valid && contactForm.get('contactFormSubjects').dirty) || (contactForm.get('contactFormSubjects').touched && contactForm.get('contactFormSubjects').pristine) ? 'TextFiledError' : '' ">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Organization name*</mat-label>
                                                <input matInput placeholder="" formControlName="contactFormSubjects" class="form-control">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormWebsite').valid && contactForm.get('contactFormWebsite').dirty) || (contactForm.get('contactFormWebsite').touched && contactForm.get('contactFormWebsite').pristine) ? 'TextFiledError' : '' ">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Website*</mat-label>
                                                <input matInput type="URL" placeholder="" formControlName="contactFormWebsite" class="form-control">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="textbox_content form-group textareaBox" [ngClass]="(!contactForm.get('contactFormMessage').valid && contactForm.get('contactFormMessage').dirty) || (contactForm.get('contactFormMessage').touched && contactForm.get('contactFormMessage').pristine) ? 'TextFiledError' : '' ">
                                            <label for="">Tell us a bit about your requirement</label>
                                            <textarea formControlName="contactFormMessage" class="form-control" placeholder="E.g. approximare number of users, monthly word count volume per user, customizations needed, etc."></textarea>
                                        </div>
                                    </div>

                                    <div class="col-md-6 rightPaddingRemove">
                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormName').valid && contactForm.get('contactFormName').dirty) || (contactForm.get('contactFormName').touched && contactForm.get('contactFormName').pristine) ? 'TextFiledError' : '' ">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Name*</mat-label>
                                                <input matInput placeholder="" formControlName="contactFormName" class="form-control">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-6 leftPaddingRemove">
                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('DesignationTypes').valid && contactForm.get('DesignationTypes').dirty) || (contactForm.get('DesignationTypes').touched && contactForm.get('DesignationTypes').pristine) ? 'TextFiledError' : '' ">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Designation*</mat-label>
                                                <input matInput placeholder="" formControlName="DesignationTypes" class="form-control">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-6 rightPaddingRemove">
                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormEmail').valid && contactForm.get('contactFormEmail').dirty) || (contactForm.get('contactFormEmail').touched && contactForm.get('contactFormEmail').pristine) ? 'TextFiledError' : '' ">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Email*</mat-label>
                                                <input matInput placeholder="" formControlName="contactFormEmail" class="form-control">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-6 leftPaddingRemove">
                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactphoneNo').valid && contactForm.get('contactphoneNo').dirty) || (contactForm.get('contactphoneNo').touched && contactForm.get('contactphoneNo').pristine) ? 'TextFiledError' : '' ">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Contact Number* (incl. ISD code)</mat-label>
                                                <input type="number" matInput placeholder="" formControlName="contactphoneNo" class="form-control">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group text-center">
                                    <button type="submit">Submit</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="most_suited_for_section" style="margin-top: 70px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="most_united_heading">
                    <h2>
                        <span></span>Unique Integrations for various Industries</h2>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="most_suited_point">
                    <ul>
                        <li>Life Sciences, Medicine & Pharma</li>
                        <li>Language Services, Publishing & Media</li>
                        <li>Academic Institutions</li>
                        <li>K-12 & E-Learning</li>
                        <li>Technology Platforms</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="cloud_key_features cloudKeyBenefitSection mb-0 bg-white">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="key_features_heading mb-0">
                    <h2>Text or Files - Trinka APIs Let You Choose</h2>
                    <div class="HeadingSubLine"></div>
                    <p class="mb-0">Build seamless integrations with Trinka grammar check APIs that meet your workflow and technical requirements.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="writeToolbox_inner_sections">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="wrapper_left">
                        <h3>Real-time Check API</h3>
                        <p>Trinka's real-time checks power applications that need instant writing corrections on learning platforms, editors, chat applications, and the like.</p>
                        <ul>
                            <li>Flexibility to pass on lines, paragraphs, or larger text collections</li>
                            <li>Simple response payload for all input formats</li>
                            <li>Flexibility to pay by text volume or per API call </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="wrapper_right">
                        <img src="../../../assets/images/Real-time Check API.svg" alt="Real-time Check API" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="writeToolbox_inner_sections" style="padding-top: 0;">
        <div class="container">       
            <div class="row flex-row-reverse align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="wrapper_left">
                        <h3>File-edit API</h3>
                        <p>Trinka's file edit API lets you support unique workflow needs while avoiding complex integrations and a high volume of API calls.</p>
                        <ul>
                            <li>Get edited files with a single API call</li>
                            <li>Tracked changes for versioning and user control</li>
                            <li>Writing quality scores and revision summaries for assessments</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="wrapper_right">
                        <img src="../../../assets/images/file-edit-api.png" alt="File-edit API" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="cloud_key_features cloudKeyBenefitSection cs-section" style="padding-bottom: 40px !important;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="key_features_heading mb-3">
                    <h2>Access All of Trinka's Features with a Simple API</h2>
                    <div class="HeadingSubLine"></div>
                    
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-xl-5">
                <div class="cs-left-section">
                    <p>Trinka grammar check APIs offer comprehensive writing assistance ranging from advanced English grammar corrections to tone and style enhancements. Pick and choose the features that suit your needs!</p>
                    <p>Trinka's grammar and spelling check APIs refine the way you write to match academic writing conventions and best practices while letting you focus more on your ideas.</p>
                    <!-- <p>Trinka stands out by working with the author and going beyond grammar and spelling checks to ensure holistic language enhancement.</p> -->
                    <p>Trinka grammar checker APIs also provide additional features like Technical Phrasing, Academic Tone, Unbiased language, Word Choice, Usage and Style, and more.  </p>
                </div>
            </div>
            <div class="col-lg-7 col-xl-7">
                <div class="xs-features-api">
                    <span class="hline"></span>
                    <span class="vline"></span>
                    <span class="vline vline2"></span>
                    <div class="row match-box">
                        <div class="col-md-6 col-sm-6 col-12 dflex">
                            <div class="features_cards media">
                                <div class="icon media-left">
                                    <img src="../../../assets/images/icons/Advanced-Grammar.png" alt="Advanced Grammar" />
                                </div>
                                <div class="media-body">
                                    <div class="features_cardsHeading ">
                                        <h3>Advanced Grammar</h3>
                                    </div>
                                    <p>Best for academic & formal communication
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-12 dflex">
                            <div class="features_cards media">
                                <div class="icon media-left">
                                    <img src="../../../assets/images/icons/Icon_ExpertProofreading.png" alt="Subject-specific Corrections" />
                                </div>
                                <div class="media-body">
                                    <div class="features_cardsHeading">
                                        <h3>Subject-specific Corrections</h3>
                                    </div>
                                    <p>Automatic subject detection & precise suggestions
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-12 dflex">
                            <div class="features_cards media">
                                <div class="icon media-left">
                                    <img src="../../../assets/images/icons/Icon_title.png" alt="Contextual Spelling" />
                                </div>
                                <div class="media-body">
                                    <div class="features_cardsHeading">
                                        <h3>Contextual Spelling </h3>
                                    </div>
                                    <p>Picks correct terms based on your subject
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-12 dflex">
                            <div class="features_cards media">
                                <div class="icon media-left">
                                    <img src="../../../assets/images/icons/Icon_Pass_02.png" alt="Tips & Explanations" />
                                </div>
                                <div class="media-body">
                                    <div class="features_cardsHeading">
                                        <h3>Tips & Explanations</h3>
                                    </div>
                                    <p>Explainable AI suggestions for users
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-12 dflex">
                            <div class="features_cards media">
                                <div class="icon media-left">
                                    <img src="../../../assets/images/icons/Non-standard-2.png" alt="Style Guide Preference" />
                                </div>
                                <div class="media-body">
                                    <div class="features_cardsHeading">
                                        <h3>Style Guide Preference </h3>
                                    </div>
                                    <p>Suggests corrections based on style guides (AMA, APA, etc.)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-12 dflex">
                            <div class="features_cards media no-border-before">
                                <div class="icon media-left">
                                    <img src="../../../assets/images/icons/Icon_Dictionary.png" alt="Custom Dictionaries" />
                                </div>
                                <div class="media-body">
                                    <div class="features_cardsHeading">
                                        <h3>Custom Dictionaries</h3>
                                    </div>
                                    <p>Improve suggestions and stay on brand
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="use_trinka_other_app accomplishBrilliantWrap mt-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="accomplishBrilliantSection">
                    <h2>Grab a free trial of our Grammar Checker API</h2>
                    <a href="https://calendly.com/trinkaai/meet" target="_blank" rel="noopener noreferrer" class="plansButtons pointer">Book a Demo</a>
                </div>
            </div>
        </div>
            
    </div>
</div>

<div class="cloud_key_features cloudKeyBenefitSection mb-0 bg-light-purple">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="key_features_heading">
                    <h2>Unique Use Cases Powered by Trinka Grammar Check API </h2>
                    <div class="HeadingSubLine"></div>
                </div>
            </div>
        </div>
        <div class="row match-box">

            <div class="col-lg-4 col-md-6 dflex">
                <div class="features_cards">
                    <div class="features_cardsHeading">
                        <h3>E-Learning Applications</h3>
                    </div>
                    <p>The Trinka grammar check API has helped application developers polish their learners' writing to meet academic standards.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 dflex">
                <div class="features_cards">
                    <div class="features_cardsHeading">
                        <h3>Publishing & Editorial</h3>
                    </div>
                    <p>Trinka's grammar checking API helps editorial staff with assistive text correction, saving them time and cost to quality.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 dflex">
                <div class="features_cards">
                    <div class="features_cardsHeading">
                        <h3>Student Forums</h3>
                    </div>
                    <p>The robust checks by Trinka's grammar check API have students communicate effectively in forums & groups, improving student engagement.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 dflex">
                <div class="features_cards">
                    <div class="features_cardsHeading">
                        <h3>Businesses</h3>
                    </div>
                    <p>Trinka's grammar check API has empowered several teams to write better without sacrificing on clarity, consistency or brand perception.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 dflex">
                <div class="features_cards">
                    <div class="features_cardsHeading">
                        <h3>Productivity Platforms</h3>
                    </div>
                    <p>Trinka's real-time API let productivity platforms offer unique writing assistance to help their users be more productive. </p>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6">
                <div class="features_cards commingSoonTag">
                    <img src="../../../assets/images/subjectAreaAndDocumentType.svg" alt="">
                    <h3>Subject Area & Document Type</h3>
                    <p>Customize Trinka to provide you the most relevant suggestions based on your subject area and
                        type of manuscript.</p>
                </div>
            </div> -->

        </div>
        
    </div>
</div>





<!-- <div class="use_trinka_other_app accomplishBrilliantWrap mt-0">
    <div class="container">
        <div class="accomplishBrilliantSection">
            <h2>Get writing assistance within your application</h2>
            <a (click)="scroll(target)" class="plansButtons pointer">Contact Us</a>
        </div>
    </div>
</div> -->

<div class="pricing_works">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="brandlogo_heading">
                    <h2>Flexible Pricing that Works Best for You </h2>
                </div>
                <p>We work with you to identify the best pricing model that gives you the best value. We support text volume based, file volume based, or API call volume based pricing options to match your exact need.</p>
            </div>

            <div class="col-lg-12">
                <div class="try_trinka_Buttons">

                    <!-- <button style="margin: 0; padding: 0;" (click)="scroll(target)" id="salesContactButtons" class="plansButtons" type="button">Get a Quote</button> -->
                        <a href="https://calendly.com/trinkaai/meet" target="_blank" rel="noopener noreferrer"  id="autoEditGetStart" class="cta-outline-btn m25">Book a Demo</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="localInvoiceBanner">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6 col-lg-7">
                <div class="local-left-content">
                    <h3>Trinka Simplifies Invoices with Support for Local Currency.
                    </h3>
                    <p>Trinka offers seamless invoices  (Seikyu-sho, Fapiao, Yeongeumjeung, Fatura) in multiple local currencies of JPY, CNY, BRL, TRY, KRW, INR, USD, EUR, TWD. Organizations can also ease the invoicing process  by  clubbing multiple accounts into a single invoice in their own local currency.  </p>
                    <!-- <div class="gpButtons">
                        <a [routerLink]="['/local-invoice']" class="btn-white">Check Invoice Options</a>
                    </div> -->
                </div>
            </div>
            <div class="col-md-6 col-lg-4 offset-lg-1">
                <div class="text-center">
                    <img src="../../../assets/images/local-inovice-element.png" alt="local invoice" class="img-fluid" />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="writeToolboxs_section1">
    <div class="writeToolbox_inner_sections1">
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <div class="toolbox_text_content">
                        <h3>Safe, Secure, and Trusted</h3>
                        <p>Trinka puts data safety and privacy at the forefront. All your data is strongly encrypted and securely
                            stored - no one else has access to your data. We offer unique plans that completely eliminate
                            saving any data once you receive writing suggestions.
                        </p>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="toolbox_img_content">
                        <img src="../../../assets/images/safe-secure.png" alt="safe" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="writeToolbox_inner_sections1 sections_height_set publicationCheckSections" style="background-image: url('../../../assets/images/toolbox_backgrond_browser_plugin_r.png');">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="toolbox_img_content">
                        <img src="../../../assets/images/about-ai.png" alt="about" class="img-fluid">
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="toolbox_text_content">
                        <h3>About Trinka</h3>
                        <p>Trinka uses the latest Machine Learning (ML) and Natural Language Processing (NLP) technologies to
                            correct grammar errors and suggest language enhancements. Our AI is trained on millions of well-written papers and articles across a wide range of subject areas, including medical, life sciences, physical sciences, engineering, humanities, business, and arts, to provide you with the most relevant suggestions.</p>
                        <p>Trinka is developed by a team of linguists, editors, data scientists, and engineers with a deep desire
                            to create a future with barrier-free communication.
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section-md text-center getQuote">
        <button style="margin: 0; padding: 0;" (click)="scroll(target)" id="salesContactButtons" class="plansButtons" type="button">Contact Us</button>
    </div>
</div>