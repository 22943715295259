import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { LinkService } from '../../_services/langhfre.service';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
//declare function ire(string,{}): any;
@Component({
  selector: 'app-trinka-cloud',
  templateUrl: './trinka-cloud.component.html',
  styleUrls: ['./trinka-cloud.component.scss']
})
export class TrinkaCloudComponent implements OnInit {

  constructor(private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private LinkService: LinkService
  ) { 
    this.document.body.classList.add('nosnipptFooter');
  }

  ngOnInit() {
    this.titleService.setTitle('Free Online Grammar Editor & Grammar Checker - Trinka');
    this.metaTagService.updateTag({ name: 'description', content: "Use Trinka online grammar and spell checker for free. AI-powered advanced English grammer checker software corrects your writing in real-time." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Free Online Grammar Editor & Grammar Checker - Trink' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Use Trinka online grammar and spell checker for free. AI-powered advanced English grammer checker software corrects your writing in real-time." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/trinkacloud' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/cloud_banner_img.png' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Free Online Grammar Editor & Grammar Checker - Trinka' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Use Trinka online grammar and spell checker for free. AI-powered advanced English grammer checker software corrects your writing in real-time." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/cloud_banner_img.png' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/trinkacloud' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/trinkacloud' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/trinkacloud' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/trinkacloud' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/trinkacloud' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/trinkacloud' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/trinkacloud' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/trinkacloud' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/trinkacloud' });

    if (isPlatformBrowser(this.platformId)) {
      // $.getScript('../../../assets/js/particles.min.js', function(){});
      //$.getScript('../../../assets/js/wisepop.js', function () { });
    }
    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }
  ngOnDestroy() {
    this.document.body.classList.remove('nosnipptFooter');
  }

  
}
