import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../../_services/langhfre.service';
//declare function ire(string,{}): any;

@Component({
  selector: 'app-windows-app-feedback',
  templateUrl: './windows-app-feedback.component.html',
  styleUrls: ['./windows-app-feedback.component.scss']
})
export class WindowsAppFeedbackComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private LinkService: LinkService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Windows Desktop App | Feedback Form');
    this.metaTagService.updateTag({ name: 'description', content: "Windows Desktop App | Feedback Form" });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Windows Desktop App | Feedback Form' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Windows Desktop App | Feedback Form' });
    this.metaTagService.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/windows-desktop-app/feedback' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/windows-desktop-app/feedback' });
    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });

  }

}
