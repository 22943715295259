<footer id="footer">
    <div class="container container-lp">
        <div class="row">
            <div class="col-md-12">
                <div class="footer-top">
                    <div class="row">

                        <div class="col-lg-6">
                            <div class="about-enago">
                                <h3>About Trinka</h3>
                                <p class="commonAboutContent">
                                  <span>Trinka AI is a privacy-first grammar checker and language enhancement writing assistant designed for academic and technical writing. Trinka helps professionals and academics ensure formal, concise, and engaging writing. Trinka's Enterprise solutions come with full access, multiple integration options, and complete data control, which can be customized to fit your needs.</span>
                                </p>
                                <p class="nosnipptAboutContent">
                                    <span data-nosnippet>Trinka AI is a privacy-first grammar checker and language enhancement writing assistant designed for academic and technical writing. Trinka helps professionals and academics ensure formal, concise, and engaging writing. Trinka's Enterprise solutions come with full access, multiple integration options, and complete data control, which can be customized to fit your needs.</span>
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="install-in">
                                <h3>Install for:</h3>
                                <ul>
                                    <li>
                                        <span class="browser-icon">
                                            <a routerLink="/wordplugin">
                                                <img src="../../../assets/images/NewWordAdd_in.svg" loading="lazy" width="47" height="42" alt="word add in"> </a>
                                        </span>
                                        <span class="install-in-browser">
                                            <a routerLink="/wordplugin"> MS Word for Windows</a>
                                        </span>
                                        <!-- <a routerLink="/wordplugin"></a> -->
                                    </li>
                                    <li>
                                        <span class="browser-icon">
                                            <a routerLink="/browser-plugin">
                                                <img src="../../../assets/images/NewChromeFicon.svg" loading="lazy" width="43" height="42" alt="chrome">
                                            </a>
                                        </span>
                                        <span class="install-in-browser">
                                            <a routerLink="/browser-plugin">Google Chrome</a>
                                        </span>
                                    </li>
                                    <li>
                                        <span class="browser-icon">
                                            <a routerLink="/browser-plugin">
                                                <img src="../../../assets/images/NewFirefoxFicon.svg" loading="lazy" width="45" height="42" alt="firefox">
                                            </a>
                                        </span>
                                        <span class="install-in-browser">
                                            <a routerLink="/browser-plugin">Mozilla Firefox</a>
                                        </span>
                                    </li>
                                    <li>
                                        <span class="browser-icon">
                                            <a routerLink="/browser-plugin">
                                                <img src="../../../assets/images/EdgeWhite_BGAndOutline.svg" loading="lazy" width="42" height="42" alt="edge" width="42">
                                            </a>
                                        </span>
                                        <span class="install-in-browser">
                                            <a routerLink="/browser-plugin">Microsoft Edge</a>
                                        </span>

                                    </li>
                                    <li>
                                        <span class="browser-icon">
                                            <a routerLink="/wordplugin-lite">
                                                <img src="../../../assets/images/NewWordAdd_in.svg" loading="lazy" width="47" height="42" alt="word add-in"> </a>
                                        </span>
                                        <span class="install-in-browser">MS Word for Mac OS</span>
                                    </li>
                                    <li>
                                        <span class="browser-icon">
                                            <a routerLink="/windows-desktop-app">
                                            <img src="../../../assets/images/windows.png" loading="lazy" width="42" height="42" alt="windows" /></a>
                                        </span>
                                        <span class="install-in-browser"><a routerLink="/windows-desktop-app">Windows App</a></span>
                                    </li>
                                </ul>
                            </div>
                            <div class="contact" id="footerEmailBox">
                                <form class="form-inline" (ngSubmit)="subscribeNow()" [formGroup]="footerFroms">
                                    <div class="form-group mx-sm-3 mb-2 ml-0" [ngClass]="(!footerFroms.get('footeremailform').valid && footerFroms.get('footeremailform').dirty) || (footerFroms.get('footeremailform').touched && footerFroms.get('footeremailform').pristine) ? 'TextFiledError' : '' ">

                                        <mat-form-field appearance="outline">
                                            <mat-label>Enter email & click subscribe to get updates</mat-label>
                                            <input matInput placeholder="" formControlName="footeremailform" class="form-control" name="email">
                                        </mat-form-field>
                                    </div>
                                    <button type="submit" [disabled]="!footerFroms.valid" id="footerSubscribe" class="btn btn-primary mb-2">Subscribe</button>
                                </form>
                            </div>

                        </div>


                    </div>
                    <div class="row">

                        <div class="col-lg-12">
                            <div class="row footer_menu_wrap">
                                <div class="col">
                                    <div class="footer_menu_one">
                                        <h3>Company</h3>
                                        <ul class="ftr-menu">
                                            <li>
                                                <a href="/aboutus">About Us</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/contactus']" routerLinkActive="active">Contact Us</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/workwithus']" routerLinkActive="active">Work With Us</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/affiliates']" routerLinkActive="active">Affiliate Program</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/campus-ambassador']" routerLinkActive="active">Ambassador Program</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/news']" routerLinkActive="active">News</a>
                                            </li>
                                            <li>
                                                <a href="/data-security" routerLinkActive="active">Data Security</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="footer_menu_one">
                                        <h3>Learn More</h3>
                                        <ul class="ftr-menu">
                                            <li>
                                                <a [routerLink]="['/examples']" routerLinkActive="active">Examples</a>
                                            </li>
                                            <li>
                                                <a href="/faqs">FAQs</a>
                                            </li>
                                            <li>
                                                <a href="https://developer.trinka.ai/docs/getting-started" target="_blank" rel="noopener noreferrer">Developer Docs</a>
                                            </li>
                                            <li>
                                                <a href="/eu-ai-act-compliance">EU AI Act Compliance</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="footer_menu_one">
                                        <h3>Features</h3>
                                        <ul class="ftr-menu">
                                            <li>
                                                <a [routerLink]="['/features']">All Features</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/grammar-checker']">Grammar Checker</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/proofread-file']">Proofread MS Word File</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/latex-grammar-checker']">LaTeX Proofreader</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/paraphraser']">Online Paraphraser </a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/ai-studio/ai-writing-tools']">AI Writing Tools</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/ai-studio/ai-assisted-writing']">AI-assisted Writing</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/consistency-check']">Consistency Check</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/inclusive-language-check']">Inclusive Language Check</a>
                                            </li>
                                            <!-- <li>
                                                <a [routerLink]="['/features/documark']">Documark</a>
                                            </li> -->
                                            <li>
                                                <a [routerLink]="['/features/plagiarism-check']">Plagiarism Check</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/ai-content-detector']">AI Content Detector</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/technical-checks']">Technical Checks</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/citation-checker']">Citation Checker</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/citation-formatter']">Citation Formatter</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/journal-finder']">Journal Finder</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/legal-writing']">Legal Writing</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/personal-dictionary']">Personal Dictionary</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/credits']">Credits on Trinka</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="footer_menu_one">
                                        <h3>Enterprise Solutions</h3>
                                        <ul class="ftr-menu">
                                            <li>
                                                <a [routerLink]="['/enterprise/life-sciences-medicine-and-pharma']" routerLinkActive="active">Life Sciences, Medicine & Pharma</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/language-services-publishing-and-media']" routerLinkActive="active">Language Services, Publishing & Media</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/academic-institutions']" routerLinkActive="active">Academic Institutions</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/k12-and-elearning']" routerLinkActive="active">K-12 & E-Learning</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/technology-platforms']" routerLinkActive="active">Technology Platforms</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/sensitive-data-plan']" routerLinkActive="active">Sensitive Data Plan</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise']" routerLinkActive="active">Enterprise Integrations</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/grammar-checker-api']" routerLinkActive="active">Grammar Checker API</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/local-invoice']" routerLinkActive="active">Invoicing and Payments</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/windows-desktop-app/offline-grammar-checker']" routerLinkActive="active">Offline Grammar Checker</a>
                                            </li>
                                            <!-- <li>
                                                <a [routerLink]="['/enterprise']" routerLinkActive="active">Trinka API</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise']" routerLinkActive="active">Trinka On-Premise</a>
                                            </li> -->


                                        </ul>
                                    </div>
                                    <div class="footer_menu_one">
                                        <h3>Custom AI Models</h3>
                                        <ul class="ftr-menu">
                                            <li>
                                                <a [routerLink]="['/enterprise/custom-ai-models/overview']">Overview</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/custom-ai-models/how-we-do-it']">How We Do It</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/custom-ai-models/process']">Process</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/custom-ai-models/why-choose-us']">Why Choose Us</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/custom-ai-models/rlhf']"> Reinforcement Learning with <br/>Human Feedback (RLHF)</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row">
                                        <div class="col-sm-6 col-6">
                                            <div class="footer_menu_one">
                                                <h3>Compare</h3>
                                                <ul class="ftr-menu">
                                                    <li>
                                                        <a href="/trinka-vs-grammarly">Trinka vs. Grammarly</a>
                                                    </li>
                                                    <li>
                                                        <a href="/trinka-vs-ginger">Trinka vs. Ginger</a>
                                                    </li>
                                                    <li>
                                                        <a href="/trinka-vs-languagetool">Trinka vs. LanguageTool</a>
                                                    </li>
                                                    <li>
                                                        <a href="/trinka-vs-writefull">Trinka vs. Writefull</a>
                                                    </li>
                                                    <li>
                                                        <a href="/trinka-vs-quillbot">Trinka vs. Quillbot</a>
                                                    </li>

                                                </ul>
                                            </div>
                                            <div class="footer_menu_one">
                                                <h3>Resources</h3>
                                                <ul class="ftr-menu">
                                                    <li>
                                                        <a href="/case-study">Case Study</a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.trinka.ai/blog/"  target="_blank" rel="noopener">Blog</a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.trinka.ai/blog/category/writing-tips/"  target="_blank" rel="noopener">Writing Tips</a>
                                                    </li>
                                                    <li>
                                                        <a href="podcast" routerLinkActive="active">Podcast</a>
                                                    </li>
                                                    <li>
                                                        <a href="/webinars">Webinars</a>
                                                    </li>
                                                    <li>
                                                        <a href="/videos">Videos</a>
                                                    </li>
                                                    <li>
                                                        <a href="/trinka-vs-grammarly-vs-language-tool">Whitepaper</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-6">
                                            <div class="footer_menu_one">
                                                <h3>Tools</h3>
                                                <ul class="ftr-menu">
                                                    <li>
                                                        <a href="/grammar-checker">English Grammar Checker</a>
                                                        <!-- <a [routerLink]="['/grammar-checker']" routerLinkActive="active">Grammar Checker</a> -->
                                                    </li>
                                                    <li>
                                                        <a href="/spanish-grammar-checker">Spanish Grammar checker</a>
                                                        <!-- <a [routerLink]="['/grammar-checker']" routerLinkActive="active">Grammar Checker</a> -->
                                                    </li>
                                                    <!-- <li>
                                                        <a href="/de/grammatik-pruefen" target="_blank" rel="noopener noreferrer">German Grammar checker</a>
                                                    </li> -->
                                                    <li>
                                                        <a href="/paraphrasing-tool">Paraphrasing Tool</a>
                                                        <!-- <a [routerLink]="['/punctuation-checker']" routerLinkActive="active">Punctuation Checker</a> -->
                                                    </li>
                                                    <li>
                                                        <a href="/journal-finder">Free Journal Finder</a>
                                                        <!-- <a [routerLink]="['/punctuation-checker']" routerLinkActive="active">Punctuation Checker</a> -->
                                                    </li>
                                                    <li>
                                                        <a href="/ai-content-detector">AI Content Detector</a>
                                                        <!-- <a [routerLink]="['/punctuation-checker']" routerLinkActive="active">Punctuation Checker</a> -->
                                                    </li>
                                                    <li>
                                                        <a href="/punctuation-checker">Punctuation Checker</a>
                                                        <!-- <a [routerLink]="['/punctuation-checker']" routerLinkActive="active">Punctuation Checker</a> -->
                                                    </li>
                                                    <li>
                                                        <a href="/spell-checker">Spell Checker</a>
                                                        <!-- <a [routerLink]="['/spell-checker']" routerLinkActive="active">Spell Checker</a> -->
                                                    </li>
                                                    <li>
                                                        <a href="/sentence-checker">Sentence Checker</a>
                                                        <!-- <a [routerLink]="['/sentence-checker']" routerLinkActive="active">Sentence Checker</a> -->
                                                    </li>
                                                    <li>
                                                        <a href="/essay-checker">Essay Checker</a>
                                                    </li>
                                                    <li>
                                                        <a href="/medical-grammar-and-spelling-checker">Medical Grammar Check</a>
                                                    </li>
                                                    <li>
                                                        <a [routerLink]="['/academic-phrasebank']" routerLinkActive="active">Academic Phrasebank</a>
                                                    </li>
                                                    <li>
                                                        <a [routerLink]="['/thesis-checker']" routerLinkActive="active">Thesis Checker</a>
                                                    </li>
                                                    <li>
                                                        <a href="/ai-editing">AI Editing</a>
                                                    </li>
                                                    <li>
                                                        <a [routerLink]="['/free-proofreading-tools']" >AI Proofreading Tools</a>
                                                        <!-- <a [routerLink]="['/grammar-checker']" routerLinkActive="active">Grammar Checker</a> -->
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-12">
                                            <div class="footer_menu_one">
                                                <h3>Compliances Covered</h3>
                                                <ul class="complianceCovered">
                                                    <li>
                                                        <img src="../../../../assets/images/compliance-certificate1.svg" loading="lazy" decoding="async" alt="iso 270001:2013 information security certificate" />
                                                    </li>
                                                    <li>
                                                        <img src="../../../../assets/images/compliance-certificate2.svg" loading="lazy" decoding="async" alt="iso 9001:2015 quality certificate" />
                                                    </li>
                                                    <li>
                                                        <img src="../../../../assets/images/compliance-certificate3.svg" loading="lazy" decoding="async" alt="iso 17100:2015 translation service certificate" />
                                                    </li>
                                                    <li>
                                                        <img src="../../../../assets/images/Hipaa-logo.svg" loading="lazy" decoding="async" alt="hippa" />
                                                    </li>
                                                    <li>
                                                        <img src="../../../../assets/images/compliance-certificate4.svg" loading="lazy" decoding="async" alt="ada certificate" />
                                                    </li>
                                                    <li>
                                                        <img src="../../../../assets/images/compliance-certificate5.svg" loading="lazy" decoding="async" alt="GDPR" />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="purple-bg">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <p>Need any help? Connect with us at
                        <a href="mailto:support&#64;trinka&#46;ai">support&#64;trinka&#46;ai</a>
                    </p>
                </div>
            </div>
        </div>
    </div> -->
    <div class="footer-separator"></div>
    <div class="container container-lp">
        <div class="row">
            <div class="col-md-12">
                <div class="footer-middle">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="powerd-by">
                                <div class="powered-by-left">
                                    <h3>Powered by:</h3>
                                    <p>
                                        <a href="https://www.enago.com/" target="_blank" rel="noopener noreferrer" class="footer_logos">
                                            <img src="../../../assets/images/enago-logo.png" loading="lazy" width="140" height="45" alt="enago-english-editing-service" class="enago logo" />
                                        </a>
                                    </p>
                                </div>
                                <div class="powered-by-right">
                                    <p>Enago is a trusted name in author services for the global research community helping
                                        researchers in 125+ countries improve the communication of their research and achieve
                                        successful publication.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="footer_menu_one footer_menu_last">
                                <h3>Services Offered</h3>
                                <ul class="ftr-menu">
                                    <li>
                                        <a href="https://www.enago.com/" target="_blank" rel="noopener noreferrer">English Editing</a>
                                    </li>
                                    <li>
                                        <a href="https://www.enago.com/publication-support-services" target="_blank" rel="noopener noreferrer">Publication Support</a>
                                    </li>
                                    <li>
                                        <a href="https://www.ulatus.com/" target="_blank" rel="noopener noreferrer">Translation Company</a>
                                    </li>
                                    <li>
                                        <a href="https://www.enago.com/plagiarism-checker/" target="_blank" rel="noopener noreferrer">Plagiarism and Auto Grammar Check</a>
                                    </li>
                                    <li>
                                        <a href="https://www.voxtab.com/" target="_blank" rel="noopener noreferrer">Transcription & Subtitling Services</a>
                                    </li>
                                    <li>
                                        <a href="https://lifesciences.enago.com/" target="_blank" rel="noopener noreferrer">Global Medical Communications</a>
                                    </li>
                                    <li>
                                        <a href="https://www.read.enago.com/" target="_blank" rel="noopener noreferrer">Literature Review & Analysis Tool</a>
                                    </li>
                                    <li>
                                        <a href="https://www.charlesworth-group.com/" target="_blank" rel="noopener noreferrer">Digital Marketing Agency</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="footer-bottom">
                    <div class="row">
                        <div class="col-lg-7 col-md-12">
                            <p>© Crimson AI, {{ latestYear }}. Made in India.</p>
                            <ul class="ftr-menu1">
                                <li>
                                    <a href="mailto:support&#64;trinka&#46;ai">support&#64;trinka&#46;ai</a>
                                </li>
                                <li>
                                    <a id="PrivacyButton" [routerLink]="['/privacypolicy']" routerLinkActive="active">Privacy Policy</a>
                                </li>
                                <li>
                                    <a id="termsofserviceButton" [routerLink]="['/termsofservices']" routerLinkActive="active">Terms of Services</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-5 col-md-12">
                            <div class="follow-us">
                                <h3>Follow Us:</h3>
                                <ul class="social-media">
                                    <li>
                                        <a href="https://www.facebook.com/thetrinkaai/" rel=" no referer, no opener" aria-label="facebook" target="_blank" rel="noopener noreferrer">
                                            <i class="fa fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/thetrinkaai/" rel=" no referer, no opener" aria-label="instagram" target="_blank" rel="noopener noreferrer">
                                            <i class="fa fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://x.com/thetrinkaai" rel=" no referer, no opener" aria-label="twitter" target="_blank" rel="noopener noreferrer">
                                            <img src="../../../assets/images/twitter-logo.svg" alt="twitter" width="15" height="14" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/thetrinkaai" rel=" no referer, no opener" aria-label="linkedin" target="_blank" rel="noopener noreferrer">
                                            <i class="fa fa-linkedin"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/channel/UC1PNKZCxdUArpPVr5YFaYcA" rel=" no referer, no opener" aria-label="youtube" target="_blank" rel="noopener noreferrer">
                                            <i class="fa fa-youtube-play"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="language_sections" id="languageDropDown">
                                <i class="fa fa-globe" aria-hidden="true"></i>
                                <span>English</span>
                                <ul class="drop">
                                    <li class="selected">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                        <a href="https://www.trinka.ai/">English</a>
                                    </li>
                                    <li>
                                        <a href="https://www.trinka.ai/jp/">
                                            日本語
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://trinka.enago.cn/">
                                            简体中文
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.trinka.ai/tw/">
                                            繁体中文
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.trinka.ai/kr/">
                                            한국어
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.trinka.ai/es/">
                                            Español
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.trinka.ai/pt/">
                                            Português
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<div id="emailSubscribePopup" class="modal fade show" *ngIf="subscribePopup">
	<div class="modal-dialog modal-confirm">
		<div class="modal-content">
			<div class="modal-header flex-column">
				<div class="icon-box">
					<i class="material-icons">&#xE5CA;</i>
				</div>						
				<h4 class="modal-title w-100">Thank you for subscribing to our newsletter</h4>	
                <button type="button" (click)="dismissSubcribePopup()" id="emailSubcribeButton" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
			</div>
			<div class="modal-body">
                <p>Your subscription has been confirmed.</p>
			</div>
			<div class="modal-footer justify-content-center">
				<button type="button" (click)="dismissSubcribePopup()" id="emailSubcribeButton" class="btn btn-secondary" data-dismiss="modal">OK</button>
				<!-- <button type="button" class="btn btn-danger">Delete</button> -->
                <p>Note: If at any point of time you wish to opt out from the newsletters, you can simply click on "Unsubscribe" link at the bottom of newsletter.</p>
			</div>
		</div>
	</div>
</div>