export const api_base_url = "channels.trinka.ai"
export const trinka_server_URL_DEV = "dev.cloud.trinka.ai"
export const trinka_server_URL_UAT = "uat.cloud.trinka.ai"
export const trinka_server_URL_LIV = "cloud.trinka.ai"

export const environment = {
  production: true,
  // production: false,
  server_address: "https://"+api_base_url,
  //getCountryCode: "http://uat.payments-api.trinka.ai",
  getCountryCode: "https://payments-api.trinka.ai",
  server_url: {
    katana_server: "https://gc.crimsoni.ai/api/v1/service/katana",
    gc_server: "https://gc.crimsoni.ai",
    //phrasebank_server: "http://93.115.26.69:7776"
    phrasebank_server: "https://phrasebank.trinka.ai",
    paraphraser_server: "https://paraphraser.trinka.ai",
    ai_detection_server: "https://api-content-detector.trinka.ai",
    multilingual_server: "https://api-content-detector.trinka.ai",
    free_journal_finder: "https://mktg-journal-finder.trinka.ai"
  },
  webinar: {
    server_endpoint: "https://easydash.enago.com",
    server_endpoint_email: "https://www.enago.com",
    auth: {
      identifier: "api@trinka.ai",
      password: "mktg@trinka123"
    },
    webId: "85052284809"
  },
  // application_server_address : "http://" + trinka_server_URL_DEV,
  // application_server_address : "http://" + trinka_server_URL_UAT,
  application_server_address : "https://" + trinka_server_URL_LIV,
  amplitude_api_key: "d6d0919d33fe750608c491612d7bcd97",
  social_redirectURL: "https://cloud.trinka.ai/signin",
  // social_redirectURL: "localhost:4201/signin",
  trialMode : false,
  enago_social_redirect_url: "https://accounts.trinka.ai/ExternalProvider/",
  pipedriveApiUrl: 'https://crimsoninteractiveprivatelimited.pipedrive.com',
  pipedriveApiToken: 'a18c8c6c4dbdce376427ce6e4651038c1e7c2f6b',
  translationApiUrl: 'https://dsmt.crimsoni.ai',
  documentTranslationApi: 'https://mt.dev.trinka.ai/api/v1/document/translate'
};
