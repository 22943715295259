import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../_services/langhfre.service';
import * as $ from 'jquery';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
//declare function ire(string,{}): any;
@Component({
  selector: 'app-macwordplugin',
  templateUrl: './macwordplugin.component.html',
  styleUrls: ['./macwordplugin.component.scss']
})
export class MacwordpluginComponent implements OnInit {

  deviceInfo = null;
  constructor(private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService,
    private deviceDetectorService: DeviceDetectorService
  ) { }

  ngOnInit() {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    // console.log(this.deviceInfo);
    this.titleService.setTitle("Free Grammar Checker Add-on for MS Word (Lite Version) ");
    this.metaTagService.updateTag({ name: 'description', content: "Get advanced grammar checks done as you write using Trinka's MS Word add-on as plug-in for Windows, Mac, and Office 365." });
    this.metaTagService.updateTag({ property: 'og:title', content: "Free Grammar Checker Add-on for MS Word (Lite Version) " });
    this.metaTagService.updateTag({ property: 'og:description', content: "Get advanced grammar checks done as you write using Trinka's MS Word add-on as plug-in for Windows, Mac, and Office 365." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/wordplugin-lite' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/maclite-banner.svg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Free Grammar Checker Add-on for MS Word (Lite Version) ' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Get advanced grammar checks done as you write using Trinka's MS Word add-on as plug-in for Windows, Mac, and Office 365." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/maclite-banner.svg' });
    this.metaTagService.updateTag({ name: 'keywords', content: 'office 365 grammar check, word 365 grammar check, microsoft 365 grammar check, outlook 365 grammar check, grammar check office 365, grammar check in office 365' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/wordplugin-lite' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/wordplugin-lite' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/wordplugin-lite' });
    //this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/wordplugin-lite' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/wordplugin-lite' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/wordplugin-lite' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/wordplugin-lite' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/wordplugin-lite' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/wordplugin-lite' });
    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../assets/js/owl.carousel.min.js', function () { });
      $.getScript('../../../assets/js/about_us_testimonial_silders.js', function () { });
      //$.getScript('../../../assets/js/wisepop.js', function () { });
      $.getScript('../../../assets/js/home_client_slider.js', function () { });
    }
    
    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });

  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  
}
