<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <section class="mainTopBanner checker-banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="topBannerWrapper">
                        <div class="top-banner-left">
                            <h1>Paraphraser</h1>
                            <h2>Improve the Clarity of Your Writing</h2>
                            <p>Enhance clarity in your writing with Trinka's Paraphraser. Receive suggestions to create coherent paraphrases while retaining the original meaning.</p>
                            <div class="btn-group">
                                <a href="https://cloud.trinka.ai/signup?_rg=GB" class="filled-btn" target="_blank" rel="noopener noreferrer">Get Started</a>
                            </div>
                        </div>
                        <div class="top-banner-right">
                            <div id="banner-subject" class="carousel slide" data-ride="carousel" data-interval="3000">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="carousel-inner">
                                            <div class="carousel-item active">
                                                <img src="../../../../assets/images/paraphraser/online_paraphrase_banner.svg" alt="paraphraser english" class="img-fluid" />
                                            </div>
                                            <div class="carousel-item ">
                                                <img src="../../../../assets/images/paraphraser/online_paraphrase_spanish.svg" alt="paraphraser spanish" class="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="cs-dots">
                                            <h6>Available Languages</h6>
                                            <ul class="carousel-indicators">
                                                <li data-target="#banner-subject" data-slide-to="0" class="active">English</li>
                                                <li data-target="#banner-subject" data-slide-to="1">Spanish</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="howItWorks section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="list-wrapper-image">
                        <a href="" data-toggle="modal" data-target="#wordPluginPopup" id="VewiVideoSection">
                            <img src="../../../../assets/images/online-phraphraser-video-banner.jpg" loading="lazy" decoding="async" alt="paraphraser" class="img-fluid eposter-img">
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="list-wrapper-content-2">
                        <div class="trinka-title mb-0">
                            <h2>See How It Works</h2>
                            <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                            <p>Check out the easy walk-through video to get started with Trinka Paraphrasing tool in no time.</p>
                            <p>It is a detailed step-by-step tutorial to help you use Trinka to its fullest.</p>
                            <p>Have more questions? Browse through our FAQs <a style="color: #0000ff;" (click)="scroll(target)" class="link text-link">here</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="writeToolboxs_section section-pd pb-0">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Key Features of Trinka Paraphraser</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Learn about Trinka's advanced paraphraser and its intuitive features that make rephrasing quick and easy.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row align-items-center flex-row-reverse">
                <div class="col-md-6">
                    <div class="list-wrapper-content-2">
                        <h3>Speed up Paraphrasing</h3>
                        <p>Paraphraser has been seamlessly integrated into Trinka's existing software so that you can enjoy it within your familiar user interface.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="list-wrapper-image list-wrapper-video">
                        <video class="vid-video" id="myVideo" playsinline autoplay loop muted oncanplay="this.play()" onloadedmetadata="this.muted = true" >
                            <source src="../../../../assets/images/paraphraser/videos/speed-up-paraphrase.mp4" type="video/mp4">
                            Your browser does not support the video element. Kindly update it to latest version.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- <section class="section-pd list-wrapper-style-2 pt-0">
        <div class="trinka_container">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="list-wrapper-content-2 pr-80">
                        <h3>Adjust the Level of Variation </h3>
                        <p>Trinka allows you to determine the extent of the changes you want to make by letting you adjust the degree of rephrasing according to your liking. </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="list-wrapper-image list-wrapper-video">
                        <video class="vid-video" id="myVideo" playsinline autoplay loop muted oncanplay="this.play()" onloadedmetadata="this.muted = true" >
                            <source src="../../../../assets/images/paraphraser/videos/level-of-variation.mp4" type="video/mp4">
                            Your browser does not support the video element. Kindly update it to latest version.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <section class="section-pd list-wrapper-style-2 pt-0">
        <div class="trinka_container">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="list-wrapper-content-2 pr-80">
                        <h3>Choose from Multiple Paraphrased Alternatives </h3>
                        <p>Trinka provides alternatives of the paraphrased text, allowing you to improve coherency. </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="list-wrapper-image list-wrapper-video">
                        <video class="vid-video" id="myVideo" playsinline autoplay loop muted oncanplay="this.play()" onloadedmetadata="this.muted = true" >
                            <source src="../../../../assets/images/paraphraser/videos/aternatives.mp4" type="video/mp4">
                            Your browser does not support the video element. Kindly update it to latest version.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-pd list-wrapper-style-2 pt-0">
        <div class="trinka_container">
            <div class="row align-items-center flex-row-reverse">
                <div class="col-md-6">
                    <div class="list-wrapper-content-2">
                        <h3>Replace paraphrased text effortlessly </h3>
                        <p>With Trinka, you can easily replace your original content by your paraphrased one with a couple of clicks, and it will automatically be inserted into your document.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="list-wrapper-image list-wrapper-video">
                        <video class="vid-video" id="myVideo" playsinline autoplay loop muted oncanplay="this.play()" onloadedmetadata="this.muted = true" >
                            <source src="../../../../assets/images/paraphraser/videos/Replaced-Paraphrase.mp4" type="video/mp4">
                            Your browser does not support the video element. Kindly update it to latest version.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-pd list-wrapper-style-2 pt-0">
        <div class="trinka_container">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="list-wrapper-content-2 pr-80">
                        <h3>Review Changes and Finalize </h3>
                        <p>With Trinka, you can view the paraphrased text and identify the changes made in the original text as tracked changes. </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="list-wrapper-image list-wrapper-video">
                        <video class="vid-video" id="myVideo" playsinline autoplay loop muted oncanplay="this.play()" onloadedmetadata="this.muted = true" >
                            <source src="../../../../assets/images/paraphraser/videos/show-revisions.mp4" type="video/mp4">
                            Your browser does not support the video element. Kindly update it to latest version.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-pd section-purple paraphrasingModes">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title text-center">
                        <h2>Paraphrasing Modes to Suit Your Writing Needs</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-12">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#standard">Standard</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#academic">Academic</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#formal">Formal</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#concise">Concise</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#simple">Simple</a>
                        </li>
                    </ul>
                    
                    <!-- Tab panes -->
                    <div class="tab-content bg-white">
                        <!-- Standard Tab -->
                        <div id="standard" class="tab-pane active">
                            <div class="pt-content">
                                <div class="row align-items-center">
                                    <div class="col-12">
                                        <p class="mb-30">Rewrites your text by revising the vocabulary or sentence structure</p>
                                    </div>
                                    <div class="col-5">
                                        <p><b>Example:</b></p>
                                    </div>
                                    <div class="col-7">
                                        <div class="LanguageToggleButton mb-3 justify-content-end">
                                            <div class="language-toggle-btn">
                                                <p>Show Revision</p>
                                                <label class="switch" style="margin:0 8px;">
                                                    <input type="checkbox" [(ngModel)]="standardExample" checked>
                                                    <span class="slider-rd round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="exampleContainer">
                                    <div class="exampleContent">
                                        <div class="statementText">
                                            <h6>Original Text</h6>
                                            <p>Infectious disease epidemics cause a decline in screening uptake among asymptomatic individuals, leading to concerns regarding advanced cancer cases and mortality.</p>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="seperator">
                                            <span>
                                                <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5.5 5L11.7929 11.2929C12.1834 11.6834 12.1834 12.3166 11.7929 12.7071L5.5 19" stroke="#7A28A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M13.5 5L19.7929 11.2929C20.1834 11.6834 20.1834 12.3166 19.7929 12.7071L13.5 19" stroke="#7A28A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="exampleContent">
                                        <div class="statementText">
                                            <h6>Paraphrased Text</h6>
                                            <p [ngClass]="{'hidden': !standardExample}">
                                                <i class="wrongTextParaphrased">Infectious disease epidemics cause a decline</i>
                                                <i class="RightTextParaphrased">Epidemics of infectious diseases result</i>
                                                in
                                                <i class="wrongTextParaphrased">screening uptake among</i>
                                                <i class="RightTextParaphrased">a decrease in the number of</i>
                                                asymptomatic individuals,
                                                <i class="wrongTextParaphrased">leading</i> who
                                                <i class="RightTextParaphrased">undergo screening, giving rise</i>
                                                to concerns
                                                <i class="wrongTextParaphrased">regarding</i>
                                                <i class="RightTextParaphrased">about</i>
                                                a rise in advanced cancer
                                                <i class="RightTextParaphrased">cases</i>
                                                and
                                                <i class="wrongTextParaphrased">deaths increased</i>
                                                <i class="RightTextParaphrased">mortality.</i>
                                            </p>
                                            <p [ngClass]="{'hidden': standardExample}">Epidemics of infectious diseases result in a decrease in the number of asymptomatic individuals who undergo screening, giving rise to concerns about a rise in advanced cancer cases and mortality.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Academic Tab -->
                        <div id="academic" class="tab-pane fade">
                            <div class="pt-content">
                                <div class="row align-items-center">
                                    <div class="col-12">
                                        <p class="mb-30">Makes your writing appropriate for academic readers and publishers</p>
                                    </div>
                                    <div class="col-5">
                                        <p><b>Example:</b></p>
                                    </div>
                                    <div class="col-7">
                                        <div class="LanguageToggleButton mb-3 justify-content-end">
                                            <div class="language-toggle-btn">
                                                <p>Show Revision</p>
                                                <label class="switch" style="margin:0 8px;">
                                                    <input type="checkbox" [(ngModel)]="academicExample" checked>
                                                    <span class="slider-rd round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="exampleContainer">
                                    <div class="exampleContent">
                                        <div class="statementText">
                                            <h6>Original Text</h6>
                                            <p>This work could help us figure out how other bugs that live in colonies, like ants and bees, deal with different bio situations in nature</p>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="seperator">
                                            <span>
                                                <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5.5 5L11.7929 11.2929C12.1834 11.6834 12.1834 12.3166 11.7929 12.7071L5.5 19" stroke="#7A28A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M13.5 5L19.7929 11.2929C20.1834 11.6834 20.1834 12.3166 19.7929 12.7071L13.5 19" stroke="#7A28A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="exampleContent">
                                        <div class="statementText">
                                            <h6>Paraphrased Text</h6>
                                            <p [ngClass]="{'hidden': !academicExample}">
                                                This
                                                <i class="wrongTextParaphrased">work</i>
                                                <i class="RightTextParaphrased">research</i>
                                                <i class="wrongTextParaphrased">could help us figure out</i>
                                                <i class="RightTextParaphrased">may contribute to a deeper understanding of</i> how other
                                                <i class="wrongTextParaphrased">bugs that live in colonies</i>
                                                <i class="RightTextParaphrased">social insects,</i>
                                                <i class="wrongTextParaphrased">likes</i>
                                                <i class="RightTextParaphrased">such as</i> ants and bees,
                                                <i class="wrongTextParaphrased">deal with different</i>
                                                <i class="RightTextParaphrased">adapt to diverse</i>
                                                <i class="wrongTextParaphrased">bio situation in nature</i>
                                                <i class="RightTextParaphrased">environmental conditions in their natural habitats.</i>
                                            </p>
                                            
                                            <p [ngClass]="{'hidden': academicExample}">This research may contribute to a deeper understanding of how other social insects, such as ants and bees, adapt to diverse environmental conditions in their natural habitats.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Formal Tab -->
                        <div id="formal" class="tab-pane fade">
                            <div class="pt-content">
                                <div class="row align-items-center">
                                    <div class="col-12">
                                        <p class="mb-30"> Makes your writing tone professional and businesslike</p>
                                    </div>
                                    <div class="col-5">
                                        <p><b>Example:</b></p>
                                    </div>
                                    <div class="col-7">
                                        <div class="LanguageToggleButton mb-3 justify-content-end">
                                            <div class="language-toggle-btn">
                                                <p>Show Revision</p>
                                                <label class="switch" style="margin:0 8px;">
                                                    <input type="checkbox" [(ngModel)]="formalExample" checked>
                                                    <span class="slider-rd round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="exampleContainer">
                                    <div class="exampleContent">
                                        <div class="statementText">
                                            <h6>Original Text</h6>
                                            <p>Can we move that meeting up with the HR folks to 9:30 am?</p>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="seperator">
                                            <span>
                                                <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5.5 5L11.7929 11.2929C12.1834 11.6834 12.1834 12.3166 11.7929 12.7071L5.5 19" stroke="#7A28A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M13.5 5L19.7929 11.2929C20.1834 11.6834 20.1834 12.3166 19.7929 12.7071L13.5 19" stroke="#7A28A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="exampleContent">
                                        <div class="statementText">
                                            <h6>Paraphrased Text</h6>
                                            <p [ngClass]="{'hidden': !formalExample}">
                                                Can we
                                                <i class="wrongTextParaphrased">move that</i>
                                                <i class="RightTextParaphrased">reschedule our</i>
                                                meeting
                                                <i class="wrongTextParaphrased">up</i> with the HR
                                                <i class="wrongTextParaphrased">folks</i>
                                                <i class="RightTextParaphrased">department</i> to 9:30 am?
                                            </p>
                                                
                                            
                                            <p [ngClass]="{'hidden': formalExample}">Can we reschedule our meeting with the HR department to 9:30 am?</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Concise Tab -->
                        <div id="concise" class="tab-pane fade">
                            <div class="pt-content">
                                <div class="row align-items-center">
                                    <div class="col-12">
                                        <p class="mb-30"> Makes your writing to the point and direct by using less words</p>
                                    </div>
                                    <div class="col-5">
                                        <p><b>Example:</b></p>
                                    </div>
                                    <div class="col-7">
                                        <div class="LanguageToggleButton mb-3 justify-content-end">
                                            <div class="language-toggle-btn">
                                                <p>Show Revision</p>
                                                <label class="switch" style="margin:0 8px;">
                                                    <input type="checkbox" [(ngModel)]="conciseExample" checked>
                                                    <span class="slider-rd round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="exampleContainer">
                                    <div class="exampleContent">
                                        <div class="statementText">
                                            <h6>Original Text</h6>
                                            <p>In this study, recent works on the thermal conductivity of nanofluids are examined to gain the best insights into and understanding of the influential parameters that affect thermal conductivity.</p>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="seperator">
                                            <span>
                                                <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5.5 5L11.7929 11.2929C12.1834 11.6834 12.1834 12.3166 11.7929 12.7071L5.5 19" stroke="#7A28A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M13.5 5L19.7929 11.2929C20.1834 11.6834 20.1834 12.3166 19.7929 12.7071L13.5 19" stroke="#7A28A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="exampleContent">
                                        <div class="statementText">
                                            <h6>Paraphrased Text</h6>
                                            <p [ngClass]="{'hidden': !conciseExample}">
                                                <i class="wrongTextParaphrased">In this study, recent works</i>
                                                <i class="RightTextParaphrased">Recent research</i>
                                                on the thermal conductivity of nanofluids
                                                <i class="wrongTextParaphrased">are examined</i>
                                                <i class="RightTextParaphrased">is reviewed</i> to
                                                <i class="wrongTextParaphrased">gain the best insights into and</i>
                                                <i class="RightTextParaphrased">provide a comprehensive</i> understanding of the
                                                <i class="wrongTextParaphrased">influential parameters that affect</i>
                                                <i class="RightTextParaphrased">factors impacting</i> thermal conductivity.
                                            </p>
                                            <p [ngClass]="{'hidden': conciseExample}">Recent research on the thermal conductivity of nanofluids is reviewed to provide a comprehensive understanding of the factors impacting thermal conductivity.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Simple Tab -->
                        <div id="simple" class="tab-pane fade">
                            <div class="pt-content">
                                <div class="row align-items-center">
                                    <div class="col-12">
                                        <p class="mb-30"> Conveys your message in an easy-to-understand way</p>
                                    </div>
                                    <div class="col-5">
                                        <p><b>Example:</b></p>
                                    </div>
                                    <div class="col-7">
                                        <div class="LanguageToggleButton mb-3 justify-content-end">
                                            <div class="language-toggle-btn">
                                                <p>Show Revision</p>
                                                <label class="switch" style="margin:0 8px;">
                                                    <input type="checkbox" [(ngModel)]="simpleExample" checked>
                                                    <span class="slider-rd round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="exampleContainer">
                                    <div class="exampleContent">
                                        <div class="statementText">
                                            <h6>Original Text</h6>
                                            <p>Idarucizumab binds to dabigatran and quickly reverses its anticoagulant effects.</p>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="seperator">
                                            <span>
                                                <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5.5 5L11.7929 11.2929C12.1834 11.6834 12.1834 12.3166 11.7929 12.7071L5.5 19" stroke="#7A28A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M13.5 5L19.7929 11.2929C20.1834 11.6834 20.1834 12.3166 19.7929 12.7071L13.5 19" stroke="#7A28A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="exampleContent">
                                        <div class="statementText">
                                            <h6>Paraphrased Text</h6>
                                            <p [ngClass]="{'hidden': !simpleExample}">
                                                Idarucizumab
                                                <i class="wrongTextParaphrased">binds</i>
                                                <i class="RightTextParaphrased">attaches</i>
                                                to dabigatran and
                                                <i class="wrongTextParaphrased">quickly</i>
                                                <i class="RightTextParaphrased">rapidly</i>
                                                reverses its
                                                <i class="wrongTextParaphrased">anticoagulant</i>
                                                <i class="RightTextParaphrased">blood-thinning</i>
                                                effects.
                                            </p>
                                            <p [ngClass]="{'hidden': simpleExample}">Idarucizumab attaches to dabigatran and rapidly reverses its blood-thinning effects.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>

    <div class="cloud_key_features section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Why Choose Trinka Paraphraser</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <img src="../../../../assets/images/paraphraser/icon1.svg" loading="lazy" decoding="async" alt="Improve Efficiency">
                        <h3>Improve Efficiency </h3>
                        <p>Save valuable time and enhance productivity, with Trinka paraphraser integrated within the editor - allowing you to work on a single platform.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <img src="../../../../assets/images/paraphraser/icon2.svg" loading="lazy" decoding="async" alt="Paraphrase Effectively">
                        <h3>Paraphrase Effectively</h3>
                        <p>Get complete control over the final output with Trinka Paraphraser, allowing you to choose from different modes for rephrasing and select the option that matches your desired tone and style.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <img src="../../../../assets/images/paraphraser/icon3.svg" loading="lazy" decoding="async" alt="Improve clarity">
                        <h3>Improve<br class="d-lg-block d-none"/> Clarity</h3>
                        <p>Boost clarity and coherence while paraphrasing with Trinka paraphraser. It checks your sentences for grammar, spelling, and structure issues.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

  

    <div class="trinka-banner section-pd-sm">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Enjoy Unlimited access for Trinka Paraphraser</h3>
                    </div>
                    <div class="btn-group justify-content-center">
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" class="white-btn" target="_blank" rel="noopener noreferrer">Get Started</a>
                    </div>
                </div>
            </div>  
        </div>
    </div>

    <app-security-section></app-security-section>

    <div class="allAboutTrinkaCredit list-wrapper-style-2 section-pd pt-0">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <span class="trinka-sub-title mx-auto">For Enterprise</span>
                        <h2>Trinka Paraphraser API</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Enhance the clarity of your content by incorporating Trinka's paraphrasing capabilities directly into your organization's workflows.</p>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="api_img text-center">
                        <img src="../../../../assets/images/paraphraser/API.svg" loading="lazy" decoding="async" class="img-fluid" alt="paraphraser api" />
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-4 col-md-4">
                            <div class="list-wrapper-content-2 list-style-cards">
                                <h3>Fast and Scalable</h3>
                                <p>Trinka's rapid paraphrasing will help boost your team's content production and improve overall productivity. It's easily scalable with your organization's growing needs.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                            <div class="list-wrapper-content-2 list-style-cards">
                                <h3>Data<br/>Security</h3>
                                <p>All your data is under a highly encrypted environment. Your content remains confidential, no matter the volume of text you're working with.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                            <div class="list-wrapper-content-2 list-style-cards">
                                <h3>Customizable<br class="d-md-block d-none"/>&nbsp;</h3>
                                <p> Paraphrasing levels and the number of rephrase options can be customized across your organization to ensure consistency. Achieve clarity and uniformity effortlessly.</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="aboutTrinkaCreditHeading list-wrapper-content-2 pr-80">
                        <ul class="row">
                            <li class="col-lg-4 col-md-4"><b>Fast and Scalable -</b> Trinka's rapid paraphrasing will help boost your teams content production and improve overall productivity. It's easily scalable with your organization's growing needs.</li>
                            <li class="col-lg-4 col-md-4"><b>Data Security -</b> All your data is under a highly encrypted environment. Your content remains confidential, no matter the volume of text you're working with.</li>
                            <li class="col-lg-4 col-md-4"><b>Customizable -</b> Paraphrasing levels and the number of rephrase options can be customized across your organization to ensure consistency. Achieve clarity and uniformity effortlessly.</li>
                        </ul>
                    </div> -->
                </div>
                
            </div>
        </div>
    </div>

    <div class="faqQuestionAnwser section-pd pt-0" #target>
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="trinka-title text-center">
                        <h2>Frequently Asked Questions</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="faqQuestionsList">
                <div class="accordion" id="faq">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionOne">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                            Is Trinka Paraphraser Free?</a>
                                    </div>
    
                                    <div id="QuestionOneContent" class="collapse" aria-labelledby="QuestionOne" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Yes, you can paraphrase your content for free on Trinka. However, with the Basic plan, there is a monthly limit of 5000 words for paraphrasing, in addition to the 5000 words available for grammar checking. </p>
                                            <p>For those seeking unrestricted access, the Premium plan is the ideal choice. With the Premium plan, there are no limitations, enabling users to paraphrase content as extensively as they require, without any restrictions. Upgrade Now!</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="QuestionTwo">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                            aria-controls="QuestionTwoContent">
                                            Is my content secure when using the paraphrasing platform? </a>
                                    </div>
    
                                    <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Yes, Trinka follows the highest level of data security standards and adheres to numerous compliance measures. Rest assured, your data is completely secure and treated with the utmost confidentiality. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionThree">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionThreeContent" aria-expanded="true"
                                            aria-controls="QuestionThreeContent">
                                            Can I customize the level of paraphrasing to match my writing style?
                                        </a>
                                    </div>
    
                                    <div id="QuestionThreeContent" class="collapse" aria-labelledby="QuestionThree" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Absolutely, you have full control over choosing from the various modes while paraphrasing to match your desired tone and style. You can even choose from multiple paraphrased options to ensure that the content aligns perfectly with your preferences.</p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionFour">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFourContent" aria-expanded="true"
                                            aria-controls="QuestionFourContent">
                                            Is the paraphrasing tool capable of handling technical or specialized content? 
                                        </a>
                                    </div>
    
                                    <div id="QuestionFourContent" class="collapse" aria-labelledby="QuestionFour" data-parent="#faq">
                                        <div class="card-body">
                                            <!-- <p>In addition to all the features you get in Basic, you also get Power Mode with a Premium subscription.  Power Mode provides users with up to 40% more suggestions for their content while considering the context of the writing. Furthermore, </p> -->
                                            <p>Trinka specializes in academic and technical writing, making it the top choice for handling technical or specialized content. Our tool is tailored to maintain accuracy and specificity in such documents.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="otherApps section-pd-sm">
        <div class="trinka_container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Use Trinka in Other Apps</h3>
                    </div>
                    <ul>
                        <li>
                            <a [routerLink]="['/trinkacloud']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_apps_TrinkaCloud.svg" loading="lazy" decoding="async" alt="trinka cloud">
                                <h3>Cloud</h3>
                            </a>
                        </li>
                        <li>
                            <a  [routerLink]="['/windows-desktop-app']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/native-app/windows-icon.svg" alt="windows">
                                <h3>Windows App</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/wordplugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Others_App_MSWord.svg" loading="lazy" decoding="async" alt="ms word-count-outer">
                                <h3>MS Word Add-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/browser-plugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/other_Apps_browser.svg" loading="lazy" decoding="async" alt="browser plug-in">
                                <h3>Browser Plug-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/enterprise']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_Apps_enterprise.svg" loading="lazy" decoding="async" alt="enterpise">
                                <h3>Enterprise <small>(API, JS, & On-Premise)</small></h3>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="wordPluginPopup" tabindex="-1" role="dialog" aria-labelledby="wordPluginLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 0;">
                <button type="button" id="closedPopup" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div id="iframeHolder"></div>

                <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/qF_9Al4NHIY"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                <!-- <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/z-TVcRwVp3A"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
            </div>
        </div>
    </div>
</div>