import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../../_services/langhfre.service';

@Component({
  selector: 'app-offline-grammar-checker',
  templateUrl: './offline-grammar-checker.component.html',
  styleUrls: ['./offline-grammar-checker.component.scss']
})
export class OfflineGrammarCheckerComponent implements OnInit {

  constructor(
      private titleService: Title,
      private metaTagService: Meta,
      @Inject(PLATFORM_ID) private platformId: Object,
      private LinkService: LinkService
    ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Offline Grammar and Spell Checker - Correct Grammar Without Internet');
    this.metaTagService.updateTag({ name: 'description', content: "Get accurate corrections and suggestions to enhance your writing with Trinka's offline grammar and spell checker that works without internet dependency." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Offline Grammar and Spell Checker' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Correct Grammar Without Internet' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/windows-desktop-app/offline-grammar-checker' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Offline Grammar and Spell Checker' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Correct Grammar Without Internet" });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/windows-desktop-app' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/windows-desktop-app/offline-grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/windows-desktop-app/offline-grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/windows-desktop-app/offline-grammar-checker' });
  }

}
