import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, OnDestroy, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../../_services/langhfre.service';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-toolkit/universal';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { WebsiteService } from 'src/app/_services/website.service';
import { Router } from '@angular/router';
import { LoadScriptService } from 'src/app/_services/load-script.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
//declare function ire(string,{}): any;
@Component({
  selector: 'app-data-sensitive-plan',
  templateUrl: './data-sensitive-plan.component.html',
  styleUrls: ['./data-sensitive-plan.component.scss']
})
export class DataSensitivePlanComponent implements OnInit, AfterViewInit {
  contactForm: FormGroup;
  formSection: boolean = true;
  thankSection: boolean = false;
  country: string;
  isSubmitting: boolean = false;
  constructor(private titleService: Title,
    private toastr: ToastrService,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService,
    @Inject(WINDOW) private window: Window,
    private networkCall: WebsiteService,
    private fb: FormBuilder,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
    public loadScript: LoadScriptService,
    private http: HttpClient
  ) { 

    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])],
      'contactFormSubjects': ['', Validators.required],
      'contactFormWebsite': ['', Validators.compose([Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')])],
      'contactFormMessage': ['', Validators.required],
      'contactphoneNo': ['', Validators.required],
      'NatureBusinessType': ['', Validators.required],
      'DesignationTypes': ['', Validators.required],
    });
  }
  sectionValue(section: string) {
    this.formSection = true;
    this.thankSection = false;
  }

  ngOnInit(): void {
    this.titleService.setTitle("Protect Your Private Data With Trinka's Sensitive Data Plan");
    this.metaTagService.updateTag({ name: 'description', content: "Made for enterprises that need the highest level of security and full data control, Trinka's Sensitive Data Plan neither saves your data nor uses it for AI training." });
    this.metaTagService.updateTag({ property: 'og:title', content: "Protect Your Private Data With Trinka's Sensitive Data Plan" });
    this.metaTagService.updateTag({ property: 'og:description', content: "Made for enterprises that need the highest level of security and full data control, Trinka's Sensitive Data Plan neither saves your data nor uses it for AI training." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/enterprise/sensitive-data-plan' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: "Protect Your Private Data With Trinka's Sensitive Data Plan" });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Made for enterprises that need the highest level of security and full data control, Trinka's Sensitive Data Plan neither saves your data nor uses it for AI training." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/enterprise/sensitive-data-plan' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/enterprise/sensitive-data-plan' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/enterprise/sensitive-data-plan' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/enterprise/sensitive-data-plan' });
    //this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/enterprise/sensitive-data-plan' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/enterprise/sensitive-data-plan' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/enterprise/sensitive-data-plan' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/enterprise/sensitive-data-plan' });
    //this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/enterprise/sensitive-data-plan' });
    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../assets/js/owl.carousel.min.js', function () { });
      //$.getScript('../../../assets/js/wisepop.js', function () { });
      $.getScript('../../../assets/js/home_client_slider.js', function () { });
      this.country = localStorage.getItem("country")? localStorage.getItem("country"):"en";
    }

    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  ngAfterViewInit() {
    
  }

  sendContactUsForm() {
      if (this.contactForm.valid) {
          this.isSubmitting = true;
  
          if (!localStorage.getItem("country-trinka")) {
              this.loadGeoAPIScript();
          }
  
          this.country = localStorage.getItem("country-trinka") || "IN";
  
          const organizationName = this.contactForm.value.contactFormSubjects || "Unknown Organization";
          const personName = this.contactForm.value.contactFormName;
          const email = this.contactForm.value.contactFormEmail;
          const phone = this.contactForm.value.contactphoneNo;
          const designation = this.contactForm.value.DesignationTypes;
          const comments = `Requirement - ${this.contactForm.value.contactFormMessage || ""}
            Website - ${this.contactForm.value.contactFormWebsite || ""}
            Nature of business - ${this.contactForm.value.NatureBusinessType}
            URL - ${this.router.url || "N/A"}`;
  
          console.log("comments are:", comments);
  
          const query = this.contactForm.value.NatureBusinessType + "||" + this.contactForm.value.contactFormSubjects + "||" + this.contactForm.value.contactFormMessage + "||" + this.contactForm.value.contactFormName + "||" + this.contactForm.value.DesignationTypes + "||" + this.contactForm.value.contactphoneNo + "||" + this.contactForm.value.contactFormWebsite;
  
          this.networkCall.postContactUs(this.contactForm.value.contactFormEmail, query, this.router.url).subscribe(result => {
            
            // Step 1: Search for Existing Organization
            this.http.get(`${environment.pipedriveApiUrl}/v1/organizations/search?term=${organizationName}&api_token=${environment.pipedriveApiToken}`)
            .subscribe(searchResponse => {
                const existingOrganization = searchResponse['data']?.items?.find(item => item.item.name === organizationName);
                console.log("inside pipedrive api");
                if (existingOrganization) {
                    console.log("Existing Organization Found:", existingOrganization);
                    const organizationId = existingOrganization.item.id;
                    this.createPersonAndLead(personName, email, phone, organizationId, comments, designation);
                } else {
                    // Organization does not exist, create a new one
                    this.createOrganization(organizationName, personName, email, phone, comments, designation);
                }
            }, searchError => {
                console.error("Pipedrive Organization Search Error:", searchError);
            });
  
            this.toastr.success("Thanks! We will contact you shortly.")
            this.formSection = false;
            this.thankSection = true;
            this.contactForm.reset();
          }, error => {
            this.toastr.warning("Something went wrong!")
          }).add(() => {
            this.isSubmitting = false;
          });
      } else {
          this.validateAllFormFields(this.contactForm);
      }
    }
  
    createOrganization(organizationName: string, personName: string, email: string, phone: string, commets: string, designation: string) {
      const organizationData = { name: organizationName };
  
      this.http.post(`${environment.pipedriveApiUrl}/v1/organizations?api_token=${environment.pipedriveApiToken}`, organizationData)
          .subscribe(orgResponse => {
              console.log("Organization Created:", orgResponse);
              const organizationId = orgResponse['data']?.id;
  
              if (!organizationId) {
                  console.error("Error: No organization ID returned from Pipedrive.");
                  return;
              }
  
              this.createPersonAndLead(personName, email, phone, organizationId, commets, designation);
          }, orgError => {
              console.error("Pipedrive Organization Error:", orgError);
          });
    }
  
    createPersonAndLead(personName: string, email: string, phone: string, organizationId: number, comments: string, designation: string) {
      const personData = {
          name: personName,
          email: email,
          phone: phone,
          visible_to: 3,
          org_id: organizationId // Associate person with the organization
      };
  
      this.http.post(`${environment.pipedriveApiUrl}/v1/persons?api_token=${environment.pipedriveApiToken}`, personData)
          .subscribe(personResponse => {
              console.log("Person Created:", personResponse);
              const personId = personResponse['data']?.id;
  
              if (!personId) {
                  console.error("Error: No person ID returned from Pipedrive.");
                  return;
              }
  
              this.createLead(personName, personId, organizationId, comments, designation);
          }, personError => {
              console.error("Pipedrive Person Error:", personError);
          });
    }
  
    createLead(personName: string, personId: number, organizationId: number, comments: string, designation: string) {
      const pipedriveData = {
          title: personName,
          owner_id: 21257804,
          label_ids: [],
          person_id: personId,
          organization_id: organizationId,
          value: { amount: 0, currency: "INR" },
          expected_close_date: null,
          visible_to: "3",
          was_seen: 1,
          origin_id: null,
          channel: null,
          channel_id: null,
          "ab9df2f471b661d064548dc401e65436abe0638b": comments, //comments
          "3d44a486ef816801bc5a5ce2afe8ec8066a07324": designation, //designation
          "fd8732da92bc0b0ff2858e31a9c7fa17c8606b9d": this.country   ///Capture country
      };
  
      this.http.post(`${environment.pipedriveApiUrl}/v1/leads?api_token=${environment.pipedriveApiToken}`, pipedriveData)
          .subscribe(leadResponse => {
              console.log("Lead Created:", leadResponse);
          }, leadError => {
              console.error("Pipedrive Lead Error:", leadError);
          });
    }

  loadGeoAPIScript() {
    if (isPlatformBrowser(this.platformId)) {
      var scriptName = "geoIP"
      this.loadScript.loadScript(scriptName, '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js')
        .then(() => {
          console.log(`${scriptName} loaded successfully`);
          var onSuccess = function (location) {
            localStorage.setItem("country-trinka", location['country']['iso_code'])
          };

          var onError = function (error) {
            localStorage.setItem("country-trinka", "IN")
          };
          try {
            //@ts-ignore
            geoip2.city(onSuccess, onError);
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => {
          console.error(`Failed to load ${scriptName}`);
        });
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  @ViewChild('targetElement') targetElement!: ElementRef;

  scrollToTarget() {
    if (this.targetElement) {
      this.targetElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  
}
