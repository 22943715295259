import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../../_services/langhfre.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-documark',
  templateUrl: './documark.component.html',
  styleUrls: ['./documark.component.scss']
})
export class DocumarkComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Trinka DocuMark | Academic Integrity Solution for Universities & Institutions ');
    this.metaTagService.updateTag({ name: 'description', content: "Trinka DocuMark verifies writing authenticity by tracing AI-generated, human-written, or copy-pasted text to ensure academic integrity with advanced source-based analytics." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Trinka DocuMark | Academic Integrity Solution for Universities & Institutions ' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Trinka DocuMark verifies writing authenticity by tracing AI-generated, human-written, or copy-pasted text to ensure academic integrity with advanced source-based analytics." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/documark' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/features/documark/documark.png' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Trinka DocuMark | Academic Integrity Solution for Universities & Institutions ' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Trinka DocuMark verifies writing authenticity by tracing AI-generated, human-written, or copy-pasted text to ensure academic integrity with advanced source-based analytics." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/features/documark/documark.png' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features/documark' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/documark' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features/documark' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/features/documark' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/features/documark' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/features/documark' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/features/documark' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/features/documark' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/features/documark' });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  ngAfterViewInit() {
      if (isPlatformBrowser(this.platformId)) {
        $.getScript('../../../../assets/js/home_client_slider.js', function () { });
        $('#closedPopup, #wordPluginPopup').click(function () {
          $('iframe.youtubeVideoForTryDemo').remove()
        });
  
        $('#VewiVideoSection').click(function () {
          if (!$('#iframe').length) {
            $('#iframeHolder').html('<iframe class="youtubeVideoForTryDemo" id="iframe" src="https://www.youtube.com/embed/KwsT4lEyXw4?si=iTRl6dOdFWzW9s6B" width="100%" height="450"></iframe>');
          }
        });
  
      }
    }

}
