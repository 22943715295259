import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../_services/langhfre.service';
//declare function ire(string,{}): any;
@Component({
  selector: 'app-affiliates',
  templateUrl: './affiliates.component.html',
  styleUrls: ['./affiliates.component.scss']
})
export class AffiliatesComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private LinkService: LinkService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Trinka Affiliate Program | AI-Powered Grammar Check Tool');
    this.metaTagService.updateTag({ name: 'description', content: "Know about Trinka's affiliate program · Want to become an affiliate? How can you promote Trinka? Commissions and remittance options. Product and Affiliate Program features." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Trinka Affiliate Program | AI-Powered Grammar Check Tool' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Know about Trinka's affiliate program · Want to become an affiliate? How can you promote Trinka? Commissions and remittance options. Product and Affiliate Program features." });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/affiliates' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/affiliates' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/affiliates' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/affiliates' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/affiliates' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/affiliates' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/affiliates' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/affiliates' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/affiliates' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/affiliates' });

    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  

}
