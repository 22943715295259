import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../../_services/langhfre.service';
//declare function ire(string,{}): any;

@Component({
  selector: 'app-citation-formatter',
  templateUrl: './citation-formatter.component.html',
  styleUrls: ['./citation-formatter.component.scss']
})
export class CitationFormatterComponent implements OnInit {

  steps: string[] = ['Step 1', 'Step 2', 'Step 3', 'Step 4'];
  currentStep: number = 0;
  interval: any;
  timer: any;

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private LinkService: LinkService
  ) { }

  slideImages = [
    '../../../../assets/images/features/citation-formatter/copy-citation.png',
    '../../../../assets/images/features/citation-formatter/target-styles.png',
    '../../../../assets/images/features/citation-formatter/indiviual-citation.png',
    '../../../../assets/images/features/citation-formatter/final-document.png',
  ];

  ngOnInit() {
    this.titleService.setTitle('Free Citation Formatter for MLA, APA, AMA, ACS, Elsevier & more ');
    this.metaTagService.updateTag({ name: 'description', content: "Quickly format entire reference list in one go with Trinka's Citation Formatter. Validate them in real-time for accuracy and consistency!" });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Free Citation Formatter for MLA, APA, AMA, ACS, Elsevier & more ' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Quickly format entire reference list in one go with Trinka's Citation Formatter. Validate them in real-time for accuracy and consistency!" });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/citation-checker' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/features/Citation-Formatter.png' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Free Citation Formatter for MLA, APA, AMA, ACS, Elsevier & more ' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Quickly format entire reference list in one go with Trinka's Citation Formatter. Validate them in real-time for accuracy and consistency!" });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://trinka.ai/assets/images/features/Citation-Formatter.png' });
    this.metaTagService.updateTag({ name: 'keywords', content: 'citation checker, reference checker, apa checker, apa citation checker, check citation online, apa format checker' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features/citation-formatter' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/citation-formatter' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features/citation-formatter' });
    if (isPlatformBrowser(this.platformId)) {
      this.startTimer();
    }
    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $('#closedPopup, #wordPluginPopup').click(function () {
        $('iframe.youtubeVideoForTryDemo').remove()
      });

      $('#VewiVideoSection').click(function () {
        if (!$('#iframe').length) {
          $('#iframeHolder').html('<iframe class="youtubeVideoForTryDemo" id="iframe" src="https://www.youtube.com/embed/XkLKT7b7OyA?si=vLPSl0KdI2ac7U7a" width="100%" height="450"></iframe>');
        }
      });

    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId) && this.timer) {
      clearInterval(this.timer);
    }
  }

  startTimer() {
    if (isPlatformBrowser(this.platformId)) {
      this.timer = setInterval(() => {
        this.nextStep();
      }, 4000);
    }
  }

  resetTimer(stepIndex: number) {
    if (isPlatformBrowser(this.platformId)) {
      clearInterval(this.timer);
      this.currentStep = stepIndex;
      this.startTimer();
    }
  }

  nextStep() {
    this.currentStep = (this.currentStep + 1) % this.steps.length;
  }
}
