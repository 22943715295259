import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import * as $ from 'jquery';
import { Title, Meta } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
//declare function ire(string,{}): any;
@Component({
  selector: 'app-professional-editing-service',
  templateUrl: './professional-editing-service.component.html',
  styleUrls: ['./professional-editing-service.component.scss']
})
export class ProfessionalEditingServiceComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Professional Editing Service Details | Trinka AI');
    this.metaTagService.updateTag({ name: 'description', content: "Academic English editing services by expert PhD/Master's native-English editors for your publication success." });
    this.metaTagService.updateTag({ property: 'og:title', content: "Professional Editing Service Details | Trinka AI" });
    this.metaTagService.updateTag({ property: 'og:description', content: "Academic English editing services by expert PhD/Master's native-English editors for your publication success."});
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/professional-editing-service' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    if (isPlatformBrowser(this.platformId)){
      // $.getScript('../../../assets/js/particles.min.js', function(){});
    }
    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  

}
