import { Component, OnInit, ElementRef, NgZone, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
//import { NetworkServicesService } from 'src/app/networkCalls/network-services.service';
import { Title, Meta } from '@angular/platform-browser';
// import { katanaInput } from 'src/app/models/katana-input.service';
// import { katanaResult } from 'src/app/models/katana-result.service';
// import { CKEditorComponent } from 'ngx-ckeditor';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
// import { FeedbackModalComponent } from 'src/app/Components/feedback-modal/feedback-modal.component';
// import { Observable, from } from 'rxjs';
// import { MatDialog } from '@angular/material';
// import { ErrorModalComponent } from '../error-modal/error-modal.component';
// import { ProgressStatusService } from '../../services/progress-status-service'
//import { rejectError } from 'src/app/models/reject-suggetions.service';
// import { NGXLogger } from 'ngx-logger';
//import { acceptError } from 'src/app/models/accept-suggetions.service';
//import { trigger, transition, style } from '@angular/animations';
// import { $ } from 'protractor';
import * as $ from 'jquery';
import { LinkService } from '../../_services/langhfre.service';
//import { MatRadioChange } from '@angular/material/radio';
//import { MatSnackBar } from '@angular/material/snack-bar';
//import { start } from 'repl';
// import { MatDialog } from '@angular/material/dialog';
// import { NoopScrollStrategy } from '@angular/cdk/overlay'
// import { DetectionLimitExhaustModalComponent } from '../detection-limit-exhaust-modal/detection-limit-exhaust-modal.component';
// import { EnglishGrammarCheckerComponent } from 'src/app/Components/english-grammar-checker/english-grammar-checker.component';
// import { environment } from 'src/environments/environment';
//declare function ire(string,{}): any;
@Component({
  selector: 'app-gc-editor',
  templateUrl: './gc-editor.component.html',
  styleUrls: ['./gc-editor.component.scss']
})
export class GcEditorComponent implements OnInit {
  
  constructor(
    private elRef: ElementRef,
    private titleService: Title,
    private metaTagService: Meta,
    private LinkService: LinkService,
    // public dialog: MatDialog,
    // public progressStatusService: ProgressStatusService,
    // private errorModalComponent: ErrorModalComponent,
    public lc: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    // private logger: NGXLogger,
  ) {
    //window.addEventListener("message", (d)=>{this.listenWindowMessage(d)});
  }


  ngOnInit() {
    // ele.classList.remove("loading")
    this.titleService.setTitle("Free AI Grammar Checker Online - Trinka AI Writing Assistant");
    this.metaTagService.updateTag({ name: 'description', content: "Trinka is an AI-powered, free online grammar checker and language enhancement writing assistant for academic and research paper proofreading." });
    this.metaTagService.updateTag({ property: 'og:title', content: "Free AI Grammar Checker Online - Trinka AI Writing Assistant" });
    this.metaTagService.updateTag({ property: 'og:description', content: "Trinka is an AI-powered, free online grammar checker and language enhancement writing assistant for academic and research paper proofreading." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/grammar-checker' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/WritingMoreEffective.png' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Free AI Grammar Checker Online - Trinka AI Writing Assistant' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Trinka is an AI-powered, free online grammar checker and language enhancement writing assistant for academic and research paper proofreading." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/WritingMoreEffective.png' });
    this.metaTagService.updateTag({ name: 'keywords', content: 'grammar checker, grammar and syntax checker, verify grammar, check you grammar, grammar check online, verify grammar online, english grammar check, english syntax checker, english grammatical check, grammar corrector, sentence corrector, grammar correction site, websites like grammarly, apps like grammarly, free grammar checker, grammar checker tool free, english sentence corrector ' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/grammar-checker' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/corrector-gramatical' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/grammar-checker' });
    //this.LinkService.addTag({ rel: 'alternate', hreflang: 'de-de', href: 'https://www.trinka.ai/de/grammatik-pruefen' });
    if (isPlatformBrowser(this.platformId)) {
      //$.getScript('../../../assets/js/wisepop.js', function () { });
      //$.getScript('../../../assets/js/browser.js', function () { });
    }
    if (isPlatformBrowser(this.platformId)) {
      if ((navigator.userAgent.indexOf("Edg") != -1)) {
        $("#GCChromeButton").css("display", "none");
        $("#GCFirFoxButton").css("display", "none");
        $("#GCEdgeButton").css("display", "block");
        return 'Edge';
      }
      else if ((navigator.userAgent.indexOf("MSIE") != -1)) {
        $("#GCChromeButton").css("display", "none");
        $("#GCFirFoxButton").css("display", "none");
        $("#GCEdgeButton").css("display", "block");
        return 'IE';
      }
      else if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
        $("#GCChromeButton").css("display", "none");
        $("#GCFirFoxButton").css("display", "none");
        $("#GCEdgeButton").css("display", "none");
        return 'Opera';

      } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        $("#GCChromeButton").css("display", "block");
        $("#GCFirFoxButton").css("display", "none");
        $("#GCEdgeButton").css("display", "none");
        return 'Chrome';

      } else if (navigator.userAgent.indexOf("Safari") != -1) {
        $("#GCChromeButton").css("display", "none");
        $("#GCFirFoxButton").css("display", "none");
        $("#GCEdgeButton").css("display", "none");

        return 'Safari';

      } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        $("#GCChromeButton").css("display", "none");
        $("#GCFirFoxButton").css("display", "block");
        $("#GCEdgeButton").css("display", "none");
        return 'Firefox';

      }
      else {
        return 'unknown';
      }

    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org","@graph":[{"@type":"WebPage","@id":"https://www.trinka.ai/grammar-checker#webpage","url":"https://www.trinka.ai/grammar-checker","name":"Free Grammar Checker - Best AI Writing Assistant ","isPartOf":{"@id":"https://www.trinka.ai#website"},"datePublished":"2022-07-09T05:22:52+00:00","dateModified":"2023-02-10T15:18:24+00:00","description":"Struggling with grammar, typos, and unclear sentences? try Trinka's best AI grammar checker and say goodbye to editing woes with our free AI writing assistant!","inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://www.trinka.ai/grammar-checker"]}]},{"@type":"WebSite","@id":"https://www.trinka.ai/#website","url":"https://www.trinka.ai","name":"Trinka AI","description":"Improve your writing and grammar with AI writing assistant! Trinka polishes your writing with AI editing, and proofreading for clear, concise, and impactful communication. Try for FREE!","publisher":{"@id":"https://www.trinka.ai#organization"},"alternateName":"The Complete AI Writing Assistant","inLanguage":"en-US"},{"@type":"Organization","@id":"https://www.trinka.ai/#organization","name":"Trinka AI","url":"https://www.trinka.ai","logo":{"@type":"ImageObject","inLanguage":"en-US","@id":"https://trinka.ai/assets/images/trinka-logo.png","url":"https://trinka.ai/assets/images/trinka-logo.png","contentUrl":"","caption":"Trinka AI"},"image":{"@type":"ImageObject","@id":"https://trinka.ai/assets/images/"},"sameAs":["https://www.facebook.com/thetrinkaai/","https://x.com/thetrinkaai","https://www.linkedin.com/company/thetrinkaai/","https://www.youtube.com/@Trinka-ai"]}]}
    `;
    this._renderer2.appendChild(this._document.head, script);

    let script1 = this._renderer2.createElement('script');
    script1.type = `application/ld+json`;
    script1.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka AI","item":"https://www.trinka.ai"},{"@type":"ListItem","position":2,"name":"Grammar Checker","item":"https://www.trinka.ai/grammar-checker"}]}
    `;
    this._renderer2.appendChild(this._document.head, script1);

    let script2 = this._renderer2.createElement('script');
    script2.type = `application/ld+json`;
    script2.text = `
    {"@context":"https://schema.org","@type":"Organization","name":"Trinka AI","url":"https://www.trinka.ai","logo":"https://www.trinka.ai/assets/images/trinka-logo.png","address":{"@type":"PostalAddress","streetAddress":"Office No 801, Techniplex – II Off SV Road","addressLocality":"Goregaon - West","addressRegion":"Mumbai","postalCode":"400062","addressCountry":"IN"},"sameAs":["https://www.facebook.com/thetrinkaai/","https://www.instagram.com/thetrinkaai/","https://x.com/thetrinkaai/","https://www.youtube.com/c/Trinka-ai","https://www.linkedin.com/company/thetrinkaai/",""]}
    `;
    this._renderer2.appendChild(this._document.head, script2);

    let script3 = this._renderer2.createElement('script');
    script3.type = `application/ld+json`;
    script3.text = `
    {"@context":"https://schema.org","@type":"SoftwareApplication","name":"Grammar Checker","operatingSystem":"Chrome, Firefox, Edge","applicationCategory":"Online Softwate","aggregateRating":{"@type":"AggregateRating","ratingValue":"4.8","ratingCount":"1245"},"offers":{"@type":"Offer","price":"0","priceCurrency":"USD"}}
    `;
    this._renderer2.appendChild(this._document.head, script3);
    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  testimonials = [
    {
      "author": "Hugo Alberto Rivera Rodriguez",
      "designation": "Assitant Professor, Universidad del Rosario, Colombia",
      "message": "Trinka has allowed me to improve the articles I have published. In addition to giving me suggestions for journals according to my research.",
      "photo": "clientPhotos/hugo.jpg"
    },
    {
      "author": "Raquel Rodrigues",
      "designation": "PhD student, Universidade Federal de Minas Gerais, Brazil",
      "message": "The platform has helped me a lot not only with grammar, but also with the cohesion and coherence of my texts. The plagiarism checking and reference checking features are really good.",
      "photo": "clientPhotos/raquel_rodrigues.jpg"
    },
    {
      "author": "Dr. Maryam Razmgir",
      "designation": "Associate Professor - Iran University of Medical Sciences Tehran, Iran",
      "message": "I used Trinka for writing and checking grammar in Iran. Trinka helped me improve the writing. It also helped me to find and correct grammatical errors faster. The use of Trinka was a good experience. ",
      "photo": "clientPhotos/maryam.png"
    },
  ]
  

}
