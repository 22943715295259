<div class="content_wrap_section">
    <div class="wrap_header_sectons">
        <div class="top_header_overlay"></div>
        <h1>Privacy Policy</h1>
    </div>

    <div class="body_wrap_contents">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <p>Trinka.ai is a fully owned product of Crimson AI Pvt. Ltd. Crimson AI Pvt. Ltd and its subsidiary and
                        affiliate companies (collectively hereafter, "Crimson AI,"
                        "we," "us," or "our") recognize the importance of protecting the personal information collected
                        from users in the operation of our website, products, content, and services (collectively
                        hereafter, “Services”) and taking reasonable steps to maintain the security, integrity and
                        privacy of any information in accordance with this Privacy Policy. By submitting your
                        information to Crimson AI you consent to the practices described in this policy.</p>
                    <p>We take your privacy very seriously. This Privacy Policy describes how Crimson AI collects and
                        uses the personal information you provide to Crimson AI. It also describes the choices available
                        to you regarding our use of your personal information and how you can access and update this
                        information. This Privacy Policy forms part of Crimson AI's <a
                            routerLink="/termsofservices">Terms of Service</a>. If you need more
                        information, please contact us at <a href="mailto:support&#64;trinka&#46;ai">Trinka Support</a>.</p>
                    <p>Our personal information handling policy and procedures have been developed in line with the
                        requirements of the 1995 European Union Data Protection Directive (Directive 95/46/EC) and the
                        General Data Protection Regulation (in force from 25 May 2018) and the applicable national law.
                    </p>

                    <div class="question_answer_section">
                        <h3>Questions and Answer</h3>
                        <div id="accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne"> <i class="fa"
                                                aria-hidden="true"></i>
                                            What information do we collect?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                    data-parent="#accordion">
                                    <div class="card-body">
                                        <p>We, along with our third-party vendors, collect and process personal data about you when you interact with us and our products and when you use or purchase Services from us.  The personal
                                            data we process include the following.</p>
                                        <h3>Personal information collected from you</h3>
                                        <ul>
                                            <li>Contact information, such as name, e-mail address, mailing address,
                                                telephone number, geographic location, and other demographic information
                                            </li>
                                            <li>Billing information, such as credit card number, bank account number,
                                                and billing address</li>
                                            <li>Unique identifiers, such as username, account number or password</li>
                                            <li>Information shared through surveys and polls conducted by Crimson AI
                                            </li>
                                            <li>Media files or documents</li>
                                            <li>Correspondence between you and Crimson AI</li>
                                            <li>Information you choose to make available to us as part of your Crimson
                                                AI user profile</li>
                                            <li>Information of referrals, such as referral’s name and e-mail address
                                            </li>
                                            <li>Information you choose to make available to us at events organized by
                                                Crimson AI, such as webinars and workshops</li>
                                            <li>Testimonials which may contain your Personal Data, such as name and
                                                photograph, where you have given consent</li>
                                            <li>Comments, feedback, posts, and other content you provide to Crimson AI
                                                (including through any Crimson AI website)</li>
                                        </ul>
                                        <h3>We will use Personal Data, including without limitation, to</h3>
                                        <ul>
                                            <li>Deliver the Services or information requested by you</li>
                                            <li>Send you communication from the Services, where you have given consent
                                            </li>
                                            <li>Assess your Services -related requirements to determine or suggest
                                                suitable Services</li>
                                            <li>Send you requested information about the Services</li>
                                            <li>Respond to customer Services requests, questions, complaints, and
                                                concerns</li>
                                            <li>Administer your Account</li>
                                            <li>Administer our Services and for internal operations, including
                                                troubleshooting, data analysis, testing, statistical, and survey
                                                purposes;</li>
                                            <li>Conduct research and statistical analysis to help enhance, evaluate,
                                                develop, and create Crimson AI websites, products, and services</li>
                                            <li>Send you promotional and marketing communications, where you have given
                                                consent. You can stop receiving our promotional emails by following the
                                                Unsubscribe instructions included in every email we send.</li>
                                            <li>Verify your identity when required.</li>
                                            <li>Detect fraud</li>
                                            <li>Investigate suspicious activity and otherwise keeping our site safe and
                                                secure</li>
                                            <li>Conduct data analytics</li>
                                            <li>In circumstances where you contact us by telephone, calls may be
                                                recorded for quality, training, and security purposes</li>
                                        </ul>
                                        <h3>Information collected automatically (hereafter Usage Data)</h3>
                                        <ul>
                                            <li>Electronic identification data, such as IP address, cookies, device
                                                model</li>
                                            <li>Browsing activity, such as your visit(s) to our websites, your operating
                                                system, browser type, internet service provider (ISP)</li>
                                            <li>Analytics data, such as clicks and scrolls, referring and exit pages,
                                                and information on how you interacted with our websites.</li>
                                            <li>Raw non-specific user data for research and quality control. If
                                                information is compiled or collected for such purposes, users are not
                                                personally identifiable: their identities are completely anonymous and
                                                never subject to release or publication.</li>
                                        </ul>
                                        <h3>We will use Usage Data, including without limitation, to</h3>
                                        <ul>
                                            <li>Assess needs and/or suggest suitable Service(s)</li>
                                            <li>Send you information you request about the Service(s)</li>
                                            <li>Administer the website(s), application(s), and/or Services</li>
                                            <li>Authenticate users for security purposes</li>
                                            <li>Improve, enhance, support and operate the websites</li>
                                            <li>Compile statistical reports and record insights into usage patterns</li>
                                            <li>Develop new products and services</li>
                                            <li>Improve the algorithms underlying the Services and for quality control
                                                purposes</li>
                                            <li>Conduct scientific research and statistical analysis</li>
                                        </ul>
                                        <h3 style="margin: 30px 0 10px 0;">Information from our public blogs and
                                            newsletters (also Usage Data)</h3>
                                        <p style="margin: 0 0  30px 0;">When you visit our publicly accessible blogs or
                                            subscribe to our newsletters,
                                            we may collect the following information.</p>
                                        <ul>
                                            <li>Assess needs and/or suggest suitable Service(s)</li>
                                            <li>Send you information you request about the Service(s)</li>
                                            <li>Administer the website(s), application(s), and/or Services</li>
                                            <li>Authenticate users for security purposes</li>
                                            <li>Improve, enhance, support and operate the websites</li>
                                            <li>Compile statistical reports and record insights into usage patterns</li>
                                            <li>Develop new products and services</li>
                                            <li>Improve the algorithms underlying the Services and for quality control
                                                purposes</li>
                                            <li>Conduct scientific research and statistical analysis</li>
                                        </ul>
                                        <h3>We will use such Usage Data, including without limitation, to</h3>
                                        <ul>
                                            <li>Send you promotional and marketing communications, where you have given
                                                consent.</li>
                                            <li>Respond to your questions and concerns</li>
                                            <li>Send you notifications of new content</li>
                                        </ul>
                                        <h3 style="margin: 30px 0 10px 0;">Information from Crimson AI Careers (also
                                            Usage Data)</h3>
                                        <p style="margin: 0 0  30px 0;">When you apply for employment at Crimson AI by
                                            filling out a job application form, we may collect the following
                                            information.</p>
                                        <ul>
                                            <li>Contact information, such as name, email address, mailing address,
                                                telephone number, links to your social networking profiles</li>
                                            <li>Any other information contained in the resume that you submit to us</li>
                                        </ul>
                                        <h3>We will use such Usage Data, including without limitation, to</h3>
                                        <ul>
                                            <li>Evaluate you for the position you have applied for</li>
                                            <li>Respond to your questions and concerns</li>
                                        </ul>
                                        <h3 style="margin: 30px 0 10px 0;">Disclosure and Sharing of Your Information
                                        </h3>
                                        <p style="margin: 0 0  30px 0;">Crimson AI will not disclose to or share your
                                            personal information with any unaffiliated third parties except as follows:
                                        </p>
                                        <ul>
                                            <li>Where necessary in connection with services provided by third parties
                                                (i) that provide us with a wide range of office, administrative,
                                                information technology, production, payment, or business management
                                                services, and (ii) that are required to comply with this policy</li>
                                            <li>Where you have provided a credit or debit card, we may also use third
                                                parties (such as POS payment providers) to check the validity of the
                                                sort code, account number, and card number you submit in order to
                                                prevent fraud, in accordance with our legitimate interests and those of
                                                third parties</li>
                                            <li>Where you voluntarily provide information in response to an
                                                advertisement from a third party;</li>
                                            <li>Where your consent has been provided with a third party such as an
                                                academic institution, school, employer, business, or other entity that
                                                has provided you with access to a product or service through an
                                                integration or access code, information may be shared regarding your
                                                engagement with the service or product, results of assessments taken,
                                                and other information you input into the product or service</li>
                                            <li>Where Crimson AI is required to disclose personal information in
                                                response to lawful requests by public authorities and government
                                                agencies, including to meet national security or law enforcement
                                                requirements; to comply with a subpoena or other legal process; when we
                                                believe in good faith that disclosure is necessary to protect our
                                                rights, to enforce our Terms of Service, or to protect the rights,
                                                property, or safety of our services, users, or others; and to
                                                investigate fraud</li>
                                            <li>If our business or any part of it is sold or merged with another
                                                business, your details will be disclosed to our advisers and those of
                                                any prospective purchaser and will be passed to the new owners of the
                                                business.</li>
                                            <li>We may communicate with any person you have referred to us, where we
                                                will request their explicit consent</li>
                                            <li>We may share your personal data with our group companies to process it
                                                for the purposes of inter-group administration and to deliver products
                                                or services</li>
                                            <li>Our professional advisors such as our auditors and external legal and
                                                financial advisors</li>
                                            <li>Marketing and communications agencies where they have agreed to process
                                                your personal data in line with this Privacy Policy</li>
                                            <li>Market research companies</li>
                                            <li>Our suppliers, business partners and sub-contractors</li>
                                            <li>Search engine and web analytics.</li>
                                        </ul>
                                        <p style="margin: 25px 0 20px 0;">If necessary in connection with providing
                                            Services on behalf of third parties, such as institutions and societies,
                                            providers of website hosting, maintenance, call centre operations, and
                                            identity checking, Crimson AI may disclose navigational and transactional
                                            information in the form of anonymous, aggregate usage statistics and
                                            demographics information that do not reveal your identity or personal
                                            information.</p>
                                        <h3>Disclosure in Chat Rooms, Social Media, or Forums</h3>
                                        <p>Identifiable personal information—such as your name or email address—that you
                                            voluntarily disclose and that is accessible to other users (e.g., on social
                                            media, forums, bulletin boards, or in chat areas) could be collected and
                                            disclosed by others. Crimson AI cannot take any responsibility for such
                                            collection and disclosure.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo">
                                            <i class="fa" aria-hidden="true"></i>
                                            What are your rights in relation to your personal data?
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                    data-parent="#accordion">
                                    <div class="card-body">
                                        <ul>
                                            <li>You have the right to make a written request to be informed whether or
                                                not we hold or process any of your personal information (by emailing <a
                                                    href="mailto:support&#64;trinka&#46;ai">support&#64;trinka&#46;ai</a>).</li>
                                            <li>You have the right to ask us not to process your personal data for
                                                marketing purposes. You can exercise your right to prevent such
                                                processing by checking certain boxes on the forms we use to collect your
                                                data, clicking the Unsubscribe button on any communication we have sent
                                                to you, or by writing to us.</li>
                                            <li>Where you have consented to us using your personal data, you can
                                                withdraw that consent at any time.
                                            </li>
                                            <li>If the information we hold about you is inaccurate or incomplete, you
                                                can notify us and ask us to correct or supplement it.
                                            </li>
                                            <li>You also have the right, with some exceptions and qualifications, to ask
                                                us to provide a copy of any personal data we hold about you.
                                            </li>
                                            <li>Where you have provided your data to us and it is processed by automated
                                                means, you can request that we provide it to you in a structured,
                                                machine-readable format.
                                            </li>
                                            <li>If you have a complaint about how we have handled your personal data,
                                                you can ask us to restrict how we use your personal data while your
                                                complaint is resolved.
                                            </li>
                                            <li>In some circumstances, you can write to us to erase your personal data
                                                (a) if it is no longer necessary for us to use your personal data; (b)
                                                if you object to the use of your personal data and we don't have a good
                                                reason to continue to use it; or (c) if we haven't handled your personal
                                                data in accordance with our obligations.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                            <i class="fa" aria-hidden="true"></i>
                                            For how long will we keep your personal data?
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                    data-parent="#accordion">
                                    <div class="card-body">
                                        <ul>
                                            <li>Our use of your data is governed by Crimson AI’s Privacy Policy. You
                                                acknowledge that you have read the Privacy Policy and understand how we
                                                collect, store, and use Customer Data. If you do not agree with our
                                                Privacy Policy, you must stop using our services.</li>
                                            <li>Crimson AI reserves the right to save documents on its systems according
                                                to its policy and Indian government regulations for conducting business.
                                            </li>
                                            <li>We may delete our copies of Customer Data from time to time and you
                                                agree that we will not have any liability for any damages that you may
                                                incur from such deletion.</li>
                                            <li>You agree that we may disclose Customer Data, including content of
                                                communications, if we believe that such disclosure is necessary (i) to
                                                comply with any applicable laws, (ii) to enforce our policies, and (iii)
                                                to protect our services and products.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingfive">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapsefive" aria-expanded="false"
                                            aria-controls="collapsefive">
                                            <i class="fa" aria-hidden="true"></i>
                                            Where do we store your data?</button>
                                    </h5>
                                </div>
                                <div id="collapsefive" class="collapse" aria-labelledby="headingfive"
                                    data-parent="#accordion">
                                    <div class="card-body">
                                        <p>The personal data that we collect from you may be transferred to and stored
                                            in a Crimson AI-owned server and a third-party server. It may also be
                                            processed by staff operating outside the above locations, who work for us or
                                            for one of our suppliers, in which case, the third country's data protection
                                            laws will have been approved as adequate by the European Commission or other
                                            applicable safeguards are in place. You can get further information by
                                            emailing us at <a href="mailto:support&#64;trinka&#46;ai">support&#64;trinka&#46;ai</a>.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingsix">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapsesix" aria-expanded="false"
                                            aria-controls="collapsesix">
                                            <i class="fa" aria-hidden="true"></i>
                                            Data security
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapsesix" class="collapse" aria-labelledby="headingsix"
                                    data-parent="#accordion">
                                    <div class="card-body">
                                        <p>We take the security of your information very seriously. We have been very
                                            careful in developing processes and employing technology to protect against
                                            any current and future security threats in these areas.</p>
                                        <p>Crimson AI is committed to protecting the security of your information and
                                            adopts reasonable standard, industry-wide practices for protecting your
                                            information. However, no internet data transmission is 100% secure, and
                                            hence we cannot ensure that the information you transmit to us won't be
                                            intercepted over the internet. We recommend that you practice online
                                            security measures such as having strong passwords and not disclosing your
                                            password to anyone.</p>
                                        <p>If Crimson AI learns of a security system breach, we may attempt to notify
                                            you and provide information on protective steps, if available, through the
                                            email address that you have provided to us or by posting a notice on the
                                            Crimson AI website.</p>
                                        <p>We use 256-bit Public/Private key encryption for data and file transfers and
                                            hold high-assurance SSL Certificates—a worldwide accepted security
                                            certificate and the highest measure to prevent threats and <a [routerLink]="['/features/plagiarism-check']">plagiarism</a>.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingseven">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseseven" aria-expanded="false"
                                            aria-controls="collapseseven">
                                            <i class="fa" aria-hidden="true"></i>
                                            Data confidentiality
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseseven" class="collapse" aria-labelledby="headingseven"
                                    data-parent="#accordion">
                                    <div class="card-body">
                                        <p>Confidentiality refers to limiting information access and disclosure to
                                            authorized users and preventing access by or disclosure to unauthorized
                                            ones. Crimson AI trains employees about the importance of maintaining the
                                            confidentiality and security of your information.</p>
                                        <p>Data confidentiality covers data in storage, during processing, and in
                                            transit. Crimson AI guarantees that your data will not be accessed or
                                            disclosed to unauthorized individuals, entities, or processes and will be
                                            protected from intentional or accidental attempts to perform unauthorized
                                            data reads.</p>
                                        <h3>Physical Access Control</h3>
                                        <p>Access control is determined by the software, hardware and liveware (humans)
                                            that enforce it. Crimson AI has a system of controls that limits access to
                                            the organization’s resources.</p>
                                        <h3>Network Protection</h3>
                                        <p>Also critical to confidentiality are protections against malicious software
                                            (malware), spyware, spam, and phishing attacks. Crimson AI servers are
                                            protected behind a firewall to prevent users from accessing them other than
                                            by specifically allowed protocols and methods. Crimson AI also maintains a
                                            detection method which picks up suspicious activity that may signal an
                                            intrusion and tracks each transaction by the user login details and provides
                                            a timestamp for each event.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingeight">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseeight" aria-expanded="false"
                                            aria-controls="collapseeight">
                                            <i class="fa" aria-hidden="true"></i>
                                            Children's personal data</button>
                                    </h5>
                                </div>
                                <div id="collapseeight" class="collapse" aria-labelledby="headingeight"
                                    data-parent="#accordion">
                                    <div class="card-body">
                                        <p>Crimson AI does not knowingly collect any Personal Data from children under
                                            the age of 18. If you are under the age of 18, please do not submit any
                                            Personal Data through our websites or services. We encourage parents and
                                            legal guardians to monitor their children’s internet usage and to help
                                            enforce this notice by instructing their children never to provide Personal
                                            Data through our services or websites without their permission. If you have
                                            reason to believe that a child under the age of 18 has provided Personal
                                            Data to us through our websites or services, please contact us at <a
                                                href="mailto:support&#64;trinka&#46;ai">support&#64;trinka&#46;ai</a>; we will delete
                                            that information and terminate the child's account from our databases.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingnine">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapsenine" aria-expanded="false"
                                            aria-controls="collapsenine">
                                            <i class="fa" aria-hidden="true"></i>
                                            Cookies
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapsenine" class="collapse" aria-labelledby="headingnine"
                                    data-parent="#accordion">
                                    <div class="card-body">
                                        <h3>Cookie Policy for Crimson AI</h3>
                                        <p>This is the Cookie Policy for Crimson AI, accessible from <a href="">https://www.trinka.ai/</a> </p>
                                        <p>Crimson AI ("we," "us," or "our") is an independent technology company owned by the Crimson AI Pvt. Ltd. This Cookie Statement explains how we use cookies and similar technologies in the course of our business, including through our websites that link to this Cookie Statement, such as <a href="https://trinka.ai" target="_blank" rel="noopener noreferrer">https://trinka.ai</a>, <a href="https://cloud.trinka.ai" target="_blank" rel="noopener noreferrer">https://cloud.trinka.ai</a>, or any website or mobile application owned, operated or controlled by us (collectively, “Websites” or “websites”). It explains what these technologies are and why we use them, as well as your rights to control our use of them. This Cookie Statement is and will be treated as part of our Terms of Service and is automatically incorporated therein.</p>
                                        <p>In some cases, we may use cookies and other tracking technologies described in this Cookie Statement to collect Personal Information, or to collect information that becomes Personal Information if we combine it with other information. For more information about how we process your Personal Information, please consult our Privacy Policy.</p>
                                        <h3>What are cookies?</h3>
                                        <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by online service providers in order to (for example) make their websites or services work, or to work more efficiently, as well as to provide reporting information.</p>
                                        <p>Cookies set by the website owner or service provider are called “first party cookies”. Cookies set by parties other than the website owner are called “third party cookies”. Third party cookies enable third party features or functionality to be provided on or through the website or service you are using (such as advertising, interactive content and analytics). The third parties that set these third-party cookies can recognize your computer both when it visits the website or service in question and also when it visits certain other websites or services.</p>
                                        <h3>Why do we use cookies and other tracking technologies?</h3>
                                        <p>We use first party and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our Websites and Services to operate. Other cookies enable us and the third parties we work with to track and target the interests of visitors to our Websites. For example, we use cookies to tailor content and information that we may send or display to you and otherwise personalize your experience while interacting with our Websites and to otherwise improve the functionality of the Services we provide. Third parties serve cookies through our Websites and Services for advertising, analytics, and other purposes. This is described in more detail below.</p>
                                        <h3>Cookies served through our Websites</h3>
                                        <p>These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work.</p>
                                        <h3>The Cookies We Set</h3>
                                        <ul>
                                            <li>
                                                <h4>Account related cookies</h4>
                                                <p>If you create an account with us, then we will use cookies for the management of the signup process and general administration. These cookies will usually be deleted when you log out however in some cases they may remain afterwards to remember your site preferences when logged out.</p>
                                            </li>
                                            <li>
                                                <h4>Sign In related cookies</h4>
                                                <p>We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.</p>
                                            </li>
                                            <li>
                                                <h4>Email newsletters related cookies</h4>
                                                <p>This site offers newsletter or email and blog subscription services and cookies may be used to remember if you are already registered and whether to show certain notifications which might only be valid to subscribed/unsubscribed users.</p>
                                            </li>
                                            <li>
                                                <h4>Orders processing related cookies</h4>
                                                <p>This site offers e-commerce or payment facilities and some cookies are essential to ensure that your order is remembered between pages so that we can process it properly.</p>
                                            </li>
                                            <li>
                                                <h4>Surveys related cookies</h4>
                                                <p>From time to time we offer user surveys and questionnaires to provide you with interesting insights, helpful tools, or to understand our user base more accurately. These surveys may use cookies to remember who has already taken part in a survey or to provide you with accurate results after you change pages.</p>
                                            </li>
                                            <li>
                                                <h4>Forms related cookies</h4>
                                                <p>When you submit data through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence.</p>
                                            </li>
                                        </ul>


                                        <h3>Third Party Cookies</h3>
                                        <p>We also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.</p>
                                        <ul>
                                            <li>
                                                <h4>Analytical cookies</h4>
                                                <p>These cookies enable us and third party services to collect aggregated data for statistical purposes on how our visitors use the Website. These cookies do not contain personal information such as names and email addresses and are used to help us improve your user experience of the Website.</p>
                                            </li>
                                            <li>
                                                <h4>Social media cookies</h4>
                                                <p>We also use social media buttons and/or plugins on this site that allow you to connect with your social network in various ways. Third party cookies from social media sites (such as Facebook, Twitter, etc) let us track social network users when they visit or use the Website and Services, or share content, by using a tagging mechanism provided by those social networks.</p>
                                            </li>
                                            <li>
                                                <h4>Affiliate Tracking Cookies</h4>
                                                <p>Several partners advertise on our behalf and affiliate tracking cookies simply allow us to see if our customers have come to the site through one of our partner sites so that we can credit them appropriately and where applicable allow our affiliate partners to provide any bonus that they may provide you for making a purchase.</p>
                                                <p>These cookies are also used for event tracking and remarketing purposes. Any data collected with these tags will be used in accordance with our and social networks’ privacy policies. We will not collect or share any personally identifiable information from the user.</p>
                                            </li>
                                        </ul>

                                        <h3>What are your cookie options?</h3>
                                        <p>If you don't like the idea of cookies or certain types of cookies, you can change your browser's settings to delete cookies that have already been set and to not accept new cookies. To learn more about how to do this or to learn more about cookies, visit <a href="https://www.internetcookies.org/" target="_blank" rel="noopener noreferrer">www.internetcookies.org</a>. Please note, however, that if you delete cookies or do not accept them, you might not be able to use all of the features the Website and Services offer.</p>
                                        <h3>How often will you update this Cookie Statement?</h3>
                                        <p>We may update this Cookie Statement from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Statement regularly to stay informed about our use of cookies and related technologies. The date at the bottom of this Cookie Statement indicates when it was last updated.</p>
                                        <h3>Acceptance of this policy</h3>
                                        <p>You acknowledge that you have read this Policy and agree to all its terms and conditions. By continuing to browse the website, and/or using the Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Website and Services.</p>
                                        <h3>Where can I get further information?</h3>
                                        <p>If you have any questions about our use of cookies or other technologies, please contact us at <a href="mailto:support&#64;trinka&#46;ai">support&#64;trinka&#46;ai</a> </p>
                                        <h3>Updated January 1, 2021</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingten">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseten" aria-expanded="false"
                                            aria-controls="collapseten">
                                            <i class="fa" aria-hidden="true"></i>
                                            Changes to this privacy policy</button>
                                    </h5>
                                </div>
                                <div id="collapseten" class="collapse" aria-labelledby="headingten"
                                    data-parent="#accordion">
                                    <div class="card-body">
                                        <p>We may update our Privacy Policy from time to time. We will let you know via
                                            email and/or a prominent notice on our Services, prior to the change
                                            becoming effective and update the "effective date" at the top of this
                                            Privacy Policy. You are advised to review this Privacy Policy periodically
                                            for any changes.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingeleone">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapsedelone" aria-expanded="false"
                                            aria-controls="collapsedelone">
                                            <i class="fa" aria-hidden="true"></i>
                                            Contact us</button>
                                    </h5>
                                </div>
                                <div id="collapsedelone" class="collapse" aria-labelledby="headingeleone"
                                    data-parent="#accordion">
                                    <div class="card-body">
                                        <p>Should you have any queries regarding this Privacy Policy about Crimson AI’s
                                            processing of your personal data or wish to exercise your rights, please
                                            contact us at <a href="mailto:support&#64;trinka&#46;ai">support&#64;trinka&#46;ai</a>.</p>
                                        <h3>Crimson AI</h3>
                                        <p>Office No 6, 1st Floor, Techniplex – II<br />
                                            Off SV Road, Goregaon - West<br />
                                            Mumbai, India<br />
                                            Pin code: 400062</p>
                                        <p>Phone No. +91-22-61935000</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>