import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../../_services/langhfre.service';
//declare function ire(string,{}): any;

@Component({
  selector: 'app-aicontent-detector-features',
  templateUrl: './aicontent-detector-features.component.html',
  styleUrls: ['./aicontent-detector-features.component.scss']
})
export class AIContentDetectorFeaturesComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private LinkService: LinkService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('AI Content Detector by Trinka - Identify AI-Generated Text Quickly');
    this.metaTagService.updateTag({ name: 'description', content: 'Identify AI-content generated GenAI tools like ChatGPT, Gemini, Perplexity, Bing Copilot, and more to ensure the authenticity and originality of your text.' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'AI Content Detector by Trinka - Identify AI-Generated Text Quickly' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Identify AI-content generated GenAI tools like ChatGPT, Gemini, Perplexity, Bing Copilot, and more to ensure the authenticity and originality of your text.' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/ai-content-detector' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'AI Content Detector by Trinka - Identify AI-Generated Text Quickly' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Identify AI-content generated GenAI tools like ChatGPT, Gemini, Perplexity, Bing Copilot, and more to ensure the authenticity and originality of your text." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features/ai-content-detector' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/ai-content-detector' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features/ai-content-detector' });

    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

}
