import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';

@Component({
  selector: 'app-apps-library',
  templateUrl: './apps-library.component.html',
  styleUrls: ['./apps-library.component.scss']
})
export class AppsLibraryComponent {

  isFunctionsActive: boolean = false;

  items = [
    {
      colorClass: "light-blue",
      title: "Blog Outline Generator",
      description: "Streamlines your blog writing process with captivating headlines and summaries",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["General", "Marketing"]
    },
    {
      colorClass: "light-yellow",
      title: "Blog Topic Idea Generator",
      description: "Generates a list of blog topic ideas on your focus area for your target audience",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["General", "Marketing"]
    },
    {
      colorClass: "light-green",
      title: "Ad Copy Generator",
      description: "Writes high performing, catchy, and emotionally engaging Ad copy",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["General", "Marketing"]
    },
    {
      colorClass: "light-red",
      title: "SEO Optimized Blog Post Writer",
      description: "Crafts SEO-optimized, compelling, and reader-friendly blog posts",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["General", "Marketing"]
    },
    {
      colorClass: "light-cyan",
      title: "Email Subject Line Generator",
      description: "Generates 10 different versions from existing subject line",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["General", "Marketing"]
    },
    {
      colorClass: "light-purple",
      title: "Blog Builder",
      description: "Generates an outline, key points, and blog post in a step-by-step process just with a headline idea",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["General", "Marketing"]
    },
    {
      colorClass: "light-blue",
      title: "Manuscript Blog Builder",
      description: "Transforms manuscripts into easy to understand blogs to share findings and insights with a wider audience",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Impact"]
    },
    {
      colorClass: "light-yellow",
      title: "Paper Title Ideas Generator",
      description: "Generates compelling and relevant titles for your manuscript",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Ideation"]
    },
    {
      colorClass: "light-green",
      title: "Argument Analyzer",
      description: "Identifies strengths and weaknesses of your argument",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Critical thinking"]
    },
    {
      colorClass: "light-red",
      title: "Counter Argument Generator",
      description: "Generates a strong counter argument",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Critical thinking"]
    },
    {
      colorClass: "light-cyan",
      title: "Analogy Generator",
      description: "Generates effective analogies to improve your explanations and arguments",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Critical thinking"]
    },
    {
      colorClass: "light-purple",
      title: "Critique Writer",
      description: "Generates critical evaluations of any research argument, context, theory, or paper",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Critical thinking"]
    },
    {
      colorClass: "light-blue",
      title: "Abstract Rewriter",
      description: "Enhances and refines the abstracts of academic papers, articles, and manuscripts",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Content"]
    },
    {
      colorClass: "light-yellow",
      title: "Example Generator",
      description: "Generates examples for improving understandability",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Content"]
    },
    {
      colorClass: "light-green",
      title: "Conclusion Writer",
      description: "Generates concise, impactful conclusions for your studies",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Content"]
    },
    {
      colorClass: "light-red",
      title: "Key Insights Generator",
      description: "Generates the actionable insights of any research findings and conclusions",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Analytics"]
    },
    {
      colorClass: "light-cyan",
      title: "Literature Summarizer",
      description: "Generates a summary of research literature",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Summary"]
    },
    {
      colorClass: "light-purple",
      title: "Plain Language Summary",
      description: "Transforms complex technical language of research papers into clear and simple language",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Summary"]
    },
    {
      colorClass: "light-blue",
      title: "Citation Booster",
      description: "Generates shareable content about  research findings on social media to enhance the visibility of the research",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Visibility"]
    },
    {
      colorClass: "light-yellow",
      title: "Keywords Generator",
      description: "Generates relevant keywords from documents, articles, or research papers to enhance discoverability",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Visibility"]
    },
    {
      colorClass: "light-green",
      title: "Customer Review Response Generator",
      description: "Writes customer review responses for enhanced brand reputation and engagement by responding effectively to customer feedback",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["General", "Support"]
    },
    {
      colorClass: "light-red",
      title: "Press Release from Manuscript",
      description: "Crafts an engaging press release to highlight your research for media visibility",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Impact"]
    },
    {
      colorClass: "light-cyan",
      title: "Linkedin Post from Manuscript",
      description: "Converts your manuscript into a concise LinkedIn post for professional engagement",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Impact"]
    },
    {
      colorClass: "light-purple",
      title: "Poster Outline from Manuscript",
      description: "Develops a structured poster outline to present your research at conferences",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Impact"]
    },
    {
      colorClass: "light-blue",
      title: "Audio/Video Script Outline from Manuscript",
      description: "Creates an engaging script to present your research findings via audio or video",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Impact"]
    },
    {
      colorClass: "light-yellow",
      title: "ResearchGate Content from Manuscript",
      description: "Summarizes your manuscript for ResearchGate to enhance visibility and academic engagement",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Impact"]
    },
    {
      colorClass: "light-green",
      title: "Cover Letter from Abstract",
      description: "Transforms your abstract into a concise and persuasive cover letter for journal submission",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Impact"]
    },
    {
      colorClass: "light-red",
      title: "Tweetable Abstract from Abstract",
      description: "Creates a tweetable abstract to share your research in a concise format on Twitter",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Impact"]
    },
    {
      colorClass: "light-cyan",
      title: "Instagram Post from Cover Letter",
      description: "Turns your cover letter into an engaging Instagram post to share research highlights",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Impact"]
    },
    {
      colorClass: "light-purple",
      title: "Researcher Profile Summarizer",
      description: "Summarizes a researcher's career from the content from a researcher profile",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["Academic", "Impact"]
    },
    {
      colorClass: "light-blue",
      title: "Thank you Note Creator",
      description: "Creates heartfelt thank you note to express your gratitude",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["General", "Communication"]
    },
    {
      colorClass: "light-yellow",
      title: "Tone Modifier",
      description: "Rewrites your content into a tone that matches your requirements",
      link: "https://cloud.trinka.ai/ai-apps/",
      category: ["General", "Communication"]
    },
  ];

  selectedFilter = 'All';
  selectedSubFilter: string | null = null;
  originalScrollPosition: number = 0;
  //filteredItems = [...this.items];

  @ViewChild('contentContainer', { static: false }) contentContainer: ElementRef | undefined;

  saveScrollPosition() {
    if (this.contentContainer) {
      this.originalScrollPosition = this.contentContainer.nativeElement.scrollTop;
    }
  }

  filterItems(filter: string) {
    this.selectedFilter = filter;
    this.selectedSubFilter = null;
    if (this.contentContainer) {
      this.contentContainer.nativeElement.scrollTop = this.originalScrollPosition;
    }
  }

  filterSubItems(subFilter: string) {
    this.selectedSubFilter = this.selectedSubFilter === subFilter ? null : subFilter;
  }
  
  isSubFilterActive(subFilter: string): boolean {
    return this.selectedSubFilter === subFilter;
  }

  get filteredItems() {
    return this.items.filter(item =>
      (this.selectedFilter === 'All' || item.category.includes(this.selectedFilter)) &&
      (!this.selectedSubFilter || item.category.includes(this.selectedSubFilter))
    );
  }

  toggleSubFilter() {
    this.isFunctionsActive = !this.isFunctionsActive;
  }

  closeFunctionsMenu(): void {
    this.isFunctionsActive = false;
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event): void {
    const target = event.target as HTMLElement;
    const isInsideMenu = target.closest('.sub-menu-filter, .md-function-btn');
    if (!isInsideMenu) {
      this.closeFunctionsMenu();
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

}
