import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { WebsiteService } from 'src/app/_services/website.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadScriptService } from 'src/app/_services/load-script.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-enterprise-modal',
  templateUrl: './enterprise-modal.component.html',
  styleUrls: ['./enterprise-modal.component.scss']
})
export class EnterpriseModalComponent implements OnInit {
  trinkaFeatures = [
    "Bulk Licenses for Paid plans",
    "Institution Plan",
    "Sensitive Data Plan",
    "API/SDK for Grammar Check",
    "API Proofread file",
    "Inclusive Language Check",
    "Plagiarism Check Reports",
    "AI Content Detector",
    "Trinka Reports",
    "Custom AI Models",
  ]
  email: any;
  // emailEnterprise : FormGroup;
  contactForm: FormGroup;
  formSection: boolean = true;
  thankSection: boolean = false;
  country: string;
  isSubmitting: boolean = false;
  constructor(
    private toastr: ToastrService,
    private networkCall: WebsiteService,
    private fb: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
    public loadScript: LoadScriptService,
    private http: HttpClient
  ) {
    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])],
      'contactFormSubjects': ['', Validators.required],
      'contactFormMessage': ['', Validators.required],
      'contactphoneNo': ['', Validators.required],
      'NatureBusinessType': ['', Validators.required],
      'DesignationTypes': ['', Validators.required],
      'tsFeatures': [[]],
    });
  }

  ngOnInit() {
  }
  sectionValue(section: string) {
    this.formSection = true;
    this.thankSection = false;
  }

  sendContactUsForm() {
    if (this.contactForm.valid) {
        this.isSubmitting = true;

        if (!localStorage.getItem("country-trinka")) {
            this.loadGeoAPIScript();
        }

        this.country = localStorage.getItem("country-trinka") || "IN";

        const organizationName = this.contactForm.value.contactFormSubjects || "Unknown Organization";
        const personName = this.contactForm.value.contactFormName;
        const email = this.contactForm.value.contactFormEmail;
        const phone = this.contactForm.value.contactphoneNo;
        const designation = this.contactForm.value.DesignationTypes;
        const comments = `Requirement - ${this.contactForm.value.contactFormMessage || ""}
          Inquiry for - ${this.contactForm.value.tsFeatures?.length ? this.contactForm.value.tsFeatures.join(", ") : "N/A"}
          Nature of business - ${this.contactForm.value.NatureBusinessType}
          URL - ${this.router.url || "N/A"}`;

        console.log("comments are:", comments);

        const selectedFeatures = this.contactForm.value.tsFeatures;
        const featuresString = Array.isArray(selectedFeatures) ? selectedFeatures.join(', ') : selectedFeatures;
        const query = `${this.contactForm.value.NatureBusinessType}||${this.contactForm.value.contactFormSubjects}||${this.contactForm.value.contactFormMessage}||${this.contactForm.value.contactFormName}||${this.contactForm.value.DesignationTypes}||${this.contactForm.value.contactphoneNo}||${featuresString}`;

        this.networkCall.postContactUs(this.contactForm.value.contactFormEmail, query, this.router.url).subscribe(result => {
          
          // Step 1: Search for Existing Organization
          this.http.get(`${environment.pipedriveApiUrl}/v1/organizations/search?term=${organizationName}&api_token=${environment.pipedriveApiToken}`)
          .subscribe(searchResponse => {
              const existingOrganization = searchResponse['data']?.items?.find(item => item.item.name === organizationName);
              console.log("inside pipedrive api");
              if (existingOrganization) {
                  console.log("Existing Organization Found:", existingOrganization);
                  const organizationId = existingOrganization.item.id;
                  this.createPersonAndLead(personName, email, phone, organizationId, comments, designation);
              } else {
                  // Organization does not exist, create a new one
                  this.createOrganization(organizationName, personName, email, phone, comments, designation);
              }
          }, searchError => {
              console.error("Pipedrive Organization Search Error:", searchError);
          });

          this.toastr.success("Thanks! We will contact you shortly.")
          this.formSection = false;
          this.thankSection = true;
          this.contactForm.reset();
        }, error => {
          this.toastr.warning("Something went wrong!")
        }).add(() => {
          this.isSubmitting = false;
        });
    } else {
        this.validateAllFormFields(this.contactForm);
    }
  }

  createOrganization(organizationName: string, personName: string, email: string, phone: string, commets: string, designation: string) {
    const organizationData = { name: organizationName };

    this.http.post(`${environment.pipedriveApiUrl}/v1/organizations?api_token=${environment.pipedriveApiToken}`, organizationData)
        .subscribe(orgResponse => {
            console.log("Organization Created:", orgResponse);
            const organizationId = orgResponse['data']?.id;

            if (!organizationId) {
                console.error("Error: No organization ID returned from Pipedrive.");
                return;
            }

            this.createPersonAndLead(personName, email, phone, organizationId, commets, designation);
        }, orgError => {
            console.error("Pipedrive Organization Error:", orgError);
        });
  }

  createPersonAndLead(personName: string, email: string, phone: string, organizationId: number, comments: string, designation: string) {
    const personData = {
        name: personName,
        email: email,
        phone: phone,
        visible_to: 3,
        org_id: organizationId // Associate person with the organization
    };

    this.http.post(`${environment.pipedriveApiUrl}/v1/persons?api_token=${environment.pipedriveApiToken}`, personData)
        .subscribe(personResponse => {
            console.log("Person Created:", personResponse);
            const personId = personResponse['data']?.id;

            if (!personId) {
                console.error("Error: No person ID returned from Pipedrive.");
                return;
            }

            this.createLead(personName, personId, organizationId, comments, designation);
        }, personError => {
            console.error("Pipedrive Person Error:", personError);
        });
  }

  createLead(personName: string, personId: number, organizationId: number, comments: string, designation: string) {
    const pipedriveData = {
        title: personName,
        owner_id: 21257804,
        label_ids: [],
        person_id: personId,
        organization_id: organizationId,
        value: { amount: 0, currency: "INR" },
        expected_close_date: null,
        visible_to: "3",
        was_seen: 1,
        origin_id: null,
        channel: null,
        channel_id: null,
        "ab9df2f471b661d064548dc401e65436abe0638b": comments, //comments
        "3d44a486ef816801bc5a5ce2afe8ec8066a07324": designation, //designation
        "fd8732da92bc0b0ff2858e31a9c7fa17c8606b9d": this.country   ///Capture country
    };

    this.http.post(`${environment.pipedriveApiUrl}/v1/leads?api_token=${environment.pipedriveApiToken}`, pipedriveData)
        .subscribe(leadResponse => {
            console.log("Lead Created:", leadResponse);
        }, leadError => {
            console.error("Pipedrive Lead Error:", leadError);
        });
  }

  loadGeoAPIScript() {
    if (isPlatformBrowser(this.platformId)) {
      var scriptName = "geoIP"
      this.loadScript.loadScript(scriptName, '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js')
        .then(() => {
          console.log(`${scriptName} loaded successfully`);
          var onSuccess = function (location) {
            localStorage.setItem("country-trinka", location['country']['iso_code'])
          };

          var onError = function (error) {
            localStorage.setItem("country-trinka", "IN")
          };
          try {
            //@ts-ignore
            geoip2.city(onSuccess, onError);
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => {
          console.error(`Failed to load ${scriptName}`);
        });
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

}
