import { Component, OnInit } from '@angular/core';
import { LinkService } from '../../_services/langhfre.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-es-editor',
  templateUrl: './es-editor.component.html',
  styleUrls: ['./es-editor.component.scss']
})
export class EsEditorComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private LinkService: LinkService
  ) { }

  ngOnInit(): void {
      this.titleService.setTitle('Free Spanish Grammar Checker Online – Trinka AI');
      this.metaTagService.updateTag({ name: 'description', content: "Improve your Spanish writing with Trinka AI’s free Spanish grammar checker. Instantly fix Spanish grammar, spelling, and style errors for clear and accurate text." });
      this.metaTagService.updateTag({ property: 'og:title', content: 'Free Spanish Grammar Checker Online – Trinka AI' });
      this.metaTagService.updateTag({ property: 'og:description', content: "Improve your Spanish writing with Trinka AI’s free Spanish grammar checker. Instantly fix Spanish grammar, spelling, and style errors for clear and accurate text." });
      this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/spanish-grammar-checker' });
      this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
      this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/free-spanish-grammar-checker.png' });
      this.metaTagService.updateTag({ name: 'twitter:title', content: 'Free Spanish Grammar Checker Online – Trinka AI' });
      this.metaTagService.updateTag({ name: 'twitter:description', content: "Improve your Spanish writing with Trinka AI’s free Spanish grammar checker. Instantly fix Spanish grammar, spelling, and style errors for clear and accurate text." });
      this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/free-spanish-grammar-checker.png' });
      this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
      this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/spanish-grammar-checker' });
      this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/spanish-grammar-checker' });
      // this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/spanish-grammar-checker' });
      // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/spanish-grammar-checker' });
      // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/spanish-grammar-checker' });
      // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/spanish-grammar-checker' });
      // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/spanish-grammar-checker' });
      // this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/spanish-grammar-checker' });
      // this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/spanish-grammar-checker' });
    }

}
